<template>
  <div>
    <div class="btn-large wide-btn rounded-btn mt-25" @click="pay" v-if="showBtn">
      {{$t("sendBill.buyNowPayLaterButton")}}
    </div>
    <section v-else>
      <div v-show='loading' class='grey-text center mt-10'>
        <i class="fas fa-spinner fa-spin grey-text"></i> 
        {{$t("sendBill.loading")}}
      </div>
      <div ref="card" v-show='amountNumber' class="mt-10"></div>
      <button @click="payKlarna" v-show="!loading" class="btn-large wide-btn mt-5" :disabled="pending">
        {{$t("sendBill.pay")}}<span v-show='amountNumber'> {{amountNumber | currency}}</span>
      </button>
    </section>
  </div>

</template>
<script>
  import tips from "../../tips"

  export default {
    components: {tips},
    props: ['amountNumber', 'stripePublishableKey', 'merchantStripeId', 'linkOrderId', 'merchantQid', 'showTips', 'policyUrl', 'hideVotes'],
    data() {
      return {
        showBtn: true,
        clientSecret: null,
        paymentIntentId: null,
        redirectUrl: null,
        loading: false,
        pending: false
      }
    },
    methods: {
      payKlarna() {
        this.pending = true
        const elements = this.elements
        this.stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: this.redirectUrl
          },
        }).then(result => {
          if (result.error) {
            this.notifyError(result.error.message)
            this.pending = false
          }
        })
      },
      pay() {
        this.$emit('input', true)
        this.showBtn = false
        this.createPaymentIntent()
      },
      createPaymentIntent() {
        this.loading = true
        this.$http.post("/api/v1/app/stripe/klarna_payment_intents", this.params).then(response => {
          this.clientSecret = response.body.client_secret
          this.paymentIntentId = response.body.payment_intent_id
          this.redirectUrl = response.body.redirect_url
          this.loadStripeJs()
        }, error => {
          this.loading = false
          this.errorHandler(error)
        })
      },
      loadStripeJs() {
        this.script= document.createElement('script')
        this.script.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(this.script)
        this.script.onload = this.setupStripe
      },
      setupStripe() {
        this.stripe = Stripe(this.stripePublishableKey, {stripeAccount: this.merchantStripeId})

        const appearance = {
          theme: 'stripe',
        };
        this.elements = this.stripe.elements({ appearance, clientSecret: this.clientSecret });
        const paymentElementOptions = {
          layout: "tabs",
        };
        this.card = this.elements.create('payment')
        this.card.mount(this.$refs.card)
        this.loading = false
      },
    },
    computed: {
      params() {
        return {
          amount: this.amountNumber,
          link_order_id: this.linkOrderId,
          tip_amount: 0,
          tip_percent: 0
        }
      }
    }
  }
</script>
<style scoped>
</style>
