<template>
  <div>
    <center v-show='loading' class='grey-text'>
      <i class="fas fa-spinner fa-spin grey-text"></i> 
      Loading ...
    </center>
    <div v-show='!loading'>
      <h4>Reward Balance: <b>{{totalBalance}}</b></h4>

      <i class="far fa-clock"></i> Pending amount: 
      <span class='red-text'>${{pendingAmount}}</span>

      <br><br>
      <button class='btn' @click='withdraw' :disabled='submitDisabled' v-if='totalBalance > 0'>
        Redeem ${{totalBalance}} *
      </button>
      <span v-else>Nothing to withdraw...</span>
      <br>
      <small class='grey-text' v-show='totalBalance > 0'>
        <br>
        * Withdrawal to available balance will be processed immediately and amount 
        ${{totalBalance}} will be available for payments right away.
      </small>
    </div>
  </div>
</template>
<script>
  export default {
    created() {
      this.getDefaults()
    },
    data() {
      return {
        totalBalance: 0,
        pendingAmount: null,
        loading: true,
        submitDisabled: false,
      }
    },
    methods: {
      getDefaults() {
        this.$http.get('/api/v1/app/balance_reward').then( response => {
          this.totalBalance = response.body.balance
          this.pendingAmount = response.body.pending_amount
          this.loading = false
        }, error => {
          Rollbar.error(error.body)
        })
      },
      withdraw() {
        this.submitDisabled = true
        this.$http.post('/api/v1/app/withdraw_reward_balance').then(response => {
          Turbolinks.visit('/dashboard')
        }, error => {
          this.notifyError(error.body.message)
          // this.notifyError('Unable withdraw rewards')
          this.submitDisabled = false
          Rollbar.error(error.body)
        })
      }
    }
  }
</script>
