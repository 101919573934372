<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        fetchPolicy: "no-cache",
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {sendBillNote}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings
      }
    },
    data() {
      return {
        settings: {},
        showForm: false
      }
    },
    methods: {
      updateText() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($text: String!) {
            updateSendBillNote(input: {text: $text}) {
              text
              errors
            }
          }`,
          // Parameters
          variables: {
            text: this.settings.sendBillNote,
            gid: this.gid
          },
        }).then(result => {
          this.settings.sendBillNote = result.data.updateSendBillNote.text
          this.showForm = false
          this.notifySuccess("Note successfully updated")
        }, error => {
          this.gqlErrorHandler(error)
        })
      },
      close() {
        this.$apollo.queries.settings.refetch()
        this.showForm = false
      }
    }
  }
</script>

<template>
  <div>
    <section class="row">
      <div class="mt-25 grey-text">
        <div v-if="$apollo.loading">
          <i class="fas fa-spinner fa-spin grey-text"></i> 
          Loading...
        </div>

        <div v-else>
          {{settings.sendBillNote}}
          
          <span v-show="!settings.sendBillNote && !settings.showForm">(not set)</span>

          <span v-if="!showForm">
            <a class="pointer" @click="showForm = true">
              <i class="far fa-edit"></i> Edit
            </a>
          </span>
        </div>

        <div class="col s12" v-show="showForm">
          <section class="mt-10">
            <textarea v-model="settings.sendBillNote" placeholder="Note"></textarea>
            <button class="btn" @click="updateText">Save</button>
            <button @click="close" class="right btn-flat">Close</button>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
  textarea {
    height: 60px;
  }
</style>
