<template>
  <div class="container">
    <section v-show="showForm">
      <h3 class="center bold-font"><b>Send a check</b></h3>
      <div class='row'>
        <div class="col s12">
          <div class="mt-10">
            <label>Payee phone (to receive a check by SMS)</label>
            <input v-model="phone">
          </div>

          <div class="mt-10">
            <label>Payee name</label>
            <input v-model="payeeName">
          </div>
        </div>
      </div>

      <div class='row'>
        <div class='col s6'>
          <label>Check amount, USD</label>
          <input 
            name='amountName'
            v-model='amount'
            step="0.01"
            type="number"
            pattern="\d*"
            inputmode="decimal"
            v-validate="'min_value:0.01|required'"
            class='inputText'
            lang="en-001">

          <span class='red-text small-font' v-show='errors.first("amountName")'>The Amount field must be 0.01 or more.</span>
        </div>
        <div class='col s6' v-show="amount && payeeName">
          <a :href="previewPath" class="btn right outlined-btn" target="_blank">
            preview
          </a>
        </div>
      </div>
      <div class="center grey-text">
        Sending a check from account: {{maskedAccount}}
      </div>
      <button class="btn wide-btn" @click="sendCheck" :disabled="disabled">
        Send a check
      </button>
    </section>

    <section v-show="!showForm">
      <center>
        <i class="fas fa-check-circle fa-3x emerald mt-25"></i>
        <div class='large-font bold-font mt-25'><b>Check Successfully Sent</b></div>

        <a href="/" class="btn-large mt-50">Ok</a>


      </center>
    </section>
  </div>
</template>
<script>
  export default {
    props: ["maskedAccount"],
    data() {
      return {
        phone: null,
        payeeName: null,
        amount: null,
        disabled: false,
        showForm: true
      }
    },
    methods: {
      sendCheck() {
        this.disabled = true
        this.$http.post("/api/v1/app/client/checks", this.params).then(response => {
          this.showForm = false

        }, error => {
          this.errorHandler(error)
          this.disabled = false
        })
      }
    },
    computed: {
      params() {
        return {
          phone: this.phone,
          payee_name: this.payeeName,
          amount: this.amount
        }
      },
      previewPath() {
        return "/client/check.pdf?amount=" + this.amount + "&payee_name=" + this.payeeName
      }
    }
  }
</script>
