<template>
  <section>
    <div class='row'>
      <div class='col s12'>
        <b>Auto reload</b><br>
        <span class='grey-text' v-show='actualActive'>
          ${{actualAmount}} when balance is below ${{actualThreshold}} | <a href @click.prevent='showForm = true; isSuccess=false'>Edit</a>
        </span>

        <!-- Switch -->
        <div class="switch right">
          <label>
            Off
            <input type="checkbox" @change='switched' v-model='actualActive'>
            <span class="lever"></span>
            On
          </label>
        </div>
      </div>
    </div>
    <div class='row' v-show='showForm'>
      <div class='col s12 m6'>
        <div class='card-panel'>
          <b>Edit Auto reload</b><br>

          <div class="input-field mt-25">
            <select v-model='actualAmount' id='amount-select'>
              <option value='5'>$5.00</option>
              <option value='10'>$10.00</option>
              <option value='50'>$50.00</option>
              <option value='100'>$100.00</option>
              <option value='250'>$250.00</option>
              <option value='500'>$500.00</option>
            </select>
            <label for='amount-select'>Amount</label>
          </div>

          <div class="input-field mt-25">
            <select v-model='actualThreshold' id='threshold-select'>
              <option value='10'>$10.00</option>
              <option value='20'>$20.00</option>
              <option value='30'>$30.00</option>
              <option value='50'>$50.00</option>
              <option value='100'>$100.00</option>
              <option value='250'>$250.00</option>
            </select>
            <label for='threshold-select'>When balance is below</label>
          </div>

          <a class='btn' @click='saveSettings' v-show='!isSaving'>Save</a>
          
          <center v-show='isSaving' class='grey-text'>
            <i class="fas fa-spinner fa-spin grey-text"></i> 
            Saving ...
          </center>

          <div class='right'>
            <a @click.prevent='showForm = false' class='pointer' v-show='!isSaving' href>
              Close
            </a>
          </div>
          <br>
        </div>
      </div>
    </div>

    <center v-show='isSuccess' class='green-text'>
      <i class="fas fa-check"></i> 
      Auto reload settings successfully saved!
    </center>
  </section>
</template>
<script>
  const apiAutoreloadPath = '/api/v1/vue/setup/autoreloads'

  export default {

    props: ['amount', 'threshold', 'active'],
    data() {
      return {
        actualAmount: this.amount,
        actualThreshold: this.threshold,
        actualActive: this.active,
        showForm: false,
        isSaving: false,
        isSuccess: false
      }
    },
    methods: {
      saveSettings() {
        this.isSaving = true
        this.$http.post(apiAutoreloadPath, { autoreload: { amount: this.actualAmount, threshold: this.actualThreshold }, active: this.actualActive }).then( response => {
          this.isSaving = false
          this.showForm = false
          this.isSuccess = true
        }, response => {
          console.log('error happened')
        })

      },
      switched() {
        this.saveSettings()
      }
    }
  }
</script>
