<script>
  export default {
    props: ['userId', 'currentState'],
    data() {
      return {
        state: this.currentState
      }
    },
    methods: {
      switched() {
        this.$http.patch('/api/v1/app/merchant/settings/allow_emails/' + this.userId, { type: this.type }).then(response => {
          this.notifySuccess('Email setting successfully updated')
        }, response => {
          this.notifyError('Something went wrong. Email setting not updated')
          this.state = !this.state
        })
      }
    },
    computed: {
      type() {
        if(this.state){ return "accountant"} else { return null }
      }
    }
  }
</script>

<template>
  <div>
    <div class= "very-close-lines">
      Allow receive emails for accountants group:<br>
      
      <div class="mt-5">
        <small class="grey-text">In addition for supervisor group user will receive all emails for the accountants</small>
      </div>
    </div>
    <!-- Switch -->
    <div class="switch">
      <label>
        Deny
        <input type="checkbox" @change='switched' v-model='state'>
        <span class="lever"></span>
        Allow
      </label>
    </div>
  </div>
</template>
