<template>
  <div>
    <center class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin grey-text"></i> 
      Loading Pin pad ...
    </center>
    <form name="myform" :action="pinPadPath" method="POST" ref='form'>
      <input v-for="entry in entries" type="hidden" :name="entry[0]" v-model="entry[1]">
    </form>
  </div>
</template>
<script>
  export default {
    props: ['pinPadPath'],
    data() {
      return {
        params: new URLSearchParams(window.location.search)
      }
    },
    created() {
      setTimeout(() => this.$refs.form.submit(), 300);
    },
    computed: {
      entries() {
        return this.params.entries()
      }
    }
  }
</script>
