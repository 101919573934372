<template>
  <div>
    <span class="btn-large pay-btn" @click='submit' :disabled='submitDisabled'>Pay with {{subtype}}</span>
  </div>
</template>
<script>
  export default {
    props: ["merchantQid", "amount", "note", "subtype", "cardId", "tipPercent", "tipAmount"],
    data() {
      return {
        submitDisabled: false
      }
    },
    methods: {
      submit() {
        this.submitDisabled = true
        this.$http.post("/api/v1/app/payment_by_cards", this.params).then(response => {
          this.submitDisabled = false

          Turbolinks.visit("/client/ach_transactions/" + response.body.payment.data.id)
        }, error => {
          this.submitDisabled = false
          this.errorHandler(error)
        })
      }
    },
    computed: {
      params() {
        return {
            qid: this.merchantQid,
            tip_amount: this.tipAmount,
            tip_percent: this.tipPercent,
            amount: this.amount,
            note: this.note,
            card_id: this.cardId
        }
      }
    }
  }
</script>
<style>
</style>
