<template>
  <div>
    <section v-show='showForm && !cookie'>
      <h3><b>Send payment</b></h3>
        <div class='small-font grey-text' style='margin-top: -15px;'>
          Pay for: {{subjectText}}
        </div>

      <div class='red-text' v-show='showFreePayementNotice'>
        Free promo, just click PAY <emojify :text="'\ud83d\ude0e'" />
      </div>

      <div class='row'>
        <div class='col s6' @click='amountClick'>
          <input name='amountName' value='0.00' step="0.01" type="number" v-validate="'min_value:0.01|required'" class='inputText' :disabled='true'> 
          <label>Amount, USD</label>
        </div>

        <div class='col s6'>
          <p>Receiver:<br> <b>{{merchant}}</b></p>
        </div>
      </div>

      <div class='large-font'>
        <div class="input-fields">
          <label for='first_name'>First name</label>
          <input 
            v-model='firstName'
            placeholder='Name...'   
            id="first_name"
            autofocus=true
            autocomplete="first_name"
            v-validate="'required'"
            name='firstName'>

          <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("firstName")}}</span>
        </div>

        <div class="input-fields">
          <label for='last_name'>Last name</label>
          <input 
            v-model='lastName'
            placeholder='Last name...'   
            autocomplete="last_name"
            v-validate="'required'"
            name='lastName'>

          <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("lastName")}}</span>
        </div>

        <div class="input-fields">
          <label for='email'>Email</label>
          <input 
            placeholder='email@example.com'
            type='email'
            v-model='email'
            autocomplete="email"
            v-validate="'required|email'"
            name='email'>

          <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("email")}}</span>
        </div>
      </div>

      <div class='row mt-25'>
        <div class='col s12'>
          <span class="btn-large right" @click='submitForm' :disabled='submitDisabled'>Pay</span>
        </div>
      </div>
    </section>

    <section v-show='cookie' class='mt-25'>
      <center>
        <emojify :text="'\ud83d\ude0e'" className='emo-100' /> <br>
          <h3>Hey!<br> You already paid this merchant!</h3>

          <p class='large-font'>
            Signup to get <b class='emerald'>$10</b> bonus and receive <b class='emerald'>20%</b> cash back for each transaction
          </p>

          <a class='btn-large mt-50' href='/unverified_signup/new' >Sign up and get $10 bonus</a>
      </center>
    </section>

    <section v-if='showVerificationCodeForm' class='mt-25'>
      <center>
        <div class='large-font'>
          A text message with a 4-digit verification code was just sent to
          <b>{{email}}</b><br>
        </div>
        <div>
          Wrong email? <span class='btn-flat' @click='editEmail'>Edit</span>
        </div>
      </center>
      <div class='mt-10'>
        <label>Enter the code</label>
        <input
          type='number'
          placeholder='1234'
          v-model='codeFromUser'
          autofocus=true
          v-validate="'required|numeric'"
          name='code'>
          <span class='red-text small-font' v-show='errors.first("code")'>{{errors.first("code")}}</span>

            <button class='btn-large mt-25' @click='verifyCode' style='width: 100%' >
              <i class="far fa-paper-plane"></i> 
              Send code
            </button>
            <br>

            <button class='btn mt-25 red' @click='skip' v-show='showSkip' style='margin-lef: 10px;'>
              skip
           </button>
      </div>
    </section>

    <center v-show='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>

    <after-free-payment
      v-show='!showForm && !showVerificationCodeForm && !showPending'
      :merchant='merchant'
      :amount='amount'
      :emailId='emailId'
      :authCode='authCode'/>
  </div>
</template>
<script>
  import afterFreePayment from './after_free_payment'
  import email from './email'
  export default {
    data() {
      return {
        submitDisabled: false,
        showForm: true,
        showVerificationCodeForm: false,
        showPending: false,
        showSkip: false,
        authCode: null,
        showFreePayementNotice: false,
        firstName: null,
        lastName: null,
        email: null,
        code: 'd19790cb-09e3-4d5b-b3f4-bba3268cbe20',
        codeFromUser: null,
        emailId: null,
        emailState: null,
        attempts: 0
      }
    },
    components: {afterFreePayment: afterFreePayment, email: email },
    props: [ 'amount', 'merchant', 'qid', 'stuff_qid', 'cookie', 'subject' ],
    methods: {
      submitForm() {
        this.submitDisabled = true
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$http.post('/api/v1/vue/free/emails', this.emailParams).then(response => {
              this.showForm = false
              this.showVerificationCodeForm = true

              this.code = response.body.code
              this.emailId = response.body.id
              this.emailState = response.body.state

              this.showSkipWithDelay()
            }, error => {
              this.notifyError(error.body.message)
              this.submitDisabled = false
            })
          } else {
            this.submitDisabled = false
          }
        })
      },
      amountClick() {
        this.showFreePayementNotice = true
      },
      skip() {
        this.showVerificationCodeForm = false
        this.showPending = true
        this.processPayment()
      },
      verifyCode() {
        if (this.code == this.codeFromUser) {
          this.notifySuccess('Code successfully verified!')
          this.showVerificationCodeForm = false
          this.showPending = true
          this.$http.patch('/api/v1/vue/free/emails/' + this.emailId, {state: 'verified'})
          this.processPayment()

        } else {
          console.log(this.attempts)
          this.codeFromUser = null
          this.attempts += 1

          if (this.attempts == 3) {
            this.submitDisabled = false
            this.showForm = true
            this.showVerificationCodeForm = false
            this.showPending = false
            this.authCode = null
            this.showFreePayementNotice = false
            this.firstName = null
            this.lastName = null
            this.email = null
            this.code = 'd19790cb-09e3-4d5b-b3f4-bba3268cbe20'
            this.codeFromUser = null
            this.emailId = null
            this.emailState = null
            this.attempts = 0
          }
          this.notifyError('Incorrect code!')
        }
      },
      processPayment() {
        this.$http.post('/api/v1/vue/free/payments', this.params).then(response => {
          this.showPending = false
          this.showAfterPayment = true
          this.authCode = response.body.payment.data.attributes.auth_code
        }, error => {
          this.notifyError(error.body.error.base[0])
          this.submitDisabled = false
        })
      },
      showSkipWithDelay() {
        setTimeout(()=>{ this.showSkip = true }, 10000 )
      },
      editEmail() {
        this.showVerificationCodeForm = false
        this.showForm = true
        this.submitDisabled = false
      }
    },
    computed: {
      params() {
        return {
          amount: this.amount,
          note:   this.note,
          qid:    this.qid,
          stuff_qid: this.stuff_qid,
          email_id: this.emailId
        }
      },
      emailParams() {
        return {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          stuff_qid: this.stuff_qid
        }
      },
      subjectText() {
        return this.subject.replace(/_/g, ' ')
      },
    }
  }
</script>
<style scoped>
</style>
