<template>
  <div>
    <center v-show='loading'>
      <i class="fas fa-spinner fa-spin grey-text"></i> 
      Loading ...
    </center>
    <bill-split-new @created='created' v-if='!billSplit && !loading'>
    </bill-split-new>

    <bill-split-active v-bind='billSplitProps' v-if='!loading && billSplit' @refresh='getBillSplit'>
    </bill-split-active>
  </div>
</template>
<script>
  export default {
    created() {
      this.getBillSplit()
    },
    data() {
      return {
        billSplit: null,
        loading: true
      }
    },
    methods: {
      getBillSplit() {
        this.$http.get('/api/v1/app/client/bill_split').then(response => {
          this.loading = false
          if (response.body.data) {
            this.billSplit = response.body
          } else if (this.billSplit) {
            Turbolinks.visit('/')
          }
        })
      },
      created(object) {
        this.billSplit = object
      }
    },
    computed: {
      billSplitProps() {
      return {
          qty:            this.billSplit.data.attributes.qty,
          amount:         this.billSplit.data.attributes.amount,
          qid:            this.billSplit.data.attributes.qid,
          amount_for_pay: this.billSplit.data.attributes.amount_for_pay,
          participants:   this.billSplit.included,
          refreshed_at:   this.billSplit.data.attributes.refreshed_at,
          bill_id:        this.billSplit.data.id
        }
      }
    }
  }
</script>
<style>
</style>
