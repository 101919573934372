import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hiddenPassword", "password", "show", "hide", "nonPrintable", "link"]

  show(event) {
    if(event) { event.preventDefault() }
    this.hiddenPasswordTarget.hidden = true
    this.passwordTarget.hidden = false
    this.showTarget.hidden = true
    this.hideTarget.hidden = false
  }

  hide(event) {
    event.preventDefault()
    this.hiddenPasswordTarget.hidden = false
    this.passwordTarget.hidden = true
    this.showTarget.hidden = false
    this.hideTarget.hidden = true
  }
  print() {
    this.show()
    this.linkTarget.hidden = false
    window.print()
  }

  cancel(event) {
    console.log("Boom!")
    event.preventDefault()
  }
}
