<template>
  <div v-show='readyForReward' class='card-panel'>
    <center>
      <img src='/reward-win.png'><br>
      <span class='reward-win'>
        {{rewardPercent}}% discount will be applied for this transaction
      </span>
      <br>
    </center>
  </div>
</template>
<script>
  export default {
    props: ['rewardPercent', 'readyForReward']
  }
</script>

<style scoped>
  .reward-win {
    color: red;
    font-weight: bold;
    font-size: 1.5em;
  }
</style>
