<template>
  <div>
    <div v-if='tips != null' class='row'>
      <div class='col s12 old-text how-balance low-text'>
        <div class='mt-15'>
          Today’s sales<span class='hide-on-med-and-up'> (incl tips)</span>: 
          <div class='right old-text how-balance low-text emerald bold-font'>{{totalSales | currency}}</div><br>
          <div v-show='todayOverallSales != totalSales'>
            All subs sales<span class='hide-on-med-and-up'> (incl sub-accounts)</span>: 
            <div class='old-text how-balance low-text right emerald bold-font'>{{todayOverallSales | currency}}</div>
          </div>
          <div v-show='nextBatchAmount != null'>
            Next batch<span class='hide-on-med-and-up'></span>: 
            <div class='old-text how-balance low-text right emerald bold-font'>{{nextBatchAmount | currency}}</div><br>
          </div>
        </div>
        <div v-show='showTips'>
          My tips today: 
          <div class='old-text how-balance low-text right emerald bold-font'>{{tips | currency}}</div>
        </div>
      </div>
    </div>
    <span v-else><i class="fas fa-spinner fa-spin grey-text show-balance"></i></span>
  </div>
</template>
<script>
  export default {
    props: ['showTips'],
    created() {
      this.getBalance();
    },
    data() {
      return {
        tips: null,
        todayCardSales: null,
        todayAchSales: null,
        totalSales: null,
        todayOverallSales: null,
        nextBatchAmount: null
      }
    },
    methods: {
      getBalance() {
        this.$http.get('api/v1/app/merchant/today_tips').then( response => {
          this.tips = response.body.todayTips
        })
        this.$http.get('api/v1/app/merchant/next_batches').then( response => {
          this.nextBatchAmount = response.body.next_batch_amount
        })
        this.$http.get('api/v1/app/merchant/today_sales').then( response => {
          this.todayCardSales = response.body.todayCardSales
          this.todayAchSales = response.body.todayAchSales
          this.totalSales = response.body.totalSales
          this.todayOverallSales = response.body.todayOverallSales
        })
      },
    }
  }
</script>
<style>
  .show-balance {
    font-size: 2em;
  }
</style>
