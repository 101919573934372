<template>
  <div>
    <button class='btn' @click='loadData' :disabled='reloadDisabled'>
      <i class="fas fa-sync-alt"></i> 
      Reload
    </button>
    <vue-good-table
      class='mt-10'
      :columns="columns"
      :rows="rows"
      :sort-options="{
          enabled: true,
          initialSortBy: {field: 'created_at', type: 'desc'}
        }"
      :pagination-options="{
          enabled: true,
          perPage: 30,
          perPageDropdown: false
        }"
      :search-options="{enabled: true}">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'business_name'">
          <a :href="props.row.show_path">{{props.formattedRow[props.column.field]}}</a>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  created() {
    this.loadData()
  },
  data(){
    return {
      reloadDisabled: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number'
        },
        {
          label: 'Business name',
          field: 'business_name'
        },
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Address',
          field: 'address'
        },
        {
          label: 'Created On',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyyMMdd',
          dateOutputFormat: 'MMM do yyyy'
        },
        {
          label: 'State',
          field: 'state'
        },
      ],
      rows: [],
    };
  },
  methods: {
    loadData() {
      this.reloadDisabled = true
      this.$http.get('/api/v1/vue/manager/merchants').then(response => {
        this.reloadDisabled = false
        console.log(response.body.data)
        this.rows = response.body.data.map(function(n){return n.attributes})
      }, error => {
        this.reloadDisabled = false
        this.notifyError(error.statusText)
      })
    }
  }
};
</script>
<style scoped>
  .select-dropdown {
    visibility: hidden;
    dispay: none !important;
  }
  .select-wrapper input.select-dropdown {
    dispay: none !important;

  }
  .caret {
    visibility: hidden;
  }
  .footer__row-count__select {
    margin-top: -10px
  }
</style>
