<template>
  <div>
    <!-- Tips section -->
    <section class='mt-10 grey-text center' v-if='showTips'>
      <tips :qid="merchantQid" :tips-updated="tipsUpdated"></tips>
    </section>

  </div>
</template>
<script>
  import { useCardsBillStore } from "./store"
  import { toRefs } from '@vue/composition-api'
  import tips from "../../../tips"

  export default {
    setup: () => ({ ...toRefs(useCardsBillStore()) }),
    components: { tips },
    props: [
      'showTips'
    ],
    methods: {
      tipsUpdated(selectedTip, tipCustomAmount) {
        this.selectedTip = selectedTip
        this.tipCustomAmount = tipCustomAmount
        this.updateAmounts()
      }
    }
  }
</script>
