<template>
  <div>
    <input type="number" pattern="\d*" @keyup="handleBlur" :name="name" v-model="routingNumber" /><br>
    <span v-show="bankName" class="grey-text text-darken-3">
      <small><i class="fas fa-university"></i> {{bankName}}</small>
    </span>
    <span v-show="routingNumber && !bankName" class="red-text">
      Bank not detected
    </span>
  </div>
</template>
<script>
  export default {
    created() {
      this.handleBlur()
    },
    props: ["name", "routing"],
    data() {
      return {
        routingNumber: this.routing,
        bankName: null
      }
    },
    methods: {
      handleBlur() {
        if(this.routingNumber.length > 5) {
          this.$http.get("/api/v1/app/admin/routings/" + this.routingNumber).then(response =>{
            this.bankName = response.body.bank_name
          })
        }
      }
    }
  }
</script>
<style>
</style>
