<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {pay {allowTipsPerSubAccount}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.pay
      }
    },
    data() {
      return {
        settings: {}
      }
    },
    methods: {
      switchTip() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($state: Boolean!, $gid: ID) {
            updateTipPerSubAccount(input: {allowTipsPerSubAccount: $state, userId: $gid}) {
              paySettings { allowTipsPerSubAccount }
              errors
            }
          }`,
          // Parameters
          variables: {
            state: !this.settings.allowTipsPerSubAccount,
            gid: this.gid
          },
        }).then(result => {
          if(result.data.updateTipPerSubAccount.errors.length > 0) {
            this.notifyError(result.data.updateTipPerSubAccount.errors[0])
            this.settings.allowTipsPerSubAccount = !this.settings.allowTipsPerSubAccount
          } else {
            this.settings.allowTipsPerSubAccount = result.data.updateTipPerSubAccount.paySettings.allowTipsPerSubAccount
            this.notifySuccess("Tips settings successfully updated")
          }
        }, error => {
          this.gqlErrorHandler(error)
          this.settings.allowTipsPerSubAccount = !this.settings.allowTipsPerSubAccount
        })
      }
    }
  }
</script>

<template>
  <div>
    <section>
      <div class= "very-close-lines">
        Activate individual per sub-account tips settings:<br>
        
        <small class="grey-text">Allows individual level sub-account tips settings</small>
      </div>
      <!-- Switch -->
      <div class="switch">
        <label>
          Group
          <input type="checkbox" @click='switchTip' v-model='settings.allowTipsPerSubAccount' :disabled="$apollo.loading">
          <span class="lever"></span>
          Individual
        </label>
      </div>
    </section>
  </div>
</template>
