<template>
  <div>
    <section class='large-font mt-25' v-if='showPhoneForm'>
      <center>
        <div class='big-font'>Please enter your phone</div>
      </center>
      <div class='mt-10'>
        <label>Phone</label>
        <input
          type='tel'
          placeholder='(373) 112-1122'
          v-model='phone'
          autofocus=true
          autocomplete="phone"
          v-validate="'required'"
          name='phone'>
          <span class='red-text small-font' v-show='errors.first("phone")'>{{errors.first("phone")}}</span>
          <br>

        <button class='btn-large mt-25' @click='sendPhone'>
          <i class="far fa-paper-plane"></i> 
          Verify
        </button>
      </div>
    </section>

    <section v-if='!showPhoneForm' class='mt-25'>
      <center>
        <div class='large-font'>
          A text message with a 4-digit verification code was just sent to<br>
          <b>{{formattedPhone}}</b>
        </div>
      </center>
      <div class='mt-10'>
        <label>Enter the code</label>
        <input
          type='number'
          placeholder='1234'
          v-model='codeFromUser'
          autofocus=true
          v-validate="'required|numeric'"
          name='code'>
          <span class='red-text small-font' v-show='errors.first("code")'>{{errors.first("code")}}</span>

          <button class='btn-large mt-25' @click='verifyCode' style='width: 100%' >
            <i class="far fa-paper-plane"></i> 
            Verify code
          </button>

          <div class='mt-25'>
            Didn't receive SMS? <a href @click.prevent='requestNewCode'>Request new code</a>
          </div>
      </div>
    </section>
  </div>
</template>
<script>
  export default {
    props: ['phoneNumber'],
    data() {
      return {
        showPhoneForm: true,
        showProfileSetupNotice: false,
        formattedPhone: null,
        phone: this.phoneNumber,
        codeFromUser: null,
        profileId: null
      }
    },
    methods: {
      sendPhone() {
        this.$http.post('/api/v1/app/verification_phones', this.phoneParams).then(response => {
          this.showPhoneForm          = false
          this.formattedPhone         = response.body.formatted_number
          this.profileId              = response.body.profile_id

          console.log(this.code)
        }, error => {
          this.showPhoneForm  = true
          this.notifyError(error.body.error)
        })
      },
      verifyCode() {
        this.$http.post('/api/v1/app/verification_phones/verify', { code: this.codeFromUser }).then(response => {
          this.notifySuccess(response.body.message)
          Turbolinks.visit('/profile/setup_unverified_client')
          
        }, error => {
          this.codeFromUser = null
          this.notifyError(error.body.error)
        })
      },
      requestNewCode() {
        this.codeFromUser = null
        this.showPhoneForm = true
      }
    },
    computed: {
      phoneParams() {
        return {
          number: this.phone
        }
      },
    }
  }
</script>
