<template>
  <div>
    <h3>Pay to merchant</h3>

    <section v-if="paymentQr">
      <qr-code
        v-if='paymentQr'
        :size='180'
        class='mt-25'
        :text='paymentQr'>
      </qr-code>
    </section>

    <div class='mt-25'>
      <countdown :left-time="endTime" @finish='getPaymentQr' class='red-text' :autoStart="false" ref="countdown">
        <span
          slot="process"
          slot-scope="{ timeObj }">{{ `Left time: ${timeObj.m}:${timeObj.s}` }}</span>
        <span slot="finish" class='red-text'>Run out of time!</span>
      </countdown>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['userId'],
    created() {
      this.getPaymentQr()
    },
    data() {
      return {
        paymentQr: null,
        expireAt: null
      }
    },
    methods: {
      getPaymentQr() {
        this.$http.get('/api/v1/app/payment_qr').then(response => {
          console.log(response.body)
          this.expireAt = response.body.expire_at
          this.paymentQr = response.body.payment_qr
          this.$refs.countdown.startCountdown(true)
        })
      }
    },
    computed: {
      endTime() {
        return this.$moment.utc(this.expireAt).diff() - 10000
      }
    }
  }
</script>
