export default {
  howDidWeDoToday: "¿Cómo nos ha ido hoy?",
  whatTheBadReason: "¿Cuál es la principal razón por la que no está satisfecho?",
  whatTheGoodReason: "Por favor, comparta sus experiencias felices",
  shareFeedback: "Comparta su opinión",
  leaveReview: "¿Quiere dejar su opinión?",
  yes: "Sí",
  no: "No",
  showSuccess: "Gracias por la respuesta",
  redirecting: "Redirigiendo..."
}
