<template>
  <div class="center" v-show="showFeedbackHub">
    <!-- Vote module -->
    <section v-show="!voted && showVote && notFinished">
      <h3 class="bold-font">{{$t("vote.howDidWeDoToday")}}</h3>
      <div v-if="useNpsVote">
        <div v-if="useNpsVote" @mouseover="hoverEffect" @mouseleave="clearHover" class="nps-container">
          <div class="nps-buttons">
            <span v-for="n in 10" :key="n" @click="npsVote(n)" class="nps-btn bold-font" :class="getClass(n)">{{ n }}</span>
          </div>
        </div>
      </div>

      <div v-else>
        <button class="btn btn-large wide-btn mt-25 vote-btn"  @click="vote(true)" :disabled="disabled">
          <i class="far fa-thumbs-up fa-flip-horizontal mt-25 icon-text"></i>
        </button>
        <button class="btn btn-large wide-btn mt-25 vote-btn" @click="vote(false)" :disabled="disabled">
          <i class="far fa-thumbs-down mt-25 icon-text"></i>
        </button>
      </div>
    </section>

    <!-- Slot module -->
    <slot v-if="(voted || !showVote) && !url" name="header" class="red-text"></slot>

    <!-- Review module (allows redirect to url) -->
    <section v-show="(voted || (!showVote && showReview)) && showReview && url && notFinished">
      <div v-if="redirecting" class="grey-text">
        <i class="fas fa-spinner fa-spin grey-text"></i> 
        {{$t("vote.redirecting")}}
      </div>

      <div v-else>
        <h3>{{$t("vote.leaveReview")}}</h3>
        <a :href="url" @click="redirect" class="mt-10 btn btn-large wide-btn vote-btn">
          <div class='big-font mt-25'><b>{{$t("vote.yes")}}</b></div>
        </a><br>
        <div class="mt-25">
          <a @click="notFinished = false">{{$t("vote.no")}}</a><br>
        </div>
      </div>

    </section>

    <!-- Feedback module (allow save local feedback) -->
    <section v-show="voted && showDownVoteForm">
      <label v-if="answer">{{$t("vote.whatTheGoodReason")}}</label>
      <label v-else>{{$t("vote.whatTheBadReason")}}</label>
      <textarea v-model="feedbackText"></textarea>
      <button @click="submitFeedback" :disabled="disabled" class="btn">{{$t("vote.shareFeedback")}}</button>
    </section>
  </div>
</template>
<script>
  export default {
    // Props with type and default values
    props: {
      transactionId: {
        type: String
      },
      showVote: {
        type: Boolean,
        default: true
      },
      showReview: {
        type: Boolean,
        default: true
      },
      // Hides whole feedback module (votes and reviews)
      showFeedbackHub: {
        type: Boolean,
        default: true
      },
      useNpsVote: {
        type: Boolean,
        default: false
      },
      paymentIntentExternalId: {
        type: String,
      },
      url: {
        type: String
      },
      transactionGid: {
        type: String
      },
      locale: {
        type: String,
        default: 'en'
      }
    },
    data() {
      return {
        answer: false,
        score: null,
        hovered: null,
        disabled: false,
        voted: false,
        notFinished: true,
        showDownVoteForm: false,
        feedbackText: null,
        voteId: null,
        redirecting: false
      }
    },
    methods: {
      npsVote(score) {
        this.score = score
        this.vote(null)
      },
      vote(answer) {
        this.answer = answer
        this.disabled = true
        this.voted = true
        this.$http.post("/api/v1/app/client/votes", this.params).then(response => {
          this.voteId = response.data.vote_id
          this.disabled = false
          if(!this.url) { this.showDownVoteForm = true }
        }, error => {
          this.errorHandler(error)
          this.disabled = false
        })
      },
      submitFeedback() {
        this.disabled = true
        this.$http.patch("/api/v1/app/client/vote_feedbacks/" + this.voteId, this.paramsFeedback).then(response => {
          this.disabled = false
          this.showDownVoteForm = false
          this.notifySuccess(response.data.message)
        }, error => {
          this.errorHandler(error)
          this.disabled = false
        })
      },
      redirect() {
        this.redirecting = true
      },
      hoverEffect(event) {
        if (event.target.matches('.nps-btn')) {
          this.hovered = parseInt(event.target.textContent);
        }
      },
      clearHover() {
        this.hovered = null;
      },
      getClass(n) {
        if (!this.hovered || n > this.hovered) return '';
        if (this.hovered >= 9) return 'high-hover';
        if (this.hovered >= 7) return 'mid-hover';
        return 'low-hover';
      }
    },
    computed: {
      params() {
        return {
          transaction_gid: this.transactionGid,
          transaction_id: this.transactionId,
          payment_intent_external_id: this.paymentIntentExternalId,
          posetive: this.answer,
          score: this.score
        }
      },
      paramsFeedback() {
        return {
          feedback: this.feedbackText
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .vote-btn {
    height: 100px !important;
  }
  .icon-text {
    font-size: 4em !important;
  }
  textarea {
    height: 200px;
  }
  .nps-btn {
    display: inline-block;
    margin: 1px; // Minimize margin to save space
    margin-top: 40px; // Add top margin to separate from other elements
    width: 17vw; // Smaller viewport width percentage
    height: 17vw; // Keep height proportional to the width
    line-height: 17vw; // Adjust line height to center text vertically
    text-align: center;
    border-radius: 50%;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 9vw; // Smaller font size to fit the button
    color: #555;
  }

  /* Medium screens (tablets) */
  @media (min-width: 768px) {
    .nps-btn {
      margin: 3px;
      width: 35px; // Standard size for medium screens
      height: 35px;
      line-height: 35px;
      font-size: 12px; // Increase font size for readability
    }
  }

  /* Larger desktop screens */
  @media (min-width: 1024px) {
    .nps-btn {
      margin: 5px;
      width: 60px; // Larger size for desktops
      height: 60px;
      line-height: 60px;
      font-size: 18px; // Larger font size for desktops
    }
  }

  .low-hover {
    background-color: red;
    color: white;
  }

  .mid-hover {
    background-color: orange;
    color: white;
  }

  .high-hover {
    background-color: green;
    color: white;
  }
</style>
