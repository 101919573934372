<template>
  <div>
    <center>
      <h3>Scan to Pay ${{amount_for_pay}}</h3>
      <b class='grey-text text-darken-3'>
        Split the bill ${{amount}} with {{qty}} participants
      </b>
      <qr-code
        :size='280'
        class='mt-25'
        :text='qrLink'>
      </qr-code>

      <button class='btn-large mt-25' @click='refresh' :disabled='refreshing'>
        <i class="fas fa-sync" :class="{'fa-spin': refreshing}"></i> Refresh
      </button><br>
      <button class='btn red mt-25' @click='stop'>
        <i class="far fa-stop-circle"></i> Stop
      </button>
    </center>

    <section class='mt-25'>
      <em><b>Participants {{participants.length}} of {{qty}}</b></em>

      <div v-for='(user, index) in participants'>
        {{index + 1}}. {{user.attributes.first_name}} {{user.attributes.last_name}}
      </div>

    </section>
    <center class='mt-10'>
    </center>
  </div>
</template>
<script>
  export default {
    props: {
      qty:            Number,
      amount:         Number,
      amount_for_pay: Number,
      participants:   Array,
      qid:            String,
      refreshed_at:   Number,
      bill_id:        String
    },
    data() {
      return {
        refreshing: false
      }
    },
    methods: {
      refresh() {
        this.refreshing = true
        this.$emit('refresh')
      },
      stop() {
        this.$http.delete('/api/v1/app/client/bill_split').then(response => {
          Turbolinks.visit('/')
        }, error => {
          this.notifyError('Unable to cancel Bill Splitting')
       })
      }
    },
    watch: {
      refreshed_at() {
        this.refreshing = false
      }
    },
    computed: {
      qrLink() {
        return '/pay_bill_splits/' + this.bill_id
      }
    }
  }
</script>
<style>
</style>

