<template>
  <div>
    <center>
      <svg width="300" height="300" class='on-video mt-25'>
        <polyline points="60 0 0 0 0 60" :stroke="color" stroke-width="15"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.5"/>

        <polyline points="130 0 170 0" :stroke="color" stroke-width="15"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.5"/>

        <polyline points="240 0 300 0 300 60" :stroke="color" stroke-width="15"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.5"/>

        <polyline points="0 240 0 300 60 300" :stroke="color" stroke-width="15"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.5"/>

        <polyline points="130 300 170 300" :stroke="color" stroke-width="15"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.5"/>

        <polyline points="240 300 300 300 300 240" :stroke="color" stroke-width="15"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.5"/>

        <polyline points="0 130 0 170" :stroke="color" stroke-width="15"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.5"/>

        <polyline points="300 130 300 170" :stroke="color" stroke-width="15"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.5"/>

        <polyline points="20 150 280 150" stroke="red" stroke-width="3"
              stroke-linecap="butt" fill="none" stroke-linejoin="miter" stroke-opacity="0.3">
          <animate
            attributeType="XML"
            attributeName="stroke-opacity"
            values="0;0.2;0.5;0.7;0.5;0"
            dur="2s"
            repeatCount="indefinite"/>
        </polyline>
      </svg>
      <div class='on-video mt-10'>Align QR code within frame to scan</div>
      <div class='on-video mt-10' v-show='isCromeOniOS'>ATTENTION!!! Chrome browser has limited access to camera on Apple devices. Please use Safari browser instead.</div>
      <div class='on-video mt-10 red-text' v-show='errorMessage'>Error: {{errorMessage}}</div>

      <qrcode-stream @decode="codeScanned" camera='auto' @init="onInit" :track="repaint"  class='fullscreen'></qrcode-stream>

        <div v-show='showError' class='on-video'>
          Scanned WRONG QR-code. Unable to process payment!
        </div>

        <br>
        <a class='btn on-video' href='/'>BACK</a>

    </center>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        color: '#00ab8e',
        show: true,
        showError: false,
        errorMessage: null,
        merchantTransaction: /(merchants|clients)\/[0-9a-fA-F\-]+/,
        payBillSplits: /\/pay_bill_splits\/[0-9a-fA-F]+/,
        isCromeOniOS: false
      }
    },
    created() {
      if (navigator.userAgent.match('CriOS')) { this.isCromeOniOS = true }
    },
    methods: {
      onInit (promise) {
        promise.then(() => {
        })
        .catch(error => {
          if (error.name === 'NotAllowedError') {
            this.errorMessage = 'Hey! I need access to your camera'
          } else if (error.name === 'NotFoundError') {
            this.errorMessage = 'Do you even have a camera on your device?'
          } else if (error.name === 'NotSupportedError') {
            this.errorMessage = 'Seems like this page is served in non-secure context (HTTPS, localhost or file://)'
          } else if (error.name === 'NotReadableError') {
            this.errorMessage = 'Couldn\'t access your camera. Is it already in use?'
          } else if (error.name === 'OverconstrainedError') {
            this.errorMessage = 'Constraints don\'t match any installed camera. Did you asked for the front camera although there is none?'
          } else {
            this.errorMessage = 'UNKNOWN ERROR: ' + error.message
          }
        })
      },
      logErrors (promise) {
        promise.catch(console.error)
      },
      codeScanned(code) {
        this.color = 'white'
        if (this.extractQR(code)) {
          Turbolinks.visit('/client/merchants/' + this.extractQR(code) + '/ach_transactions/new')
          this.show = false
        } else if (this.extractBillId(code)) {
          Turbolinks.visit('/client/pay_bill_splits/' + this.extractBillId(code))
          this.show = false
        } else {
          this.show = false
          this.showError = true
        }
      },
      // Allowed paths:
      // /client/merchants/:id/ach_transactions/new
      extractQR(code) {
        var matched_pattern = code.match(this.merchantTransaction)
        if (matched_pattern) {
          return matched_pattern[0].split('/').pop()
        } else {
          return false
        }
      },
      extractBillId(code) {
        let matched_pattern = code.match(this.payBillSplits)
        if (matched_pattern) {
          return matched_pattern[0].split('/').pop()
        } else {
          return false
        }
      },
      repaint (location, ctx) {
        const {
          topLeftFinderPattern,
          topRightFinderPattern,
          bottomLeftFinderPattern
        } = location

        const pointArray = [
          topLeftFinderPattern,
          topRightFinderPattern,
          bottomLeftFinderPattern
        ]
        ctx.fillStyle = '#00ab8e'

        pointArray.forEach(({ x, y }) => {
          ctx.fillRect(x - 5, y - 5, 10, 10)
        })
      }
    }
  }
</script>
<style>
  .fullscreen {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
      background-color: black;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
  }
  .on-video {
    z-index: 101;
    position: relative;
    color: white;
  }
  .camera-container {
    width: 300px !important;
    height: 300px !important;
  }
</style>
