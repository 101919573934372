<template>
  <div>
    <div class="row">
      <div class="row">
        <div class="input-field col s12 m6">
          <input 
            name="amount"
            autofocus
            v-model='amount'
            step="0.01"
            type="text" 
            inputmode="decimal"
            v-validate="'min_value:0.5|max_value:10000|required'"
            class='inputText'
            lang="en-001">
            <span class='red-text small-font' v-show='errors.first("amount")'>{{errors.first("amount")}}</span>

          <label for="amount">Amount</label>
        </div>
        <div class="input-field col s12 m6">
          <input
            id="phone"
            type="tel"
            class="validate"
            v-model="phone"
            name="phone"
            autocomplete="tel">
            <span class='red-text small-font' v-show='errors.first("phone")'>{{errors.first("phone")}}</span>

          <label for="phone">Phone</label>
        </div>
      </div>
      <span class='red-text'>{{errorMessage}}</span><br>
      <button @click="charge" v-show="!showPending && connectedReader" class="btn-large wide-btn mt-25" :disabled="disableSignupBtn">Charge <span v-show='amount'>${{amount}}</span></button>
      <button v-if="!connectedReader" @click="connectReader" class="btn-flat wide-btn mt-25">Connect reader</button>
      <center v-else class="mt-25">
        <i class="fas fa-circle emerald"></i>
        Reader Connected
      </center>
    </div>
    <center v-show='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>
  </div>
</template>
<script>
  const API_PATH = "/api/v1/app/stripe/payment_intents"

  export default {
    props: ['stripePublishableKey'],
    mounted() {
      this.loadStripeJs()
    },
    data() {
      return {
        amount: null,
        phone: null,
        errorMessage: null,
        disableSignupBtn: true,
        showPending: false,
        paymentIntentId: null,
        terminal: null,
        connectedReader: null
      }
    },
    methods: {
      charge() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.disableSignupBtn = true
            this.showPending = true
            // create Payment Intent
            this.$http.post(API_PATH, this.params).then(response => {
              this.paymentIntentId = response.body.id
              this.checkout(response.body.client_secret)
              console.log("PI ID", this.paymentIntentId)
            }, error => {
              this.errorHandler(error)
            })
          } else {
            this.notifyError("Please complete all required fields!")
          }
        })
      },
      checkout(clientSecret) {
        this.terminal.collectPaymentMethod(clientSecret).then(result => {
          if (result.error) {
            // Placeholder for handling result.error
          } else {
            console.log("Payment method collected for:", result.paymentIntent)
            this.terminal.processPayment(result.paymentIntent).then(function(result) {
              if (result.error) {
                // Placeholder for handling result.error
              } else if (result.paymentIntent) {
                console.log('authorized', result)
                // Placeholder for notifying your backend to capture result.paymentIntent.id
              }
            });
            // Placeholder for processing result.paymentIntent
          }
        });
      },
      loadStripeJs() {
        this.script_s= document.createElement('script')
        this.script_s.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(this.script_s)

        this.script= document.createElement('script')
        this.script.setAttribute('src', 'https://js.stripe.com/terminal/v1/')
        document.head.appendChild(this.script)
        this.script.onload = this.terminalInit
      },
      terminalInit() {
        console.log('Init term')
        this.disableSignupBtn = false
        this.terminal = StripeTerminal.create({
          onFetchConnectionToken: this.fetchConnectionToken,
          onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
        });
      },
      fetchConnectionToken() {
        return this.$http.get("/api/v1/app/stripe/initialize_direct_terminal").then(response => {
          console.log(response.body.connection_token)
          return response.body.connection_token
        })
      },
      unexpectedDisconnect() {
        this.connectedReader = null
        console.log('disconnected')
      },
      connectReader() {
        var config = {simulated: true};
        this.terminal.discoverReaders(config).then( result => {
          if (result.error) {
            console.log('Failed to discover: ', result.error);
          } else if (result.discoveredReaders.length === 0) {
            console.log('No available readers.');
          } else {
            // Just select the first reader here.
            var selectedReader = result.discoveredReaders[0];

            this.terminal.connectReader(selectedReader).then( connectResult => {
              if (connectResult.error) {
                console.log('Failed to connect: ', connectResult.error);
              } else {
                console.log('Connected to reader: ', connectResult.reader.label);
                this.connectedReader = connectResult.reader.label
              }
            });
          }
        });
      }
    },
    computed: {
      params() {
        return {
          amount: this.amount,
          phone: this.phone,
          tip_amount: 4.0,
          tip_percent: 0
        }
      }
    }
  }
</script>
