<template>
</template>
<script>
  export default {
    data() {
      return {
        blocked: false
      }
    },
    created() {
      this.$bus.$on('blockPageReload', this.updateBlocker)
      this.profileChannel = this.$cable.subscriptions.create({ channel: 'ProfileChannel' },
        { 
          received: this.profileUpdated
        }
      );
    },
    methods: {
      profileUpdated(data) {
        if(!this.blocked) { 
          window.location.href = "/profile"
          // Turbolinks.visit('/profile')
        }
      },
      updateBlocker(state) {
        this.blocked = state
      }
    }
  }
</script>
<style>
</style>
