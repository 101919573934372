<template>
  <div>
    <div class="btn" @click="voidTransaction" :disabled="processing" v-show="voidable">
      <span v-if="processing">
        <i class="fas fa-spinner fa-spin"></i> 
        PROCESSING ...
      </span>
      <span v-else>
        VOID
      </span>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    props: ['paymentId', 'paymentGid', 'voidable'],
    data() {
      return {
        processing: false
      }
    },
    mounted() {
      if (sessionStorage.getItem("showVoidedNotification") === "true") {
        sessionStorage.removeItem("showVoidedNotification");
        this.notifySuccess("Transaction voided successfully!");
      }
    },
    methods: {
      voidTransaction() {
        this.processing = true
        this.$apollo.mutate({
          mutation: gql`
            mutation($paymentId: ID!) {
              voidCashPayment(input: {paymentId: $paymentId}) {
                successMessage
                errors
              }
            }
          `,
          variables: {
            paymentId: this.paymentGid
          }
        }).then(response => {
          if (response.data.voidCashPayment.errors.length > 0) {
            this.processing = false;
            this.notifyError(response.data.chargeCash.errors[0]);
          } else {
            sessionStorage.setItem("showVoidedNotification", "true");
            Turbolinks.visit(window.location.pathname);
          }
        }).catch(error => {
          this.processing = false;
          this.gqlErrorHandler(error)
        });
      }
    }
  }
</script>
