<template>
  <div>
    <div id="payment-request-button" class='mt-10' v-show="allowAppleGooglePay && !allowPayByCardInput">
      <!-- A Stripe Element will be inserted here. -->
    </div>

    <button @click="selectManualInput" v-show="!showPending && !allowPayByCardInput" class="btn-large wide-btn outlined-btn mt-5">
      <i class="far fa-credit-card"></i> 
      Debit or Credit card
    </button>
  </div>
</template>
<script>
  import { useCardsBillStore } from "./store"
  import { toRefs } from '@vue/composition-api'

  export default {
    setup: () => ({ ...toRefs(useCardsBillStore()) }),
    props: [
      'stripePublishableKey',
      'linkOrderId',
      'merchantName',
      'hideVotes',
      'merchantVotesUrl',
      'merchantStripeId',
      'showVote',
      'showReview',
      'showFeedbackHub'
    ],
    mounted() {
      this.loadingStripeJS = true
      this.loadStripeJs()
    },
    data() {
      return {
        allowAppleGooglePay: true
      }
    },
    methods: {
      // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-create-payment-request-instance
      mountPaymentRequestButton() {
        // Create an instance of stripe.paymentRequest
        // https://stripe.com/docs/js/payment_request/create
        var paymentRequest = this.stripeForConnected.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: this.merchantName,
            amount: Math.round(this.grossAmount * 100) // Stripe expects amount in cents
          },
          requestPayerName: false,
          requestPayerEmail: false,
          disableWallets: ["link"]
        });

        // Create the paymentRequestButton Element
        var elements = this.stripeForConnected.elements();
        var prButton = elements.create('paymentRequestButton', {
          paymentRequest: paymentRequest,
          style: {
            paymentRequestButton: {
              theme: 'light-outline',
              height: '60px'
            }
          }
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then((result)=> {
          this.loadingStripeJS = false
          if (result) {
            this.allowPayByCardInput = false
            var paymentRequestButtonContainer = document.getElementById('payment-request-button')

            // Check if the container has any child nodes (previous button)
            if (paymentRequestButtonContainer.hasChildNodes()) {
              // Remove all child nodes (previous button) from the container
              paymentRequestButtonContainer.innerHTML = ''
            }

            prButton.mount('#payment-request-button')
          } else {
            this.allowPayByCardInput = true
            this.allowAppleGooglePay = false
            document.getElementById('payment-request-button').style.display = 'none'
          }
        });

        paymentRequest.on('paymentmethod', this.completePaymentByButton)
      },
      completePaymentByButton(ev) {
        // For now we allow all brands for A/G pay but for enable - just replace
        // the condition checker
        // if(this.cardBrandsAcceptable(ev.paymentmethod.card.brand)) {
        if(true) {
          // Payment Method object - https://stripe.com/docs/api/payment_methods/object
          var params = {
            qid: this.merchantQid,
            user_email: ev.payerEmail,
            user_name: ev.payerName,
            method_name: ev.methodName,
            funding: ev.paymentMethod.card.funding,
            brand: ev.paymentMethod.card.brand,
            amount: this.amountNumber,
            tip_percent: this.selectedTip,
            tip_amount: this.tipCustomAmount,
            link_order_id: this.linkOrderId
          }
          this.$http.post('/api/v1/vue/stripe/payment_intents', params).then(response => {
            var clientSecret = response.body.client_secret

            this.stripeForConnected.confirmCardPayment(
              clientSecret,
              {payment_method: ev.paymentMethod.id},
              {handleActions: false}
            ).then((confirmResult) => {

              if (confirmResult.error) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                // this.notifyError("Payment Failed!")
                this.notifyError(confirmResult.error.message)
                ev.complete('fail');
              } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11" instead
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (confirmResult.paymentIntent.status === "requires_action") {
                  // Let Stripe.js handle the rest of the payment flow.
                  this.stripeForConnected.confirmCardPayment(clientSecret).then(function(result) {
                    if (result.error) {
                      // The payment failed -- ask your customer for a new payment method.
                      this.notifyError("Payment Failed, try another payment method")
                      this.notice.push("Payment Failed, try another payment method")
                    } else {
                      // The payment has succeeded.
                      Turbolinks.visit(
                        "/a/thank_you?payment_intent_external_id=" +
                          confirmResult.paymentIntent.id +
                          "&url=" + this.merchantVotesUrl +
                          "&locale=" + this.$i18n.locale +
                          "&show_vote=" + this.showVote +
                          "&show_review=" + this.showReview +
                          "&show_feedback_hub=" + this.showFeedbackHub
                      )
                    }
                  });
                } else {
                  // The payment has succeeded.
                  Turbolinks.visit(
                    "/a/thank_you?payment_intent_external_id=" +
                      confirmResult.paymentIntent.id +
                      "&url=" + this.merchantVotesUrl +
                      "&locale=" + this.$i18n.locale +
                      "&show_vote=" + this.showVote +
                      "&show_review=" + this.showReview +
                      "&show_feedback_hub=" + this.showFeedbackHub
                  )
                }
              }
            })
          }, error => {
            this.notifyError(error.body.error_messages)
          })
        } else {
          this.notifyError(ev.paymentmethod.card.brand + " card not acceptable for processing at " + this.merchantName)
        }
      },
      loadStripeJs() {
        this.script= document.createElement('script')
        this.script.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(this.script)
        this.script.onload = this.setupStripe
      },
      setupStripe() {
        this.stripeForConnected = Stripe(this.stripePublishableKey, {
          stripeAccount: this.merchantStripeId,
          apiVersion: "2020-08-27"
        })

        this.updateAmounts()
        this.mountPaymentRequestButton()
      },
      selectManualInput() {
        this.allowPayByCardInput = true
        this.allowAppleGooglePay = false
      }
    },
    watch: { 
      grossAmount: function(newVal, oldVal) {
        if(this.allowAppleGooglePay || (this.stripeForConnected && !this.allowPayByCardInput)) {
          this.mountPaymentRequestButton()
        }
      }
    }
  }
</script>
