<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {subaccHistoryLimit {days}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.subaccHistoryLimit
      }
    },
    data() {
      return {
        settings: {},
        showForm: false
      }
    },
    methods: {
      update() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($days: Int!, $gid: ID) {
            updateSubaccHistoryLimit(input: {days: $days, userId: $gid}) {
              subaccHistoryLimit { days }
            }
          }`,
          // Parameters
          variables: {
            days: parseInt(this.settings.days),
            gid: this.gid
          },
        }).then(result => {
          this.settings.days = result.data.updateSubaccHistoryLimit.subaccHistoryLimit.days
          this.showForm = false
          this.notifySuccess("Limit successfully updated")
        }, error => {
          this.gqlErrorHandler(error)
          this.settings.requireInvoiceField = !this.settings.requireInvoiceField
        })
      }
    }
  }
</script>

<template>
  <div>
    <section class="row">
      <div class= "very-close-lines">
        Transaction history limit for sub-accounts:<br>
        
      </div>

      <div class="mt-25 grey-text">
        {{settings.days}} day(s) | <a class="pointer" @click="showForm = !showForm"><i class="far fa-edit"></i> Edit</a>
      </div>

      <div class="col s6 m2" v-show="showForm">
        <input v-model="settings.days" type="number" />
        <button class="btn" @click="update">Update</button>
      </div>
    </section>
  </div>
</template>
