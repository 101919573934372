<template>
  <div>
    <h3>Receive Money</h3>

    <qr-code
      :size='180'
      class='mt-25'
      :text='qid'>
    </qr-code>

    <div> 
      <vue-barcode value="1000000000asdasd00" format="CODE128" height='80' width='1' :display-value='false'>
          Unable to render bar code
      </vue-barcode>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['qid']
  }
</script>
