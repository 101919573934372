<template>
  <span @click="click">
    <span v-show="showSlot">
      <slot></slot>
    </span>
    <span v-show="!showSlot">
      <i class="fas fa-spinner fa-spin grey-text" :class="iconClass"></i> <span :class="textClass">{{text}}</span>
    </span>
  </span>
</template>
<script>
  export default {
    props: ["iconClass", "text", "textClass"],
    data() {
      return {
        showSlot: true
      }
    },
    methods: {
      click() {
        this.showSlot = false
      }
    }
  }
</script>
<style>
</style>
