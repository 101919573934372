<template>
  <div>
    <div v-if='!preScreen && !showWaitingMessage'>
      <div v-if='sessionId'>
        <div class='mt-25'>
          <b>Answer following questions</b>
          (<countdown :end-time="new Date().getTime() + 120000" @finish='outOfTime' class='red-text'>
            <span
              slot="process"
              slot-scope="{ timeObj }">{{ `Left time: ${timeObj.m}:${timeObj.s}` }}</span>
            <span slot="finish" class='red-text'>Run out of time!</span>
          </countdown>):
        </div>
        <br>
        <div v-for='question in questions' class='card-panel'>
          <b>{{question.text}}</b>
          <hr>
          <div class="row">
            <div
              v-for='answer in question.answers'
              @click='answerClick(question.id, answer.id)'
              :class="[isSelected(question.id, answer.id) ? 'selected' : 'question']"
              class='col s12 m5 waves-effect waves-light hoverable'>

              {{answer.text}}
            </div>
          </div>
        </div>
        <div disabled='selectedLength != questions.length'>
          <button class='btn v-align' :disabled='selectedLength != questions.length' @click='submitAnswers'>Submit</button>
          <small class='red-text' v-if='selectedLength != questions.length'>
            <br>* Answer please all question above
          </small>
        </div>
      </div>
      <div v-else class='mt-25'>
        <center>
          <i class="fas fa-spinner fa-spin grey-text"></i>
          Preparing questions ...
        </center>
      </div>
    </div>
    <div v-else v-show='!showWaitingMessage' class='card-panel'>
      <center>
        <h3 class='emerald'><b><i class="fas fa-exclamation-triangle emerald"> </i> Verification Questions</b></h3>
        <hr>
        Answer the following 4 verification questions within 2 minutes:<br>
        <button class='btn mt-25' @click='continuePressed'>Continue</button>
      </center>
    </div>
    <div v-show='showWaitingMessage' class='mt-25'>
      <center>
        <i class="fas fa-spinner fa-spin grey-text"></i> {{waitingMessage}}
      </center>
    </div>
  </div>
</template>
<script>
  export default {
    created() {
    },
    data() {
      return {
        questions: [],
        sessionId: null,
        preScreen: true,
        selected: {},
        runOutOfTime: false,
        waitingMessage: 'Please wait ...',
        showWaitingMessage: false
      }
    },
    methods: {
      initiateKbaSession() {
        this.$http.get('/api/v1/app/verification/kba/questions').then(response => {
          this.questions = response.body.questions
          this.sessionId = response.body.sessionId
        }, error => {
          Rollbar.error('Error pull questions', {error: error})
          this.notifyError('Unable to reload questions!')
        })
      },
      answerClick(questionId, answerId) {
        this.selected = {...this.selected, ...{[questionId]: answerId}}
      },
      isSelected(questionId, answerId){
        return this.selected[questionId] == answerId
      },
      continuePressed() {
        this.preScreen = false
        this.initiateKbaSession()
      },
      outOfTime() {
        this.notifyError('Ran out of time!')
      },
      submitAnswers() {
        this.showWaitingMessage = true
        this.waitingMessage = 'Questions have been submitted! Processing...'
        this.$http.post('/api/v1/app/verification/kba/answers', {
          answers: this.answersPayload, sessionId: this.sessionId }).then(response => {
          }, error => {
            Rollbar.error('Error pull questions', {error: error})
            this.notifyError(error.body.error)
            this.waitingMessage = 'Updating account status...'
          })
      }
    },
    computed: {
      selectedLength() {
        return Object.keys(this.selected).length
      },
      answersPayload() {
        return Object.entries(this.selected).map( 
          function(element) {
            return {questionId: element[0], answerId: element[1]}
          }
        )
      }
    }
  }
</script>
<style scoped>
  .question {
    border: 2px solid #00ab8e;
    color: #00ab8e;
    padding: 10px !important;
    margin: 5px;
    cursor: pointer;
  }
  .selected {
    padding: 10px !important;
    margin: 5px;
    background-color: #00ab8e;
    color: white;
    border: 2px solid #00ab8e;
  }
</style>
