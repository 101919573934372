<template>
  <div>
    <section v-show='showPendingSetup'>
      <i class="fas fa-spinner fa-spin grey-text"></i> 
      Setting up account. Please wait
    </section>

    <section v-show='!showPendingSetup' class='red-text'>
      Unable setup account: <br>
      <b>
        {{errorMessage}}
      </b>
    </section>
    <span v-show='showButton' class='btn-large' @click='refresh'>
      <i class="fas fa-sync"></i> Refresh
    </span>

  </div>
</template>
<script>
  export default {
    created() {
      this.setupAccount()
    },
    data() {
      return {
        showPendingSetup: true,
        apiPath: '/api/v1/app/verification/unverified_client/dwolla_customers',
        errorMessage: null,
        showButton: false
      }
    },
    methods: {
      setupAccount() {
        this.$http.post(this.apiPath).then(response => {
          this.notifySuccess(response.body.message)
          setTimeout(()=>{ Turbolinks.visit('/') }, 5000 )
        }, error => {
          this.notifyError(error.body.error)
          this.showPendingSetup = false
          this.errorMessage = error.body.error
        })
      },
      refresh() {
        Turbolinks.visit('/')
      }
    }
  }
</script>
