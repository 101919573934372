<template>
  <div>
    <button class="btn-small" :disabled="processing" @click="create">
      <i v-show="processing" class="fas fa-spinner fa-spin grey-text"></i>
      {{label}}
    </button>
  </div>
</template>
<script>
  export default {
    props: ["code", "state"],
    data() {
      return {
        processing: false
      }
    },
    methods: {
      create() {
        this.processing = true
        this.$http.patch("/api/v1/app/merchant/link_checks/" + this.code, this.params).then(response =>{
          Turbolinks.visit(window.location)
        }, error => {
          this.processing = false
          this.errorHandler(error)
        })
      }
    },
    computed: {
      label() {
        if(this.state == "active") {
          return "Deactivate"
        } else {
          return "Activate"
        }
      },
      params() {
        if(this.state == "active") {
          return {state: "inactive"}
        } else {
          return {state: "active"}
        }
      }
    }
  }
</script>
<style>
</style>
