<template>
  <div>
    <div>
      <center v-show="showSuccess">
        <i class="fas fa-check-circle fa-3x emerald mt-50"></i>
        <div class='large-font bold-font grey-text text-darken-3 mt-10'><b>Successfully voided</b></div>
        <div class="btn btn-large mt-50" @click="click">Next</div>
      </center>

      <center class='big-font' v-show="!showSuccess">
        Download iWallet Business app now:
        <section class='mt-25'>
            <a href='https://apps.apple.com/us/app/iwallet-business/id1488129902'>
              <img width='280' src='https://iwallet.com/images/apple_store_icon.png' alt='iOS App'>
            </a> 

            <br>

            <a href='https://play.google.com/store/apps/details?id=com.iwallet.android_business'>
              <img width='280' src='https://iwallet.com/images/google_pay_icon.png' alt='Android App'>
            </a>
        </section>
      </center>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["showVoided"],
    data() {
      return {
        showSuccess: this.showVoided
      }
    },
    methods: {
      click() {
        Turbolinks.visit("/")
      }
    }
  }
</script>
