<template>
  <div class='container'>
    <!-- <img src='https://iwallet.com/b/image5.jpg' @load='loadSuccess(5)' v-show='false'> -->
    <!-- <img src='https://iwallet.com/b/image4.jpg' @load='loadSuccess(4)' v-show='false'> -->
    <!-- <img src='https://iwallet.com/bt/image3.jpg' @load='loadSuccess(3)' v-show='false'> -->
    <!-- <img src='https://iwallet.com/b/image2.jpg' @load='loadSuccess(2)' v-show='false'> -->
    <img src='https://iwallet.com/b/image1.jpg' @load='loadSuccess(1)' v-show='false'>
    <vueper-slides
      :bullets-outside='true'
      :arrows="false">
      <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image"/>
    </vueper-slides>
  </div>
</template>
<script>
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'

  export default {
    components: { VueperSlides, VueperSlide },
    data: () => ({
      slides: []
    }),
    methods: {
      loadSuccess(number) {
        this.slides.push({image: `https://iwallet.com/t/image${number}.jpg`})
      }
    }
  }
  
</script>
<style>
  .vueperslides__bullet--active .default {
    background-color: #00ab8e;
    border: 0px;
  }
</style>
