var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"btn",attrs:{"disabled":_vm.reloadDisabled},on:{"click":_vm.loadData}},[_c('i',{staticClass:"fas fa-sync-alt"}),_vm._v(" \n    Reload\n  ")]),_vm._v(" "),_c('vue-good-table',{staticClass:"mt-10",attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
        enabled: true,
        initialSortBy: {field: 'created_at', type: 'desc'}
      },"pagination-options":{
        enabled: true,
        perPage: 30,
        perPageDropdown: false
      },"search-options":{enabled: true}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'business_name')?_c('span',[_c('a',{attrs:{"href":props.row.show_path}},[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]):_c('span',[_vm._v("\n        "+_vm._s(props.formattedRow[props.column.field])+"\n      ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }