<script>
  import gql from 'graphql-tag'

  export default {
    props: ['id', 'percent', 'fixedCents', 'tipsFeeCents', 'appleGooglePayPercent', 'surcharge'],
    data() {
      return {
        showForm: false,
        settings: {}
      }
    },
    mounted() {
      this.setInitial()
    },
    methods: {
      update() {
        this.$http.post('/api/v1/app/merchant/settings/payment_fees', { id: this.id, settings: this.settings}).then(response => {
          this.showForm = false
          this.notifySuccess('Setting successfully updated')
        }, response => {
          this.notifyError('Something went wrong. Settins not updated')
          this.setInitial
        })
      },
      setInitial() {
        this.settings = {
          percent: this.percent,
          fixedCents: this.fixedCents,
          tipsFeeCents: this.tipsFeeCents,
          appleGooglePayPercent: this.appleGooglePayPercent,
          surcharge: this.surcharge
        }
      }
    }
  }
</script>

<template>
  <span>
    <span class="grey-text">
      (
      Fee: {{settings.percent}}% + {{settings.fixedCents }}c + 
      Tips fee: {{ settings.tipsFeeCents }}c + A/G Pay fee: {{ settings.appleGooglePayPercent }}%
      | Surcharge: {{settings.surcharge}}
      )
      <a class="pointer" @click="showForm = !showForm">
        <span v-if="!showForm">
          <i class="far fa-edit"></i> Edit
        </span>

        <span v-else>
          <i class="fas fa-times"></i>
        </span>
      </a>
    </span>

    <div v-show="showForm">
      <label>Percent</label>
      <input v-model="settings.percent" type="text" />

      <label>Fixed Cents</label>
      <input v-model="settings.fixedCents" type="text" />

      <label>Tips fee Cents</label>
      <input v-model="settings.tipsFeeCents" type="text" />

      <label>A/G Pay fee</label>
      <input v-model="settings.appleGooglePayPercent" type="text" />

      <!-- Switch -->
      Surcharge:
      <div class="switch">
        <label>
          Off
          <input type="checkbox" v-model="settings.surcharge">
          <span class="lever"></span>
          On
        </label>
      </div>

      <button class="btn mt-10" @click="update">Update</button>
    </div>
  </span>
</template>
