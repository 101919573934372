<template>
  <div>
    <section class='large-font mt-25' v-if='showPhoneForm'>
      <center>
        <div class='big-font'>Please fill in the form to get {{subjectText}}</div>
      </center>
      <div class='mt-10'>
        <label>Email</label>
        <input
          type='email'
          placeholder='abc@example.com'
          v-model='email'
          autofocus=true
          autocomplete="email"
          v-validate="'required|email'"
          name='phone'>
          <span class='red-text small-font' v-show='errors.first("phone")'>{{errors.first("email")}}</span>

        <label>First name</label>
        <input
          type='text'
          v-model='firstName'
          autocomplete="first_name"
          v-validate="'required'"
          name='phone'>
          <span class='red-text small-font' v-show='errors.first("firstName")'>{{errors.first("firstName")}}</span>

        <label>Last name</label>
        <input
          type='text'
          v-model='lastName'
          autocomplete="last_name"
          v-validate="'required'"
          name='phone'>
          <span class='red-text small-font' v-show='errors.first("lastName")'>{{errors.first("lastName")}}</span>

        <button class='btn-large mt-25' @click='sendPhone'>
          Get {{subjectText}}
        </button>
      </div>
    </section>

    <after-free-payment
      v-if='showAfterPayment'
      :merchant='merchant'
      :qid='qid'
      :amount='amount'
      :phoneId='phoneId'
      :authCode='authCode'/>

  </div>
</template>
<script>
  import afterFreePayment from './phone_after_free_payment'
  export default {
    components: { afterFreePayment },
    props: ['amount', 'merchant', 'qid', 'stuff_qid', 'subject'],
    created() {
      if(this.phoneIdFromCookie) {
        this.showPhoneForm = false
        this.showPending = true
        this.sendPhone()
      }
    },
    data() {
      return {
        email: null,
        firstName: null,
        lastName: null,
        showPhoneForm: true,
        showAfterPayment: false,
        authCode: null
      }
    },
    methods: {
      sendPhone() {
        this.$http.post('/api/v1/vue/free/emails/create_with_details', this.emailParams).then(response => {
          console.log(response.body)
        }, error => {
          this.showPhoneForm  = true
          this.showPending    = false
          this.notifyError(error.body.message)
          this.phone = this.phoneNumberFromCookie
        })
      }
    },
    computed: {
      subjectText() {
        return this.subject.replace(/_/g, ' ')
      },
      emailParams() {
        return {
          email: this.email,
          first_name: this.firstName,
          last_name: this.lastName
        }
      },
      params() {
        return {
          amount: this.amount,
          qid:    this.qid,
          stuff_qid: this.stuff_qid,
          phone_id: this.phoneId
        }
      }
    }
  }
</script>
