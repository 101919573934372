<template>
  <div>
    <center>
      <h3>Re-send signature capture</h3>
      <center v-show='loading' class='grey-text'>
        <i class="fas fa-spinner fa-spin grey-text"></i> 
        Loading ...
      </center>
      <section v-show="!loading && !success">
        <div class="row">
          <div class="col s12">
            <div class="input-field col s12 m6 center">
              <vue-tel-input :autofocus='true' v-model="phone" wrapperClasses="customPhoneInput" placeholder="Cardholder phone number"></vue-tel-input>
            </div>
            <button class="btn-large mt-25" @click="resend" :disabled="sendDisabled">
              Re-send
            </button>
          </div>
        </div>
      </section>

      <section v-show="success" class="emerald center">
       <h3 class='emerald'>Signature capture successfully sent!</h3>

        <a href="/" class="btn-large mt-50">OK</a>
      </section>
    </center>
  </div>
</template>
<script>
  export default {
    props: ["transactionId"],
    data() {
      return {
        sendDisabled: false,
        loading: true,
        phone: null,
        success: false
      }
    },
    created() {
      this.getPhone()
    },
    methods: {
      resend() {
        this.sendDisabled = true
        this.$http.patch("/api/v1/app/merchant/manual_charges/" + this.transactionId, {phone: this.phone}).then(response => {
          this.notifySuccess(response.body.message)
          this.success = true
        }, error => {
          this.errorHandler(error)
          this.sendDisabled = false
        })
      },
      getPhone() {
        this.$http.get("/api/v1/app/merchant/manual_charges/" + this.transactionId).then( response => {
          this.phone = response.body.phone.slice(2,15)
          this.loading = false
        })
      }
    }
  }
</script>
<style>
</style>
