<template>
  <div>
    <span class="red badge new" v-show='count > 0'>{{count}}</span>
  </div>
</template>
<script>
  export default {
    props: ['userId'],
    created() {
      this.getNotifications()
    },
    data() {
      return {
        count: 0
      }
    },
    methods: {
      getNotifications() {
        this.$http.get('/api/v1/vue/notifications/total').then(response => {
          this.count = response.body
        })
      }
    }
  }
</script>
<style>
</style>
