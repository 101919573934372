<template>
  <div>
    <div v-if="processing">
      <center class='grey-text mt-25'>
        <i class="fas fa-spinner fa-spin"></i> {{$t("sendBill.processing")}}
      </center>
    </div>
    <div v-else id="paypal-button-container" class="mt-25"></div>
  </div>
</template>

<script>
  export default {
    name: 'PaypalButton',
    props: {
      paypalClientId: {
        type: String,
        required: true
      },
      paypalMerchantId: {
        type: String,
        required: true
      },
      linkOrderId: {
        type: String,
        required: true
      },
      bnCode: {
        type: String,
        required: true
      },
      merchantVotesUrl: {
        type: String,
        required: true
      },
      showVote: {
        type: Boolean,
        required: true
      },
      showReview: {
        type: Boolean,
        required: true
      },
      showFeedbackHub: {
        type: Boolean,
        required: true
      },
      useNpsVote: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        orderId: null,
        paymentId: null,
        processing: false
      }
    },
    mounted() {
      this.loadPaypalSdk();
    },
    methods: {
      loadPaypalSdk() {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${this.paypalClientId}&merchant-id=${this.paypalMerchantId}&enable-funding=venmo&disable-funding=credit,card,sofort`;
        script.onload = this.initPaypalButton;
        script.setAttribute('data-partner-attribution-id', this.bnCode);
        document.head.appendChild(script);
      },
      initPaypalButton() {
        this.$nextTick(() => {
          if (window.paypal && document.getElementById('paypal-button-container')) {
            window.paypal.Buttons({
              // Set up the transaction
              createOrder: () => {
                this.$emit('input', true)
                return fetch("/api/v1/paypal/orders", {
                  method: "post",
                  headers: {
                    "Content-Type": "application/json",
                  }, 
                  // use the "body" param to optionally pass additional order information
                  // like product skus and quantities
                  body: JSON.stringify({
                    id: this.linkOrderId
                  })
                })
                .then((response) => response.json())
                .then((order) => {
                  this.orderId = order.order_id
                  this.paymentId = order.payment_id

                  return this.orderId
                });
              },
              // Finalize the transaction
              onApprove: (data, actions) => {
                let params = {payment_id: this.paymentId}
                this.$http.patch("/api/v1/paypal/orders/" + this.linkOrderId, params).then(response => {
                  this.processing = true

                  Turbolinks.visit(
                    "/a/thank_you?transaction_id=" + this.paymentId +
                    "&url=" + this.merchantVotesUrl +
                    "&locale=" + this.$i18n.locale +
                    "&show_vote=" + this.showVote +
                    "&show_review=" + this.showReview +
                    "&show_feedback_hub=" + this.showFeedbackHub +
                    "&use_nps_vote=" + this.useNpsVote
                  )
                }, error => {
                  this.errorHandler(error)
                  this.$emit('input', false)
                  this.processing = false
                })
              },
              // Handle error cases
              onError: err => {
                this.notifyError(err.message)
                this.$emit('input', false)
                this.processing = false
              },
              onCancel: data => {
                this.notifyError("Payment cancelled")
                this.$emit('input', false)
                this.processing = false
              }
            }).render('#paypal-button-container');
          }
        });
      }
    }
  }
</script>
