import { render, staticRenderFns } from "./sub_acc_access_to_cof.vue?vue&type=template&id=4db31db8&"
import script from "./sub_acc_access_to_cof.vue?vue&type=script&lang=js&"
export * from "./sub_acc_access_to_cof.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports