<template>
  <div>
    <section class="main-form"> 
      <div class="row">
        <div class="input-field col s12 m6">
          <i class="fas fa-dollar-sign prefix grey-text"></i>
          <input 
            name="amount"
            @input="updateAmounts"
            autofocus
            v-model='amount'
            step="0.01"
            type="text" 
            inputmode="decimal"
            v-validate="'min_value:0.01|required'"
            class='inputText'
            lang="en-001">
            <span class='red-text small-font' v-show='errors.first("amount")'>{{errors.first("amount")}}</span>

          <label for="amount">Amount</label>
        </div>
        <div class="input-field col s12 m6">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input name="invoice" id="invoice" type="text" v-model="invoice" ref="invoice">

          <label for="invoice">{{invoiceLabel}}</label>
        </div>

        <div class="input-field col s12 m6" v-if="requireInvoiceConfirmation">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input name="invoice-cfm" id="invoice-cfm" type="text" v-validate="{required: invoice.length > 0, confirmed: 'invoice'}">

          <label for="invoice-cfm">Invoice (confirmation)</label>
          <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
        </div>

        <div class="input-field col s12 m6">
          <i class="fas fa-sticky-note prefix grey-text"></i>
          <input name="note" id="note" type="text" v-model="note" ref="note">

          <label for="note">Note (Optional)</label>
        </div>
      </div>
      <div ref="card"></div><br>
      <span class='red-text'>{{errorMessage}}</span><br>
      <span class='red-text'>{{processingErrorMessage}}</span><br>

      <center>
        <section class='mt-10' v-if='showTips'>
          <tips :qid="qid" :tips-updated="tipsUpdated"></tips>
        </section>
      </center>

      <button @click="charge" v-show="!showPending" class="btn-large wide-btn mt-50" :disabled="disableSignupBtn || !phoneIsValid">
        charge<span v-show='amount'> for {{withTipAmount}}</span>
      </button>
    </section>
    <center v-show='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>
  </div>
</template>
<script>
  import tips from "../../tips"

  export default {
    props: ['stripePublishableKey', 'showTips', 'qid', 'requireInvoiceField', 'requireInvoiceConfirmation', 'setAmount', 'setPhone', 'setNote', 'setInvoice'],
    components: {tips},
    mounted() {
      this.loadStripeJs()
    },
    data() {
      return {
        amount: this.setAmount,
        grossAmount: 0,
        feeAmount: 0,
        withTipAmount: 0,
        phone: this.setPhone,
        token: null,
        elements: null,
        card: null,
        errorMessage: null,
        processingErrorMessage: null,
        disableSignupBtn: true,
        showPending: false,
        phoneIsValid: true,
        transactionAmount: null,
        transactionId: null,
        selectedTip: 0,
        tipCustomAmount: 0,
        note: this.setNote,
        invoice: this.setInvoice,
        hideZip: false
      }
    },
    methods: {
    charge() {
        this.processingErrorMessage = null
        this.errorMessage = null
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.disableSignupBtn = true
            this.showPending = true
            this.stripe.createToken(this.card).then(this.resultHandler)
          } else {
            this.notifyError("Please complete all required fields!")
          }
        })
      },
      resultHandler(result) {
        if (result.error) {
          this.notifyError(result.error.message)
          this.processingErrorMessage = result.error.message
          this.showPending = false
          this.disableSignupBtn = false
        } else {
          this.token = result.token.id
          this.$http.post("/api/v1/app/merchant/manual_charges/merchant_approve", this.params).then(response => {
            this.transactionAmount = response.body.payment.data.attributes.amount_formatted
            this.transactionId = response.body.payment.data.id
            window.location.href = '/client/ach_transactions/' + response.body.payment.data.id
          }, error => {
            // if(error.body.retry_with_zip) {
            if(false) {
              let resubmitNotice = "Card declined! Try to resubmit with zip code"
              this.notifyError(resubmitNotice)
              this.processingErrorMessage = resubmitNotice
              this.hideZip = false
              this.setupStripe()
            } else {
              this.errorHandler(error)
              if(error.status == 422) {
                this.processingErrorMessage = error.body.error_messages[0]
              }
            }
            this.showPending = false
            this.disableSignupBtn = false
          })
        }
      },
      loadStripeJs() {
        this.script= document.createElement('script')
        this.script.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(this.script)
        this.script.onload = this.setupStripe
      },
      setupStripe() {
        this.stripe = Stripe(this.stripePublishableKey)
        this.elements = this.stripe.elements({locale: 'en'})
        this.card = this.elements.create('card',
          {
            hidePostalCode: this.hideZip,
            iconStyle: 'solid',
            style: {
              base: {
                iconColor: '#c4f0ff',
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '18px'
              }
            }
          }
        )
        this.card.mount(this.$refs.card)

        this.card.on('change', this.onChange);
      },
      onChange({error, complete}) {
        if (error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = '';
        }
        if (complete) {
          this.disableSignupBtn = false
        }
      },
      phoneValidate(result) {
        this.phoneIsValid = result.isValid

        if(result.isValid) {
          this.card.focus()
        }
      },
      tipsUpdated(selectedTip, tipCustomAmount) {
        this.selectedTip = selectedTip
        this.tipCustomAmount = tipCustomAmount
        this.updateAmounts()
      },
      updateAmounts() {
        this.$http.get("/api/v1/vue/gross_calculations", {params: this.paramsGrossCalc}).then(response => {
          this.grossAmount = response.body.gross_amount
          this.feeAmount = response.body.fee
          this.withTipAmount = response.body.amount_with_tip
        })
      }
    },
    computed: {
      params() {
        return {
          amount: this.amount,
          phone: this.phone,
          token: this.token,
          tip_percent: this.selectedTip,
          tip_amount: this.tipCustomAmount,
          note: this.note,
          invoice: this.invoice
        }
      },
      paramsGrossCalc() {
        return {
          amount: this.amount,
          tip_percent: this.selectedTip,
          tip_custom_amount: this.tipCustomAmount,
          qid: this.qid
        }
      },
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      }
    }
  }
</script>
<style scoped>
  .customPhoneInput {
    border: none !important;
  }
  .activeTip {
    background-color: #fff;
    border: 2px solid  #00ab8e;
    color: #00ab8e;
  }
</style>
