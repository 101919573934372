<template>
  <span>
    <span v-if='value != null'>
      <span class='bold-text show-balance flow-text'>{{value | currency}}</span><br>
      <span v-show='pendingAmount > 0'>
        <i class="far fa-clock"></i> Pending: 
        <span class='red-text'>{{pendingAmount | currency}}</span>
      </span>
      <a v-show='!withdrawing && value > 0 && false' href @click.prevent='withdrawBalance'>Collect your money</a>
      <span v-show='withdrawing' class='grey-text'>
        <i class="fas fa-spinner fa-spin"></i> Withdrawing ...
      </span>
    </span>
    <span v-else><i class="fas fa-spinner fa-spin grey-text show-balance"></i></span>
  </span>
</template>
<script>
  export default {
    created() {
      this.getBalance();
    },
    props: ['userId'],
    data() {
      return {
        value: null,
        pendingAmount: null,
        withdrawing: false
      }
    },
    methods: {
      getBalance() {
        this.$http.get('/api/v1/app/balance', { params: { user_id: this.userId } }).then( response => {
          this.value = response.body.balance
          this.pendingAmount = response.body.pending_amount
        })
      },
      withdrawBalance() {
        this.withdrawing = true
        this.$http.post('/api/v1/vue/withdraw_balances').then(response => {
          this.withdrawing = false
          this.notifySuccess(response.body.message)
          this.value = 0
        }, response => {
          this.withdrawing = false
          this.notifyError(response.body.message)
        })
      }
    }
  }
</script>
<style>
  .show-balance {
    font-size: 2em;
  }
</style>
