<script>
  export default {
    props: ['userId', 'currentState'],
    data() {
      return {
        state: this.currentState
      }
    },
    methods: {
      switched() {
        this.$http.patch('/api/v1/app/merchant/settings/allow_search_all_transactions/' + this.userId, { state: this.state }).then(response => {
          this.notifySuccess('Setting successfully updated')
        }, response => {
          this.notifyError('Something went wrong. Setting not updated')
          this.state = !this.state
        })
      }
    }
  }
</script>

<template>
  <div>
    <div>
      No day limit search

      <div class="mt-5">
        <small class="grey-text">Allow sub-account to search its own transactions without day limit</small>
      </div>
    </div>
    <!-- Switch -->
    <div class="switch">
      <label>
        Deny
        <input type="checkbox" @change='switched' v-model='state'>
        <span class="lever"></span>
        Allow
      </label>
    </div>
  </div>
</template>
