<template>
  <div>
  </div>
</template>
<script>
  export default {
    props: ['type'],
    created() {
      if (this.$webkit) {
        this.getRefreshToken()
      }
    },
    data() {
      return {
        tokensPayload: null
      }
    },
    methods: {
      getRefreshToken() {
        this.$http.get('/api/v1/vue/refresh_token').then(response => {
          this.tokensPayload = response.body
          this.tokensPayload.type = this.type
          this.$webkit.messageHandlers.finishClientSignup.postMessage(this.tokensPayload)
        }, error => {
          Rollbar.error(error.body)
        })
      }
    }
  }
</script>
