<template>
  <div>
    <div class= "grey-text">
      Tip values:
      <span class="bold-font">
        <span v-if="settings.tipsByAmount">
          {{settings.tip1 | currency}}
          {{settings.tip2 | currency}}
          {{settings.tip3 | currency}}
        </span>
        <span v-else>
          {{settings.tip1}}%
          {{settings.tip2}}%
          {{settings.tip3}}%
        </span>
      </span> 
      | <a class="pointer" @click="editMode = !editMode"><i class="far fa-edit"></i> Edit</a>
    </div>
    <div class="rows" v-show="editMode">

      <div class="col s12 mt-10">
        <!-- Switch -->
        <div class="switch">
          <label>
            Percentage
            <input type="checkbox" v-model="settings.tipsByAmount">
            <span class="lever"></span>
            Fixed amount
          </label>
        </div>
      </div>
      <br>
      <div class="mt-25">
        <div class="col s4">
          <label>Tip1</label>
          <input name="tip1" type="number" v-model="settings.tip1" :disabled="!editMode">
        </div>

        <div class="col s4">
          <label>Tip2</label>
          <input name="tip2" type="number" v-model="settings.tip2" :disabled="!editMode">
        </div>

        <div class="col s4">
          <label>Tip3</label>
          <input name="tip3" type="number" v-model="settings.tip3" :disabled="!editMode">
        </div>
      </div>
      <div class="mt-10">
        <button class="btn" @click="save" :disabled="disabled">Save</button>
      </div>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {pay {tip1 tip2 tip3 tipsByAmount}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.pay
      }
    },
    data() {
      return {
        settings: {},
        editMode: false,
        disabled: false
      }
    },
    methods: {
      save() {
        this.disabled = true
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($tip1: Int!, $tip2: Int!, $tip3: Int!, $gid: ID, $tipsByAmount: Boolean!) {
            updateTipValues(input: {tip1: $tip1, tip2: $tip2, tip3: $tip3, userId: $gid, tipsByAmount: $tipsByAmount}) {
              paySettings { tip1 tip2 tip3 }
            }
          }`,
          // Parameters
          variables: {
            tip1: parseInt(this.settings.tip1),
            tip2: parseInt(this.settings.tip2),
            tip3: parseInt(this.settings.tip3),
            tipsByAmount: this.settings.tipsByAmount,
            gid: this.gid
          },
        }).then(result => {
          this.settings.tip1 = result.data.updateTipValues.paySettings.tip1
          this.settings.tip2 = result.data.updateTipValues.paySettings.tip2
          this.settings.tip3 = result.data.updateTipValues.paySettings.tip3
          this.editMode = false
          this.disabled = false
          this.notifySuccess("Tip values successfully updated")
        }, error => {
          this.disabled = false
          this.gqlErrorHandler(error)
        })
      }
    }
  }
</script>
<style scoped>
  input {
    margin-bottom: 25px !important;
  }
</style>
