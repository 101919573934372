<script>
  export default {
    props: ['userId', 'active', 'nfcActive'],
    created() {
      this.state = this.active

    },
    data() {
      return {
        state: null
      }
    },
    methods: {
      switched() {
        this.$http.post('/api/v1/app/admin/settings/pays', { id: this.userId, pay: {allow_terminal_transactions: this.state}}).then(response => {
          this.notifySuccess('Setting successfully updated')
        }, response => {
          this.notifyError('Something went wrong. Settins not updated')
          this.state = !this.state
        })
      }
    }
  }
</script>

<template>
  <div>
    <!-- Switch -->
    <div class="switch">
      <label>
        Disabled
        <input type="checkbox" @change='switched' v-model='state'>
        <span class="lever"></span>
        Enabled
      </label>
    </div>

    <div v-show='state'>
      <div class= "very-close-lines mt-25">
        NFC Terminal: <br>
        <small class="grey-text">(allow_create_reusable_card_by_terminal)</small>
      </div>
      <terminal-settings :user-id='userId' :active='nfcActive'></terminal-settings>
    </div>
  </div>
</template>


