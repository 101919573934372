<template>
  <span>
    <span v-if='value != null'>
      <span class='bold-text show-balance flow-text'>{{value | currency}}</span>
      <span v-show='pendingAmount > 0'>
        <br>
        <i class="far fa-clock"></i> 
        <span class='red-text'>{{pendingAmount | currency}}</span>
      </span>
      <span v-show='value > 0'>
        <a href='/balance_reward_withdraw' class='waves-effect waves-light btn-small'>
          Redeem
        </a>
        <br>
      </span>
      <br>
    </span>
    <span v-else><i class="fas fa-spinner fa-spin grey-text show-balance"></i></span>
  </span>
</template>
<script>
  export default {
    created() {
      this.getBalance();
    },
    data() {
      return {
        value: 0,
        pendingAmount: 0
      }
    },
    methods: {
      getBalance() {
        this.$http.get('/api/v1/app/balance_reward').then( response => {
          this.value = response.body.balance
          this.pendingAmount = response.body.pending_amount
        })
      }
    }
  }
</script>

