<template>
  <div>
    <i class="fas fa-check-circle fa-2x emerald"></i>
    <div class='large-font'><b>Payment Successful</b></div>
    <div class='card-panel container'>
      <h3 v-if='type'>{{ type.replace(/[0-9]/g,'') }} ${{ cardAmount }} Gift Card</h3>
      <div class='large-font'><b>{{cardNumber}}</b></div>
      <button 
        class='btn'
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        v-clipboard:copy="cardNumber">

        <i class="far fa-copy"></i> Copy Gift Card
      </button>
    </div>
    <br><br>
    <b>
      Congrats! <emojify text='🎊'></emojify>
      you got {{percentDiscount}}% off <emojify text='😎'></emojify>
    </b>
    Amount paid: ${{ amount }}
    <div class='container grey-text'>
      <div v-html='usageNotice'></div>
    </div>
    <br><br>

    <button class='btn-large' @click='goHome'>ok</button>

  </div>
</template>
<script>
  export default {
    props: ['cardAmount', 'amount', 'type', 'cardNumber', 'usageNotice', 'percentDiscount'],

    methods: {
      onCopy() {
        this.notifySuccess('Copied to clipboard!')
      },
      onError() {
        this.notifyError('Unable to copy')
      },
      goHome() {
        Turbolinks.visit('/')
      }
    }
  }
</script>
<style>
</style>
