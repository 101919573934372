<template>
  <div>
    <a class="waves-effect waves-light btn modal-trigger" @click="loadImages" :href="'#' + modalId">Show image</a>
    <!-- Modal Structure -->
    <div :id="modalId" class="modal">
      <div class="modal-content">
        <img v-if="showImages" :src="'data:image/jpeg;base64,' + image_front" width="100%">
        <img v-if="showImages && image_back" :src="'data:image/jpeg;base64,' + image_back" width="100%">
      </div>
      <div class="modal-footer">
        <a href class="modal-close waves-effect waves-green btn-flat">Close</a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["id"],
    data() {
      return {
        showImages: false,
        image_front: null,
        image_back: null
      }
    },
    methods: {
      loadImages() {
        this.$http.get("/api/v1/app/merchant/paya/check21_transactions/" + this.id).then(response => {
          this.image_front = response.body.front
          this.image_back = response.body.back
          this.showImages = true
        })
      }
    },
    computed: {
      modalId() {
        return "modal_" + this.id
      }
    }
  }
</script>
<style>
</style>
