<!-- Charge card on file AKA Repeat Sale. For Card on file use another component-->
<template>
  <div>
    <section class="mt-25" v-show="!showPaymentDetails">
      <h3 class="bold-font">Repeat sale</h3>
      Payment Source: <span class="bold-font">{{card}}</span><br>
      <div class="row mt-25">
        <div class="col s6">
          <label>Amount</label>
          <input v-model="amount"
            @input="updateAmounts"
            name="amount"
            class="inputText"
            type="number"
            pattern="\d*"/>
          <input v-model="inputPhone" name="phone" v-if="smsFlow" placeholder="Phone (required)"/>
          <input v-model="note" name="note" placeholder="Note (optional)"/>
          <input v-model="invoice" name="invoice" :placeholder="invoiceLabel"/>
        </div>
      </div>

      <div class="row" v-if="smsFlow">
        <div v-show="showTips">
          <div class="col s12 m6">
            <span class="grey-text">Allow tipping for this transaction</span>
            <div class="switch">
              <label>
                Off
                <input type="checkbox" v-model="perTransactionTips">
                <span class="lever"></span>
                On
              </label>
            </div>
          </div>
        </div>
        <div v-show="showFeedbackHub">
          <div class="col s12 m6">
            <span class="grey-text">Allow Votes/Feedback</span>
            <div class="switch">
              <label>
                Off
                <input type="checkbox" v-model="perTransactionVotes">
                <span class="lever"></span>
                On
              </label>
            </div>
          </div>
        </div>
      </div>

      <button :disabled="!amount || processing" name="repeatSale" class="mt-25 waves-effect waves-green btn-large wide-btn" @click="submitHandler">
        <span v-if="processing">
          <i class="fas fa-spinner fa-spin grey-text"></i> 
          Processing...
        </span>
        <span v-else>
          {{submitLabel}}<span v-show='amount'> for {{grossAmount | currency}}</span>
        </span>
      </button>
      <div v-show="feeAmount > 0" class="grey-text mt-10">
        <center>Non cash adjustment: {{feeAmount | currency}}</center>
      </div>
    </section>

    <payment-details v-show="showPaymentDetails" :amount="transactionAmount" :id="transactionId"></payment-details>
  </div>
</template>
<script>
  import gql from 'graphql-tag'
  import paymentDetails from './manual_charge/payment_details'

  export default {
    props: {qid: String, gid: String, paymentFlowType: String, card: String, phone: String, requireInvoiceField: Boolean, showFeedbackHub: Boolean, showTips: Boolean},
    components: {paymentDetails},
    data() {
      return {
        grossAmount: 0,
        feeAmount: 0,
        withTipAmount: 0,
        amount: null,
        note: null,
        invoice: null,
        processing: false,
        showPaymentDetails: false,
        transactionId: null,
        transactionAmount: null,
        inputPhone: this.phone,
        lastGrossCalcRequestId: 0,
        perTransactionTips: this.showTips,
        perTransactionVotes: this.showFeedbackHub
      }
    },
    methods: {
      submit() {
        this.processing = true
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($id: ID!, $amount: Float!, $tipAmount: Float, $tipPercent: Int, $note: String, $invoice: String) {
            chargeCardOnFile(input: {amount: $amount, sourceTransactionId: $id, tipPercent: $tipPercent, tipAmount: $tipAmount, note: $note, invoice: $invoice}) {
              transaction { deprecatedId }
              errors
            }
          }`,
          // Parameters
          variables: {
            amount: Number(this.amount),
            id: this.gid,
            note: this.note,
            invoice: this.invoice,
            tipAmount: this.tipAmount,
            tipPercent: this.selectedTip
          }
        }).then((response) => {
          if(response.data.chargeCardOnFile.errors.length > 0) {
            this.processing = false
            this.notifyError(response.data.chargeCardOnFile.errors[0])
          } else {
            Turbolinks.visit("/client/ach_transactions/" + response.data.chargeCardOnFile.transaction.deprecatedId)
          }
        }).catch((error) => {
          this.processing = false
          this.notifyError(error)
        })
      },
      submitSms() {
        this.processing = true
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($id: ID!, $amount: Float!, $note: String, $invoice: String, $phone: String!, $perTransactionTips: Boolean, $perTransactionVotes: Boolean) {
            chargeCardOnFileWithApprove(input: {amount: $amount, sourceTransactionId: $id, note: $note, phone: $phone, invoice: $invoice, perTransactionTips: $perTransactionTips, showFeedbackHub: $perTransactionVotes}) {
              transaction { deprecatedId amount}
              errors
            }
          }`,
          // Parameters
          variables: {
            amount: Number(this.amount),
            id: this.gid,
            note: this.note,
            phone: this.inputPhone,
            invoice: this.invoice,
            perTransactionTips: this.perTransactionTips,
            perTransactionVotes: this.perTransactionVotes
          }
        }).then((response) => {
          if(response.data.chargeCardOnFileWithApprove.errors.length > 0) {
            this.processing = false
            this.notifyError(response.data.chargeCardOnFileWithApprove.errors[0])
          } else {
            this.showPaymentDetails = true
            this.transactionId = response.data.chargeCardOnFileWithApprove.transaction.deprecatedId
            this.transactionAmount = response.data.chargeCardOnFileWithApprove.transaction.amount
          }
        }).catch((error) => {
          this.processing = false
          this.notifyError(error)
        })
      },
      updateAmounts() {
        // For the frequent updates, we want to make sure we only use the latest request
        // because the requests are async and can come back in any order
        this.lastGrossCalcRequestId++;
        const currentRequestId = this.lastGrossCalcRequestId;

        this.$http.get("/api/v1/vue/gross_calculations", {params: this.paramsGrossCalc}).then(response => {
          if (currentRequestId === this.lastGrossCalcRequestId) {
            this.grossAmount = response.body.gross_amount
            this.feeAmount = response.body.fee
            this.withTipAmount = response.body.amount_with_tip
          }
        })
      }
    },
    computed: {
      smsFlow() {
        return this.paymentFlowType == "manual_sms"
      },
      paramsGrossCalc() {
        return {
          amount: this.amount,
          qid: this.qid
        }
      },
      submitHandler() {
        if(this.smsFlow) {
          return this.submitSms
        } else {
          return this.submit
        }
      },
      submitLabel() {
        if(this.smsFlow) {
          return "Request e-sign"
        } else {
          return "Charge"
        }

      },
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      }
    }
  }
</script>
<style scoped>
  .activeTip {
    background-color: #fff;
    border: 2px solid  #00ab8e;
    color: #00ab8e;
  }
</style>
