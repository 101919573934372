import {defineStore} from 'pinia'

export const useCardsBillStore = defineStore('cardsBill', {
  state: () => (
    { 
        showCCBtn: true,
        grossAmount: 0,
        amountNumber: 0,
        feeAmount: 0,
        tipAmount: 0,
        tipAmountFormatted: null,
        withTipAmount: null,
        selectedTip: 0,
        tipCustomAmount: 0,
        cardToken: {},
        errorMessage: null,
        showPending: false,
        allowPayByCardInput: true,
        disableSubmit: true,
        loadingStripeJS: false,
        resubmitFlow: false,
        allowSignAutosubmit: false,
        signatureImage: null,
        merchantQid: null,
        httpService: null
    }),
  actions: {
    async updateAmounts() {
      const response = await this.httpService.get("/api/v1/vue/gross_calculations", {
        params: {
          amount: this.amountNumber,
          tip_percent: this.selectedTip,
          tip_custom_amount: this.tipCustomAmount,
          qid: this.merchantQid,
          funding: this.cardToken.funding
        }
      });

      this.grossAmount = response.body.gross_amount;
      this.feeAmount = response.body.fee;
      this.withTipAmount = response.body.amount_with_tip;
      this.tipAmount = response.body.tip_amount;
      this.tipAmountFormatted = response.body.tip_amount_formatted;
    }
  }
})
