<template>
  <div>
    <center v-show='loading' class='grey-text'>
      <i class="fas fa-spinner fa-spin grey-text"></i> 
      Loading ...
    </center>
    <div v-show='!loading'>
      Total balance: ${{totalBalance}}, Available for withdraw: ${{availableBalance}}<br>
      <div class=red-text>{{failureMessage}}</div>
      <div class=red-text>{{errorMessage}}</div>

      <div v-show='availableBalance > 0' class='mt-25'>
        <div class='row'>
          <div class='col s6'>
            <label>Withdraw amound, USD (Max ${{availableBalance}})</label>
            <input
              v-model='amount'
              v-validate="`min_value:0.01|max_value:${availableBalance}|required`"
              type='number'
              name='amountName' class='inputText'>

          </div>
          <div class='col s6'>
            <span @click='withdraw' :disabled='submitDisabled' class='btn-large pointer'>Withdraw</span>
          </div>
        </div>
        <span class='red-text small-font' v-show='errors.first("amountName")'>
          The Amount is required field with value in range 0.01 - {{availableBalance}}
        </span>

        <div v-show='availableBalance != totalBalance' class='grey-text mt-50 small-font'>
          * NOTE: Available for withdraw balance may be less then total Balance in following cases:<br>
          <ul>
            <li>You didn't make any payments to merchants - by our terms of service rewards non refundable until at least one payment to merchant will be processes</li>
            <li>Your load balance transaction had been made less then 72 hours ago. Please wait this time.</li>
            <li>Check our Terms of Service for more info <a href='https://iwallet.com/terms_of_service.html'>here</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    created() {
      this.getDefaults()
    },
    data() {
      return {
        availableBalance: null,
        totalBalance: null,
        failureMessage: null,
        amount: null,
        loading: true,
        submitDisabled: false,
        errorMessage: null
      }
    },
    methods: {
      getDefaults() {
        this.$http.get('/api/v1/vue/withdraw_balances').then(response => {
          this.loading = false
          this.availableBalance = response.body.availableBalance
          this.totalBalance = response.body.totalBalance
          this.failureMessage = response.body.failureMessage
        })
      },
      withdraw() {
        this.submitDisabled = true
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$http.post('/api/v1/vue/withdraw_balances', { amount: this.amount }).then(response => {
              Turbolinks.visit('/dashboard')
            }, error => {
              this.submitDisabled = false
              this.errorMessage = error.body.message
              console.log(error)
            })
          } else {
            this.submitDisabled = false
          }
        })
      }
    }
  }
</script>
