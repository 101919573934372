<template>
  <div>
    <section v-show='!showSuccess && !paid'>
      <select class="browser-default" v-model='selectedCard' v-show='!processing'>
        <option v-for='card in cards.data' :value='card.id'>
          {{card.attributes.brand}} - {{card.attributes.last4}}
        </option>
      </select>

      <div v-show='processing' class='grey-text'>
        <i class="fas fa-spinner fa-spin grey-text"></i> 
        Processing ...
      </div>
      <button class='btn-large mt-25' @click='pay' :disabled='processing'>Pay</button><br>
    </section>

    <section v-show='showSuccess || paid' class='emerald'>
      <h4 class='emerald'>
        <i class="far fa-check-circle"></i>
        Paid
      </h4>
    </section>
  </div>
</template>
<script>
  export default {
    created() {
    },
    props: ['amount', 'note', 'qid', 'cards', 'local_order_id', 'card_flow_status'],
    data() {
      return {
        selectedCard: this.cards.data[0].id,
        processing: false,
        showSuccess: false,
        transaction: null
      }
    },
    methods: {
      pay() {
        this.processing = true
        this.$http.post('/api/v1/app/payment_by_cards', this.params).then(response => {
          this.notifySuccess('Payment successfully processed!')
          this.transaction = response.body
          this.$http.put('/api/v1/vue/web_orders/' + this.local_order_id, {transaction_id: response.body.payment.data.id, type: "card"}).then(response => {
            this.showSuccess = true
            this.$emit('paid', this.transaction)
          })
        }, error => {
          this.processing = false
          this.notifyError('Something went wrong!')
        })
      }
    },
    computed: {
      params() {
        return {
          qid: this.qid,
          amount: this.amount,
          note: this.note,
          card_id: this.selectedCard
        }
      },
      paid() {
        if(this.card_flow_status == "paid") { return true }

        return false
      }
    }
  }
</script>
