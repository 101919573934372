<template>
  <div class="input-field">
    <label>Filter by the sub account</label>
    <input type="text" name="user_name" ref="autocomplete" placeholder="name...">
    <input type="hidden" name="user_id" ref="userId">
  </div>
</template>
<script>
  export default {
    mounted() {
      var instances = M.Autocomplete.init(this.$refs.autocomplete, {
        data: this.sub_accounts, onAutocomplete: this.completed
      });

      this.$refs.userId.value = this.userId;
      this.$refs.autocomplete.value = this.userName;
    },
    // subAccounts are in format ["name", "id"]
    // add props to the component with types for subAccounts, userId, user_name
    props: {
      subAccounts: {
        type: Array,
        required: true
      },
      userId: {
        type: String,
        required: true
      },
      userName: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        subAccount: this.userName,
        id: this.userId
      }
    },
    methods: {
      completed(name) {
        this.subAccounts.forEach(function(sub_account) {
          if (sub_account[0] == name) {
            this.$refs.userId.value = sub_account[1];
          }
        }.bind(this));
        this.$el.closest('form').submit();
      }
    },
    computed: {
      sub_accounts() {
        var sub_accounts = {};
        this.subAccounts.forEach(function(sub_account) {
          sub_accounts[sub_account[0]] = null;
        });
        return sub_accounts;
      }
    }
  }
</script>
<style>
</style>
