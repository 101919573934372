<template>
  <div>
    <div class="row">
      <div class="red-text text-darken-3">{{errorMessage}}</div>
      <div>
        <card-input :submit="submit" :reset-token="resetToken"></card-input>
      </div>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'
  import Base64 from 'crypto-js/enc-base64';
  import WordArray from 'crypto-js/lib-typedarrays';
  import AES from 'crypto-js/aes';
  import { publicEncrypt } from 'crypto'
  import cardInput from './card_input'

  export default {
    components: {cardInput},
    apollo: {
      rsa: {
        fetchPolicy: "no-cache",
        query: gql`query {
          merchant { rsaPublicKey { key keyId } }
        }`,
        update: data => data.merchant.rsaPublicKey
      }
    },
    data() {
      return {
        rsa: {},
        errorMessage: null
      }
    },
    methods: {
      submit(cardJSON) {
        let key = WordArray.random(16);
        let iv = WordArray.random(16);
        let encryptedCardPayload = AES.encrypt(cardJSON, key, { iv: iv })

        let aesKeyBase64 = Base64.stringify(encryptedCardPayload.key)
        let ivBase64 = Base64.stringify(encryptedCardPayload.iv)

        let buffer = new Buffer(aesKeyBase64)
        let encryptedAesKey = publicEncrypt(this.rsa.key, buffer).toString('base64')
        this.createToken(ivBase64, encryptedAesKey, encryptedCardPayload.toString())
      },
      createToken(iv, key, payload) {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($encryptedAesKey: String!, $iv: String!, $encryptedCardData: String!, $publicKeyId: String!) {
            createCardToken(input: {encryptedAesKey: $encryptedAesKey, iv: $iv, encryptedCardData: $encryptedCardData, publicKeyId: $publicKeyId}) {
              token
              errors
            }
          }`,
          // Parameters
          variables: {
            encryptedCardData: payload,
            encryptedAesKey: key,
            iv: iv,
            publicKeyId: this.rsa.keyId
          },
        }).then(result => {
          if(result.data.createCardToken.errors.length > 0) {
            this.errorMessage = result.data.createCardToken.errors[0]
            this.$emit("input", null)
          } else {
            this.errorMessage = null
            this.$emit("input", result.data.createCardToken.token)
          }
        }, error => {
          this.gqlErrorHandler(error)
        })
      },
      resetToken() {
        this.$emit("input", null)
      },
    }
  }
</script>
