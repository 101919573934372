<template>
  <div>
    <section class="main-form" >
      <div class="row" @click="openKeyboard">
        <div class="input-field col s12 m6">
          <i class="fas fa-dollar-sign prefix grey-text"></i>
          <input 
            ref="inputAmount"
            name="amount"
            v-model='amount'
            step="0.01"
            type="text" 
            inputmode="decimal"
            v-validate="'min_value:0.01|required'"
            class='inputText'
            @input='updateAmounts'
            lang="en-001">
            <span class='red-text small-font' v-show='errors.first("amount")'>{{errors.first("amount")}}</span>
            <div class='grey-text mt-t center' v-show='!showTapHere'>
              Gross amount: <b>{{grossAmount}}</b> |  
              <i class="far fa-credit-card"></i> Non-cash adj: <b>{{feeAmount}}</b>
            </div>

          <label for="amount">Enter amount here</label>
        </div>


        <section class='grey-text center mt-10 tips-row' v-if='showTips && !tipsLoading && !showTapHere'>
          <span class='tips-btn tips-col' :class="{'actives': selectedTip == 0}" @click='setTip(0)'>No tip</span>
          <span class='tips-btn tips-col' :class="{'actives': selectedTip == tip1}" @click='setTip(tip1)'>{{tip1}}%</span>
          <span class='tips-btn tips-col' :class="{'actives': selectedTip == tip2}" @click='setTip(tip2)'>{{tip2}}%</span>
          <span class='tips-btn tips-col' :class="{'actives': selectedTip == tip3}" @click='setTip(tip3)'>{{tip3}}%</span>
        </section>
        <div class="input-field col s12 mt-50" v-show="showManualForm">
          <div ref="card" v-show='amount'></div>
          <span class='red-text'>{{errorMessage}}</span>
        </div>
      </div>

      <center>
        <div v-show='!amount && showTapHere' class='btn-large mt-50' @click="openKeyboard">Tap here to enter amount</div>
      </center>


      <section v-show='amount'>
        <button @click="charge(resultHandler)" v-show="!showPending && showManualForm" class="btn-large wide-btn mt-25" :disabled="disableSignupBtn">
          Pay<span v-show='amount'> {{grossAmount | currency}}</span>
        </button>

        <div id="payment-request-button" class='mt-10'>
          <!-- A Stripe Element will be inserted here. -->
        </div>
      </section>

      <center v-if='showPending' class='grey-text mt-25'>
        <i class="fas fa-spinner fa-spin"></i> Processing...
      </center>
    </section>
  </div>
</template>
<script>
  export default {
    props: ['stripePublishableKey', 'qid', 'merchantName', 'merchantStripeId', 'showTips', 'googleId', 'cardBrandsAcceptance'],
    mounted() {
      this.loadStripeJs()
      document.addEventListener('click', this.openKeyboard);
    },
    data() {
      return {
        amount: null,
        grossAmount: null,
        feeAmount: null,
        withTipAmount: null,
        token: null,
        elements: null,
        card: null,
        errorMessage: null,
        disableSignupBtn: true,
        showPending: false,
        selectedTip: 0,
        stripeForConnected: null,
        showManualForm: true,
        tipsLoading: true,
        tip1: 0,
        tip2: 0,
        tip3: 0,
        showTapHere: true
      }
    },
    methods: {
      openKeyboard() {
        this.showTapHere = false
        this.$refs.inputAmount.focus()
        document.removeEventListener('click', this.openKeyboard);
      },
      charge(resultHandler) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.disableSignupBtn = true
            this.showPending = true
            this.stripe.createToken(this.card).then(resultHandler)
          } else {
            this.notifyError("Please complete all required fields!")
          }
        })
      },
      resultHandler(result) {
        if (result.error) {
          this.notifyError(result.error.message)
          this.showPending = false
          this.disableSignupBtn = false
        } else {
          this.token = result.token.id
          if(this.cardBrandsAcceptable(result.token.card.brand)) {
            this.$http.post("/api/v1/app/client/manual_payments", this.params).then(response => {
              Turbolinks.visit('/client/ach_transactions/' + response.body.payment.data.id)
            }, error => {
              this.errorHandler(error)
              this.showPending = false
              this.disableSignupBtn = false
            })
          } else {
            this.notifyError(result.token.card.brand + " card not acceptable for processing at " + this.merchantName)
            this.showPending = false
            this.disableSignupBtn = false
          }
        }
      },
      loadStripeJs() {
        this.script= document.createElement('script')
        this.script.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(this.script)
        this.script.onload = this.setupStripe
      },
      setupStripe() {
        this.stripe = Stripe(this.stripePublishableKey)
        this.elements = this.stripe.elements({locale: 'en'})
        this.card = this.elements.create('card', {hidePostalCode: true, iconStyle: 'solid'})
        this.card.mount(this.$refs.card)

        this.card.on('change', this.onChange);

        this.stripeForConnected = Stripe(this.stripePublishableKey, {
          stripeAccount: this.merchantStripeId,
          apiVersion: "2020-08-27"
        })
        this.loadTips()
        // this.stripeForConnected.applePay.stripeAccount = this.merchantStripeId
      },
      onChange({error, complete}) {
        if (error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = '';
        }
        if (complete) {
          this.disableSignupBtn = false
        }
      },
      setTip(tip) {
        this.selectedTip = tip
        this.updateAmounts()
      },
      updateAmounts() {
        this.$http.get("/api/v1/vue/gross_calculations", {params: this.params}).then(response => {
          this.grossAmount = response.body.gross_amount
          this.feeAmount = response.body.fee
          this.withTipAmount = response.body.amount_with_tip
          this.mountPaymentRequestButton()
        })
      },
      // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-create-payment-request-instance
      mountPaymentRequestButton() {
        // Create an instance of stripe.paymentRequest
        // https://stripe.com/docs/js/payment_request/create
        var paymentRequest = this.stripeForConnected.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: this.merchantName,
            amount: this.amountCents,
          },
          requestPayerName: false,
          requestPayerEmail: false
        });

        // Create the paymentRequestButton Element
        var elements = this.stripeForConnected.elements();
        var prButton = elements.create('paymentRequestButton', {
          paymentRequest: paymentRequest,
          style: {
            paymentRequestButton: {
              theme: 'light-outline',
              height: '60px'
            }
          }
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then((result)=> {
          if (result) {
            this.showManualForm = false
            prButton.mount('#payment-request-button');
          } else {
            this.showManualForm = true
            document.getElementById('payment-request-button').style.display = 'none';
            console.log("Pay API not Available")
          }
        });

        paymentRequest.on('paymentmethod', this.completePaymentByButton)
      },
      completePaymentByButton(ev) {
        // For now we allow all brands for A/G pay but for enable - just replace
        // the condition checker
        // if(this.cardBrandsAcceptable(ev.paymentmethod.card.brand)) {
        if(true) {
          // Payment Method object - https://stripe.com/docs/api/payment_methods/object
          var params = {
            qid: this.qid,
            user_email: ev.payerEmail,
            user_name: ev.payerName,
            method_name: ev.methodName,
            funding: ev.paymentMethod.card.funding,
            brand: ev.paymentMethod.card.brand,
            amount: this.amount,
            tip_percent: this.selectedTip
          }
          this.$http.post('/api/v1/vue/stripe/payment_intents', params).then(response => {
            var clientSecret = response.body.client_secret

            this.stripeForConnected.confirmCardPayment(
              clientSecret,
              {payment_method: ev.paymentMethod.id},
              {handleActions: false}
            ).then((confirmResult) => {

              if (confirmResult.error) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                this.notifyError("Payment Failed!")
                ev.complete('fail');
              } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11" instead
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (confirmResult.paymentIntent.status === "requires_action") {
                  // Let Stripe.js handle the rest of the payment flow.
                  this.stripeForConnected.confirmCardPayment(clientSecret).then(function(result) {
                    if (result.error) {
                      // The payment failed -- ask your customer for a new payment method.
                      this.notifyError("Payment Failed, try another payment method")
                      this.notice.push("Payment Failed, try another payment method")
                    } else {
                      // The payment has succeeded.
                      this.notifySuccess("Payment Successful")
                      Turbolinks.visit('/a/thank_you')
                    }
                  });
                } else {
                  // The payment has succeeded.
                  this.notifySuccess("Payment Successful")
                  Turbolinks.visit('/a/thank_you?payment_intent_external_id=' + confirmResult.paymentIntent.id + '&google_id=' + this.googleId)
                }
              }
            })
          }, error => {
            this.notifyError(error.body.error_messages)
          })
        } else {
          this.notifyError(ev.paymentmethod.card.brand + " card not acceptable for processing at " + this.merchantName)
        }
      },
      loadTips() {
        this.$http.get("/api/v1/vue/tip_values", {params: {qid: this.qid}}).then(response => {
          this.tip1 = response.body.tip1
          this.tip2 = response.body.tip2
          this.tip3 = response.body.tip3
          this.selectedTip = response.body.default
          this.tipsLoading = false
          this.updateAmounts()
        }, error => {
          this.notifyError("Something went wrong")
          // Fallback to defualt tips
          this.tip1 = 15
          this.tip2 = 20
          this.tip3 = 25
          this.tipsLoading = false
          this.updateAmounts()
        })
      },
      // If brand is not defined in cardBrandsAcceptable hash
      cardBrandsAcceptable(brand) {
        return this.cardBrandsAcceptance[brand] ?? true
      }
    },
    computed: {
      params() {
        return {
          amount: this.amount,
          token: this.token,
          qid: this.qid,
          tip_percent: this.selectedTip
        }
      },
      amountCents(){
        return Math.round(this.grossAmount * 100)
      }
    }
  }
</script>
<style scoped>
  .actives {
    background-color: #fff;
    border: 2px solid  #00ab8e;
    color: #00ab8e;
  }
</style>
