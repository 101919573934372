<template>
  <div>
    <button v-show="!showImages" @click="showImages = !showImages" class="btn">
      <i class="far fa-eye"></i> Show signature
    </button>

    <img v-if="showImages" :src="signatureLink" width="100%">
  </div>
</template>
<script>
  export default {
    props: ["transactionId"],
    data() {
      return {
        showImages: false,
        signature: null
      }
    },
    computed: {
      signatureLink() {
        return "/merchant/signature_images/" + this.transactionId
      }
    }
  }
</script>
<style>
</style>
