<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        fetchPolicy: "no-cache",
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {notifications {logOwner logSupervisor logAccountant logMaster compactLogOwner compactLogSupervisor compactLogAccountant compactLogMaster infoOwner infoSupervisor infoAccountant infoMaster criticalOwner criticalSupervisor criticalAccountant criticalMaster}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.notifications
      }
    },
    data() {
      return {
        settings: {},
        showForm: false
      }
    },
    methods: {
      updateSettings() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($params: NotificationsAttributes!, $gid: ID) {
            updateNotifications(input: {notifications: $params, userId: $gid}) {
              notifications { logOwner logSupervisor logAccountant logMaster compactLogOwner compactLogSupervisor compactLogAccountant compactLogMaster infoOwner infoSupervisor infoAccountant infoMaster criticalOwner criticalSupervisor criticalAccountant criticalMaster }
              errors
            }
          }`,
          // Parameters
          variables: {
            params: this.params,
            gid: this.gid
          },
        }).then(result => {
          this.settings = result.data.updateNotifications.notifications
          this.notifySuccess("Notifications settings successfully updated")
        }, error => {
          this.$apollo.queries.settings.refetch()
          this.gqlErrorHandler(error)
        })
      },
    },
    computed: {
      params() {
        return {
          logOwner: this.settings.logOwner,
          logSupervisor: this.settings.logSupervisor,
          logAccountant: this.settings.logAccountant,
          logMaster: this.settings.logMaster,
          compactLogOwner: this.settings.compactLogOwner,
          compactLogSupervisor: this.settings.compactLogSupervisor,
          compactLogAccountant: this.settings.compactLogAccountant,
          compactLogMaster: this.settings.compactLogMaster,
          infoOwner: this.settings.infoOwner,
          infoSupervisor: this.settings.infoSupervisor,
          infoAccountant: this.settings.infoAccountant,
          infoMaster: this.settings.infoMaster,
          criticalOwner: this.settings.criticalOwner,
          criticalSupervisor: this.settings.criticalSupervisor,
          criticalAccountant: this.settings.criticalAccountant,
          criticalMaster: this.settings.criticalMaster
        }
      }
    }
  }
</script>
<template>
  <div>
    <section class="card-panel">
      <div class="bold-font grey-text">Emails with transaction receipts, votes, feedbacks</div>
      <div class="row mt-25">
        <div class="col s6 m3 center">
          Tech
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.logOwner" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Supervisors
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.logSupervisor" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Accountants
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.logAccountant" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Owner
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.logMaster" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>
      </div>
    </section>

    <section class="card-panel">
      <div class="bold-font grey-text">Batch emails</div>
      <div class="row mt-25">
        <div class="col s6 m3 center">
          Tech
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.compactLogOwner" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Supervisors
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.compactLogSupervisor" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Accountants
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.compactLogAccountant" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Owner
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.compactLogMaster" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>
      </div>
    </section>

    <section class="card-panel">
      <div class="bold-font grey-text">Emails with recurring transactions, voids, refunds, and adjustments</div>
      <div class="row mt-25">
        <div class="col s6 m3 center">
          Tech
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.infoOwner" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Supervisors
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.infoSupervisor" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Accountants
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.infoAccountant" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center grey-text text-lighten-2">
          Owner
          <div class="switch">
            <label class="grey-text text-lighten-2">
              Off
              <input disabled type="checkbox" v-model="settings.infoMaster" @change="updateSettings">
              <span class="lever" style="background-color: lightgrey;"></span>
              On
            </label>
          </div>
        </div>
      </div>
    </section>

    <section class="card-panel">
      <div class="bold-font grey-text">Critical emails with disputes, bad checks and recurring transaction failures</div>
      <div class="row mt-25">
        <div class="col s6 m3 center">
          Tech
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.criticalOwner" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Supervisors
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.criticalSupervisor" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center">
          Accountants
          <div class="switch">
            <label>
              Off
              <input type="checkbox" v-model="settings.criticalAccountant" @change="updateSettings">
              <span class="lever"></span>
              On
            </label>
          </div>
        </div>

        <div class="col s6 m3 center grey-text text-lighten-2">
          Owner
          <div class="switch">
            <label class="grey-text text-lighten-2">
              Off
              <input disabled class="red-text" type="checkbox" v-model="settings.criticalMaster" @change="updateSettings">
              <span class="lever" style="background-color: lightgrey;"></span>
              On
            </label>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
