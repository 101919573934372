<template>
  <section>
    <div class='row'>
      <div class='col s12'>
        <b>Tax</b>

        <!-- Switch -->
        <div class="switch right">
          <label>
            Off
            <input type="checkbox" @change='switched' v-model='actualActive'>
            <span class="lever"></span>
            On
          </label>
        </div>

        <div class='grey-text mt-25' v-show='actualActive'>
          Percent: {{actualPercent}} | <a href @click.prevent='showForm = true;'>Edit</a>
        </div>

        <!-- Form -->
        <div v-show='actualActive && showForm' class='mt-25'>
          <div class='row'>
            <div class='col s12 m4'>
              <div class="input-field">
                <input 
                  type="number" 
                  id='percent-input'
                  v-model='actualPercent' 
                  v-validate="'required|numeric|min_value:1|max_value:99'" 
                  name='percent'>

                <label for='percent-input'>Percent, %</label>
              </div>
            </div>
          </div>
          <div class='red-text'>{{ errors.first('percent') }}</div>
          <div class='red-text'>{{ errors.first('min-amount') }}</div>

          <button class='btn' :disabled="errors.any()" @click='saveSettings'>Save</button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  const apiPath = '/api/v1/app/merchant/settings/taxes'

  export default {

    props: ['percent', 'active'],
    data() {
      return {
        actualPercent: this.percent,
        actualActive: this.active,
        isSaving: false,
        showForm: false,
      }
    },
    methods: {
      saveSettings() {
        this.isSaving = true
        var payload = { tax: { percent: this.actualPercent }, active: this.actualActive }
        this.$http.post(apiPath, payload).then( response => {
          this.showForm = false
          this.notifySuccess('Tax settings successfully updated')
        }, response => {
          if (response.status == 422) {
            this.notifyError(response.body.error_messages[0])
          } else {
            this.notifyError('Tax settings NOT updated. Something went wrong')
          }
        })

      },
      switched() {
        // Reset values before change active in case if it was validation error
        this.actualTotalStamps = this.total_stamps
        this.actualRewardPercent = this.reward_percent

        this.saveSettings()
      }
    }
  }
</script>

