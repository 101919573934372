<script>
  export default {
    props: ['userId', 'active'],
    created() {
      this.state = this.active

    },
    data() {
      return {
        state: null
      }
    },
    methods: {
      switched() {
        this.$http.post('/api/v1/app/merchant/settings/radars', { id: this.userId, radar: { active: this.state }}).then(response => {
          this.notifySuccess('Setting successfully updated')
        }, response => {
          this.notifyError('Something went wrong. Settins not updated')
          this.state = !this.state
        })
      }
    }
  }
</script>

<template>
  <div>
    <!-- Switch -->
    <div class="switch">
      <label>
        Off
        <input type="checkbox" @change='switched' v-model='state'>
        <span class="lever"></span>
        On
      </label>
    </div>
  </div>
</template>


