<template>
  <div>
    <label>Select Funding Source</label>
    <div class="input-field col s12" v-if="fundingSources">
      <select class='browser-default' v-model="selected" @change="changed">
        <option
          v-for="fundingSource in fundingSources"
          :value="{type: fundingSource.type, id: fundingSource.id, subtype: fundingSource.subtype}">
          {{fundingSource.name}} <span v-if="fundingSource.fee"> (Fee {{fundingSource.fee}})</span>
        </option>
      </select>
    </div>
  </div>
</template>
<script>
  export default {
    created() {
      this.getFundingSources()
    },
    props: ['merchantQid', 'clientQid', 'filter'],
    data() {
      return {
        fundingSources: null,
        selected: null
      }
    },
    methods: {
      getFundingSources() {
        this.$http.get('/api/v1/app/client/available_funding_sources', {params: this.params}).then(response => {
          this.fundingSources = response.body.funding_sources.filter( i => this.filter ? i.type == this.filter : true )
          if(this.fundingSources[0]) {
            this.selected = { type: this.fundingSources[0].type, id: this.fundingSources[0].id, subtype: this.fundingSources[0].subtype }
            this.$emit('input', this.selected)
          } else {
            this.selected = { type: "notAvailable" }
            this.$emit('input', this.selected)
          }
        })
      },
      changed() {
        this.$emit('input', this.selected)
      }
    },
    computed: {
      params() {
        return {
          client_qid: this.clientQid,
          merchant_qid: this.merchantQid
        }
      }
    }
  }
</script>
