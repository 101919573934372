<template>
  <div>
    <section class="main-form" v-show="!showNonEsignForm && !showPaymentDetails && !showLocationConfirmation && !showLocationConfirmationNonEsign">
      <div class="row">
        <div class="input-field col s12 m6">
          <i class="fas fa-dollar-sign prefix grey-text"></i>
          <input 
            name="amount"
            autofocus
            v-model='amount'
            step="0.01"
            type="text" 
            inputmode="decimal"
            v-validate="'min_value:0.01|required'"
            class='inputText'
            lang="en-001">
            <span class='red-text small-font' v-show='errors.first("amount")'>Must be $0.01 or more</span>

          <label for="amount">Amount</label>
        </div>
        <div class="input-field col s12 m6">
          <vue-tel-input v-model="phone" @validate="phoneValidate" wrapperClasses="customPhoneInput" placeholder="Cardholder phone number"></vue-tel-input>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s12 m6">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input name="invoice" id="invoice" type="text" v-model="invoice" ref="invoice">

          <label for="invoice">{{invoiceLabel}}</label>
        </div>

        <div class="input-field col s12 m6" v-if="requireInvoiceConfirmation">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input name="invoice-cfm" id="invoice-cfm" type="text" v-validate="{required: invoice.length > 0, confirmed: 'invoice'}">

          <label for="invoice-cfm">Invoice (confirmation)</label>
          <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
        </div>

        <div class="input-field col s12 m6">
          <i class="fas fa-sticky-note prefix grey-text"></i>
          <input name="note" id="note" type="text" v-model="note" ref="note">

          <label for="note">Note (Optional)</label>
        </div>
      </div>
      <div ref="card"></div><br>
      <span class='red-text'>{{errorMessage}}</span><br>

      <div v-show="showTips">
        <span class="grey-text">Allow tipping for this transaction</span>
        <div class="switch">
          <label>
            Off
            <input type="checkbox" v-model="perTransactionTips">
            <span class="lever"></span>
            On
          </label>
        </div>
      </div>

      <button @click="requestEsign" v-show="!showPending" class="btn-large wide-btn mt-10" :disabled="disableSignupBtn || !phoneIsValid">
        Request E-Sign<span v-show='amount'> for {{amount | currency}}</span>
      </button>

      <button @click="requestSkipEsign" v-show="!showPending" class="mt-10 btn wide-btn grey lighten-4 grey-text text-darken-3" :disabled="disableSignupBtn || !phoneIsValid">
        Skip e-sign
      </button>
    </section>
    <section class="nonEsign-form mt-10" v-if="showNonEsignForm && !showPending && !showPaymentDetails">
      <center>
        <h3>Skip E-signature</h3>
        <span class='red-text bold-text'><i class="fas fa-exclamation-triangle red-text"></i> WARNING!</span><br> 
        I understand the higher risk of no signature transaction
        <br>
      </center>
      <label>Cardholder First name (required)</label>
      <input 
        v-model='firstName'
        autocomplete="first_name"
        v-validate="'required'"
        name='firstName'>
        <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("firstName")}}</span>

      <label>Cardholder Last name (required)</label>
      <input 
        v-model='lastName'
        autocomplete="last_name"
        v-validate="'required'"
        name='lastName'>
        <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("lastName")}}</span>

      <label>Staff First name (required)</label>
      <input 
        v-model='staffFirstName'
        autocomplete="first_name"
        v-validate="'required'"
        name='staffFirstName'>
        <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("firstName")}}</span>

      <label>Staff Last name (required)</label>
      <input 
        v-model='staffLastName'
        autocomplete="last_name"
        v-validate="'required'"
        name='staffLastName'>
        <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("lastName")}}</span>
        <button class='btn-large wide-btn' @click='charge(resultHandlerNonEsign)'>Charge ${{amount}}</button>
        <button class='mt-10 btn wide-btn grey lighten-4 grey-text text-darken-3' @click='showNonEsignForm = false'>Back</button>
    </section>
    <center v-show='showPending && !showPaymentDetails' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>

    <!-- Show location confirmation screen E-Sign Flow-->
    <section v-show="showLocationConfirmation && !showPending">
      <h3 class="emerald">
        Are you sure that this transaction is for {{groupLocation}}?
      </h3>

      <div class="mt-25">
        <button class="btn btn-large outlined-btn" @click="showLocationConfirmation=false">No</button>
        <button class="btn btn-large" style="margin-left: 20px;" @click="charge(resultHandler)">Yes</button>
      </div>
    </section>

    <!-- Show location confirmation screen skip E-Sign Flow-->
    <section v-show="showLocationConfirmationNonEsign && !showPending">
      <h3 class="emerald">
        Are you sure that this transaction is for {{groupLocation}}?
      </h3>

      <div class="mt-25">
        <button class="btn btn-large outlined-btn" @click="showLocationConfirmationNonEsign=false">No</button>
        <button class="btn btn-large" style="margin-left: 20px;" @click="showNonEsignForm = true; showLocationConfirmationNonEsign=false">Yes</button>
      </div>
    </section>
    
    <payment-details v-show="showPaymentDetails" :amount="transactionAmount" :id="transactionId"></payment-details>
  </div>
</template>
<script>
  import paymentDetails from './payment_details'
  export default {
    props: ['stripePublishableKey', 'requireInvoiceField', 'requireInvoiceConfirmation', 'setAmount', 'setPhone', 'setNote', 'setInvoice', 'groupsEnabled', 'groupLocation', 'showTips'],
    components: {paymentDetails},
    mounted() {
      this.loadStripeJs()
    },
    data() {
      return {
        firstName: null,
        lastName: null,
        staffFirstName: null,
        staffLastName: null,
        amount: this.setAmount,
        phone: this.setPhone,
        token: null,
        elements: null,
        card: null,
        errorMessage: null,
        disableSignupBtn: true,
        showPending: false,
        showNonEsignForm: false,
        phoneIsValid: false,
        showPaymentDetails: false,
        transactionAmount: null,
        transactionId: null,
        note: this.setNote,
        invoice: this.setInvoice,
        hideZip: false,
        showLocationConfirmation: false,
        showLocationConfirmationNonEsign: false,
        perTransactionTips: this.showTips
      }
    },
    methods: {
      requestEsign() {
        // If conjunction groups enabled we will show extra confirmation window
        // to makes sure we're submitting to the correct location
        if(this.groupsEnabled) {
          this.showLocationConfirmation = true
        } else {
          this.charge(this.resultHandler)
        }
      },
      requestSkipEsign() {
        // If conjunction groups enabled we will show extra confirmation window
        // to makes sure we're submitting to the correct location
        if(this.groupsEnabled) {
          this.showLocationConfirmationNonEsign = true
        } else {
          this.showNonEsignForm = true
        }
      },
      charge(resultHandler) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.disableSignupBtn = true
            this.showPending = true
            this.stripe.createToken(this.card).then(resultHandler)
          } else {
            this.showNonEsignForm = false
            this.showLocationConfirmation = false
            this.showLocationConfirmationNonEsign = false
            this.notifyError("Please complete all required fields!")
          }
        })
      },
      resultHandler(result) {
        if (result.error) {
          this.notifyError(result.error.message)
          this.showPending = false
          this.disableSignupBtn = false
          this.showLocationConfirmation = false
          this.showLocationConfirmationNonEsign = false
        } else {
          this.token = result.token.id
          this.$http.post("/api/v1/app/merchant/manual_charges", this.params).then(response => {
            this.transactionAmount = response.body.payment.data.attributes.amount_formatted
            this.transactionId = response.body.payment.data.id
            this.showPaymentDetails = true
          }, error => {
            // if(error.body.retry_with_zip) {
            if(false) {
              this.notifyError("Card declined! Try to resubmit with zip code")
              this.hideZip = false
              this.setupStripe()
            } else {
              this.errorHandler(error)
            }
            this.showPending = false
            this.disableSignupBtn = false
            this.showLocationConfirmation = false
            this.showLocationConfirmationNonEsign = false
          })
        }
      },
      resultHandlerNonEsign(result) {
        if (result.error) {
          this.notifyError(result.error.message)
          this.showPending = false
          this.disableSignupBtn = false
          this.showLocationConfirmation = false
          this.showLocationConfirmationNonEsign = false
          this.showNonEsignForm = false
        } else {
          this.token = result.token.id
          this.$http.post("/api/v1/app/merchant/manual_charges/merchant_approve", this.cancelParams).then(response => {
            Turbolinks.visit('/')
          }, error => {
            this.errorHandler(error)
            this.showPending = false
            this.disableSignupBtn = false
            this.showNonEsignForm = false
            this.showLocationConfirmation = false
            this.showLocationConfirmationNonEsign = false
          })
        }
      },
      loadStripeJs() {
        this.script= document.createElement('script')
        this.script.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(this.script)
        this.script.onload = this.setupStripe
      },
      setupStripe() {
        this.stripe = Stripe(this.stripePublishableKey)
        this.elements = this.stripe.elements({locale: 'en'})
        this.card = this.elements.create('card', 
          {
            hidePostalCode: this.hideZip,
            iconStyle: 'solid',
            style: {
              base: {
                iconColor: '#c4f0ff',
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '18px'
              }
            }
          })
        this.card.mount(this.$refs.card)

        this.card.on('change', this.onChange);
      },
      onChange({error, complete}) {
        if (error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = '';
        }
        if (complete) {
          this.disableSignupBtn = false
        }
      },
      phoneValidate(result) {
        this.phoneIsValid = result.isValid

        if(result.isValid) {
          this.$refs.invoice.focus()
        }
      },
    },
    computed: {
      params() {
        return {
          amount: this.amount,
          phone: this.phone,
          token: this.token,
          note: this.note,
          invoice: this.invoice,
          per_transaction_tips: this.perTransactionTips
        }
      },
      cancelParams() {
        return {
          first_name: this.firstName,
          last_name: this.lastName,
          staff_first_name: this.staffFirstName,
          staff_last_name: this.staffLastName,
          amount: this.amount,
          phone: this.phone,
          token: this.token,
          note: this.note,
          invoice: this.invoice
        }
      },
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      }
    }
  }
</script>
<style>
  .customPhoneInput {
    border: none !important;
  }
</style>
