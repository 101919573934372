<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        fetchPolicy: "no-cache",
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {feedbackHub {showVote showReview useNpsVote}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.feedbackHub
      }
    },
    data() {
      return {
        settings: {},
        processing: false
      }
    },
    methods: {
      switched() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($gid: ID, $showVote: Boolean!, $showReview: Boolean!, $useNpsVote: Boolean!) {
            updateFeedbackHub(input: {userId: $gid, showVote: $showVote, showReview: $showReview, useNpsVote: $useNpsVote}) {
              errors
            }
          }`,
          // Parameters
          variables: {
            gid: this.gid,
            showVote: this.settings.showVote,
            showReview: this.settings.showReview,
            useNpsVote: this.settings.useNpsVote
          },
        }).then(result => {
          this.notifySuccess("Feedback settings successfully updated")
        }, error => {
          this.$apollo.queries.settings.refetch()
          this.gqlErrorHandler(error)
        })
      }
    },
    computed: {
      inverseUseNpsVote: {
        // Getter returns the inverted value
        get() {
          return !this.settings.useNpsVote;
        },
        // Setter sets the inverse of the input (which is the original value)
        set(value) {
          this.settings.useNpsVote = !value;
        }
      }
    }
  }
</script>

<template>
  <div>
    <section>
      <div class= "very-close-lines mt-10">
        <span class="grey-text">Enable Thumbs/NPS Screen:</span>
        <br>
      </div>
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @change='switched' v-model='settings.showVote'>
          <span class="lever"></span>
          On
        </label>
      </div>

      <section v-show="settings.showVote">
        <div class= "very-close-lines mt-25">
          <span class="grey-text">Use NPS or Thumbs:</span>
          <br>
          <small class="grey-text">Switch between Net Promoter Score (1-10) or Thumbs Up/Down.</small>
        </div>
        <div class="switch">
          <label>
            NPS 
            <input type="checkbox" @change='switched' v-model='inverseUseNpsVote'>
            <span class="lever"></span>
            Thumbs
          </label>
        </div>
      </section>

      <div class= "very-close-lines mt-50">
        <span class="grey-text">Enable Online Reviews:</span>
        <br>
        <small class="grey-text">Allows payers to leave an online review using the URL(s) below</small>
      </div>
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @change='switched' v-model='settings.showReview'>
          <span class="lever"></span>
          On
        </label>
      </div>
    </section>
  </div>
</template>
