<template>
  <div class='container mt-50'>
    <center>

      <section v-show='!showEmail'>
        <i class="fas fa-check-circle fa-2x emerald"></i>
        <div class='large-font'><b>Payment Successful</b></div>
        <span class='big-font'>
          <b>$0.00</b>
        </span>
        <br>
        <span class='emerald'>
          Cashback 20% 
        </span>
        will be applied after signup<br> 
        <emojify text='😎' /> <br>
        for each transaction
        <div class='mt-25'>
          Order total: ${{amount}} paid by iWallet to<br>
          <b class='large-font'>
            {{merchant}}
          </b>
        </div>

        <div class='grey-text'>
          Auth code: {{authCode}} | 
          {{timeNow}}
        </div>
      </section>
      <div class='mt-10'>
        <b class='red-text'>Would you like a $10 reward deposited to your bank account?</b>
      </div>
      <div class='row mt-25'>
        <div class='col s6'>
          <a :href='signupPath' class='btn'>Yes</a>
        </div>
      </div>
    </center>
  </div>
</template>
  <script>
  import email from './email'
  export default {
    props: ['amount', 'merchant', 'authCode', 'emailId'],
    data() {
      return {
        showEmail: false
      }
    },
    methods: {
      ok() {
        this.showEmail = true
      }
    },
    components: { email },
    computed: {
      timeNow() {
        return this.$moment().format('MMM Do YYYY, h:mm a')
      },
      signupPath() {
        return '/unverified_signup/new?email_id=' + this.emailId
      }
    }
  }
</script>
