<template>
  <div class='container mt-10'>
    <center>

      <section v-show='!showEmail'>
        <i class="fas fa-check-circle fa-2x emerald"></i>
        <div class='large-font'><b>Payment Successful</b></div>
        <span class='big-font'>
          <b>$0.00</b>
        </span>
        <div class='mt-5'>
          Order total paid by iWallet to<br>
          <b class='large-font'>
            {{merchant}}
          </b>
        </div>
      </section>
      <free-badges :phone-id='phoneId' :qid='qid' />

      <a class='btn-large mt-10' href='/r/277954' >Sign up and get $100 bonus</a>

    </center>
  </div>
</template>
  <script>
  import email from './email'
  import freeBadges from './free_badges'
  export default {
    props: ['amount', 'merchant', 'authCode', 'phoneId', 'qid'],
    data() {
      return {
        showEmail: false
      }
    },
    methods: {
    },
    components: { email, freeBadges },
    computed: {
      timeNow() {
        return this.$moment().format('MMM Do YYYY, h:mm a')
      }
    }
  }
</script>

