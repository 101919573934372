<template>
  <div>
    <form name="ebtForm" :action="pinPadPath" method="POST" ref='ebtForm'>
      <input type="hidden" name="AccuLanguage" value=“en-US”>
      <input type="hidden" name="AccuId" v-model="AccuId">
      <input type="hidden" name="tranId" v-model="tranId">
      <input type="hidden" name="tempCardId" v-model='ebtCardTokenId'>
      <input type="hidden" name="AccuReturnURL" :value="returnUrl">
      <input type="hidden" name="redirectUrl" :value="redirect">
      <input type="hidden" name="clientQid" :value="clientQid">
      <input type="hidden" name="merchantQid" :value="merchantQid">
      <input type="hidden" name="subtype" :value="subtype">
      <input type="hidden" name="amount" :value="amount">
      <input type="hidden" name="note" :value="note">
      <span class='btn-large Xpay-btn' :disabled="submitDisabled" @click="submit">
        <i v-show='submitDisabled' class="fas fa-spinner fa-spin grey-text"></i> 
        <span v-show='!submitDisabled'>Pay with EBT/{{subtype}}</span>
      </span>
    </form>
  </div>
</template>
<script>
  export default {
    props: ['pinPadPath', 'returnUrl', 'merchantQid', 'clientQid', 'amount', 'note', 'ebtCardTokenId', 'subtype', 'redirect'],
    data() {
      return {
        AccuId: null,
        tranId: null,
        submitDisabled: false
      }
    },
    created() {
      this.submitDisabled = true

      if(this.ebtCardTokenId) {
        this.getAccuId()
      }
    },
    methods: {
      getAccuId() {
        this.$http.get('/api/v1/app/ebt/tokenized_accu_ids/' + this.ebtCardTokenId).then(response => {
          this.submitDisabled = false
          this.AccuId = response.body.accu_id
          this.tranId = response.body.tran_id
        }, error => {
          this.submitDisabled = false
          if(error.status == 422) {
            this.notifyError(error.body.error_messages[0])
          } else {
            this.notifyError('Something went wrong')
          }
        })
      },
      submit() {
        if(this.amount) {
          this.submitDisabled = true
          this.$refs.ebtForm.submit()
        } else {
          this.notifyError('Please input Amount')
        }
      }
    }
  }
</script>
<style>
</style>
