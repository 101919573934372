<template>
  <div>
    <form enctype="multipart/form-data" novalidate ref="form">
      <div class='row'>
        <div class='col s6'>
          <label>Amount, USD</label>
          <input 
            name='amount'
            autofocus
            v-model='amount'
            step="0.01"
            type="number"
            inputmode="decimal"
            pattern="\d*"
            v-validate="'min_value:0.51|required'"
            class='inputText'
            lang="en-001">

          <span class='red-text small-font' v-show='errors.first("amount")'>The Amount field must be 0.51 or more.</span>
        </div>
      </div>
      <div class='row'>
        <div class='col s12 m6'>
          <label>Phone (optional)</label>
          <input v-model="phone" name="phone" type="tel">
        </div>
        <div class='col s12 m6'>
          <label>Note (optional)</label>
          <input v-model="note" name="note">
        </div>
        <div class='col s12 m6'>
          <label>{{invoiceLabel}}</label>
          <input v-model="invoice" name="invoice">
        </div>
      </div>

      <image-uploader
          id="frontImage"
          :debug="2"
          :maxWidth="900"
          :quality="0.8"
          :autoRotate=true
          outputFormat="blob"
          :preview=false
          :className="['file-upload', { 'fileinput--loaded' : hasFrontImage }]"
          :capture="false"
          accept="image/*"
          doNotResize="['gif', 'svg']"
          @input="setImageFront"
          @onUpload="startImageResize"
          onComplete="endImageResize"
        >

        <label for="frontImage" slot="upload-label">
          <div class="btn-large wide-btn" :disabled="disabled">
            <i class="fas fa-camera"></i> 
            <span class="upload-caption">{{ hasFrontImage ? 'Replace front side' : 'Check front' }}</span>
          </div>
        </label>

      </image-uploader>

      <img v-show="srcFront" :src="srcFront" alt="Check front image" height="90px" class="mt-10" />

      <image-uploader
          id="backImage"
          class="mt-25"
          :debug="2"
          :maxWidth="900"
          :quality="0.8"
          :autoRotate=true
          outputFormat="blob"
          :preview=false
          :className="['file-upload', { 'fileinput--loaded' : hasBackImage }]"
          :capture="false"
          accept="image/*"
          doNotResize="['gif', 'svg']"
          @input="setImageBack"
          @onUpload="startImageResize"
          onComplete="endImageResize"
        >

        <label for="backImage" slot="upload-label">
          <div class="btn-large wide-btn" :disabled="disabled">
            <i class="fas fa-camera"></i> 
            <span class="upload-caption">{{ hasBackImage ? 'Replace back side' : 'Check Back' }}</span>
          </div>
        </label>

      </image-uploader>
      <section class="grey-text small-font center" v-show="showSubmitButton">
        <img v-show="srcBack" :src="srcBack" alt="Check back image" height="90px" class="mt-10" />
        <div class="btn-large wide-btn mt-10" @click="submit" :disabled="disabled">
          <span v-if="disabled">
            <i class="fas fa-spinner fa-spin grey-text"></i> 
            <span>Processing...</span>
          </span>
          <span v-else>
            Submit
          </span>
        </div>
      </section>
    </form>
  </div>
</template>
<script>
  export default {
    props: ['requireInvoiceField', 'identifier'],
    data() {
      return {
        amount: null,
        phone: null,
        note: null,
        invoice: null,
        disabled: false,
        srcFront: null,
        hasFrontImage: false,
        imageFront: null,
        srcBack: null,
        hasBackImage: false,
        imageBack: null,
        showSubmitButton: false
      }
    },
    methods: {
      submit() {
        this.disabled = true
        let formData = new FormData(this.$refs.form)
        formData.append("check", this.imageFront)
        formData.append("check_back", this.imageBack)
        formData.append("identifier", this.identifier)

        this.$http.post("/api/v1/app/merchant/check_by_photo/create_double_sides", formData).then(response => {
          window.location.href = "/merchant/checks/" + response.body.transaction_id + "/success"
          this.disabled = false
        }, error => {
          this.disabled = false
          this.errorHandler(error)
        })
      },
      startImageResize() {
        // Some image processing
      },
      setImageFront(image) {
        console.log("Set image front")
        this.hasFrontImage = true
        this.imageFront = image

        // Prepare image preview
        var reader = new FileReader();
        reader.readAsDataURL(image); 
        reader.onloadend = res => {
          this.srcFront = reader.result;                
        }
        this.showSubmitButton = true
      },
      setImageBack(image) {
        console.log("Set image back")
        this.hasBackImage = true
        this.imageBack = image

        // Prepare image preview
        var reader = new FileReader();
        reader.readAsDataURL(image); 
        reader.onloadend = res => {
          this.srcBack = reader.result;                
        }
        this.showSubmitButton = true
      }
    },
    computed: {
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      }
    }
  }
</script>
<style>
  input#frontImage {
    display: none;
  }
  input#backImage {
    display: none;
  }
</style>

