<template>
  <div>
    <div>
      <label>EBT/SNAP Card number</label>
      <input 
        type='text'
        name='cardNumber'
        v-validate="'required'"
        v-model='cardNumber'>
        <span class='red-text small-font' v-show='errors.first("cardNumber")'>{{errors.first("cardNumber")}}</span>
    </div>

    <div class='mt-25'>
      <label>Address</label>
      <input
        name='Address'
        v-validate="'required'"
        v-model='address'>
        <span class='red-text small-font' v-show='errors.first("Address")'>{{errors.first("Address")}}</span>
    </div>
    <div class='row'>
      <div class='col m4 s6'>
        <label>City</label>
        <input 
          name='City'
          v-validate="'required'"
          v-model='city'>
        <span class='red-text small-font' v-show='errors.first("City")'>{{errors.first("City")}}</span>
      </div>
      <div class='col m4 s6'>
        <label>State</label>
        <state-select v-model="state"></state-select>
      </div>
      <div class='col m4 s6'>
        <label>Zip</label>
        <input 
          name='Zip'
          v-validate="'required'"
          v-model='zip'>
        <span class='red-text small-font' v-show='errors.first("Zip")'>{{errors.first("Zip")}}</span>
      </div>
    </div>

    <button :disabled='submitDisabled' class='btn-large mt-25' @click="addCard">Add</button>
  </div>
</template>
<script>
  // TS sources from: https://github.com/travist/jsencrypt
  // Encryption: http://www-cs-students.stanford.edu/~tjw/jsbn/
  import {BigInteger} from 'components/crypt/jsbn'
  import {RSAKey} from 'components/crypt/rsa'
  import {linebrk} from 'components/crypt/rsa'
  import {hex2b64} from 'components/crypt/base64'
  import {b64tohex} from 'components/crypt/base64'
  import stateSelect from 'components/utils/states_select'

  export default {
    props: ["redirectUrl"],
    data() {
      return {
        cardNumber: null,
        keyId: null,
        modulus: null,
        exponent: null,
        encryptedCard: null,
        address: null,
        city: null,
        state: 'CA',
        zip: null,
        submitDisabled: false
      }
    },
    components: {'state-select': stateSelect},
    methods: {
      addCard() {
        this.submitDisabled = true
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.getEncryptionKey()
          } else {
            this.submitDisabled = false
          }
        })
      },
      getEncryptionKey() {
        this.$http.get("/api/v1/app/ebt/encryption_keys").then(response => {
          this.keyId = response.body.key_id
          this.modulus = response.body.modulus
          this.exponent = response.body.exponent
          this.encrypt()
          this.tokenizeCard()
        }, error => {
          this.submitDisabled = false
          if(error.status == 422) {
            this.notifyError(error.body.error_messages[0])
          } else {
            this.notifyError('Something went wrong')
          }
        })
      },
      tokenizeCard() {
        this.$http.post("/api/v1/app/ebt/cards", this.params).then(response => {
          Turbolinks.visit(this.redirectTo)
        }, error => {
          this.submitDisabled = false
          if(error.status == 422) {
            this.notifyError(error.body.error_messages[0])
          } else {
            this.notifyError('Something went wrong')
          }
        })
      },
      encrypt() {
        var rsa = new RSAKey();
        rsa.setPublic(b64tohex(this.modulus), b64tohex(this.exponent))
        var res = rsa.encrypt(this.cardNumber.replace(/^\s+|\s+$/g, ''))
        this.encryptedCard =  linebrk(hex2b64(res), 64);
      }
    },
    computed: {
      params() {
        return {
          key_id: this.keyId,
          encrypted_card_number: this.encryptedCard,
          bin: this.cardNumber.substring(0, 9),
          address: {
            address1: this.address,
            city: this.city,
            state: this.state,
            postal_code: this.zip
          }
        }
      },
      redirectTo() {
        if(this.redirectUrl) { return this.redirectUrl }

        return "/bank_institutions"
      }
    }
  }
</script>
<style>
</style>
