<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        fetchPolicy: "no-cache",
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {pay {
            allowVoidsForAccountants
            allowRefundsForAccountants
            allowVoidsForSubAccounts
            allowRefundsForSubAccounts
          }}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.pay
      }
    },
    data() {
      return {
        settings: {}
      }
    },
    methods: {
      switchSettings(vars) {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation (
              $gid: ID,
              $allowRefundsForSubAccounts: Boolean,
              $allowVoidsForSubAccounts: Boolean,
              $allowRefundsForAccountants: Boolean,
              $allowVoidsForAccountants: Boolean,
          ) {
            updateRefundsVoids(input: 
              {
                allowRefundsForSubAccounts: $allowRefundsForSubAccounts,
                allowVoidsForSubAccounts: $allowVoidsForSubAccounts,
                allowRefundsForAccountants: $allowRefundsForAccountants,
                allowVoidsForAccountants: $allowVoidsForAccountants,
                userId: $gid
              }) {
              paySettings {allowRefundsForSubAccounts allowVoidsForSubAccounts allowRefundsForAccountants allowVoidsForAccountants}
            }
          }`,
          // Parameters
          variables: {...vars, gid: this.gid}
        }).then(result => {
          this.settings = result.data.updateRefundsVoids.paySettings
          this.notifySuccess("Pay settings successfully updated")
        }, error => {
          this.$apollo.queries.settings.refetch()
          this.gqlErrorHandler(error)
        })
      }
    }
  }
</script>

<template>
  <div class="row">
    <div class="col sm-6">
      <div class= "very-close-lines">
        Sub-accounts:<br>
        
        <small class="grey-text">Allow refunds</small>
      </div>
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @click='switchSettings({allowRefundsForSubAccounts: !settings.allowRefundsForSubAccounts})' v-model='settings.allowRefundsForSubAccounts' :disabled="$apollo.loading">
          <span class="lever"></span>
          On
        </label>
      </div>

      <div class= "very-close-lines mt-5">
        <small class="grey-text">Allow voids</small>
      </div>
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @click='switchSettings({allowVoidsForSubAccounts: !settings.allowVoidsForSubAccounts})' v-model='settings.allowVoidsForSubAccounts' :disabled="$apollo.loading">
          <span class="lever"></span>
          On
        </label>
      </div>
    </div>

    <div class="col sm-6">
      <div class= "very-close-lines">
        Accountants & Supervisors:<br>
        
        <small class="grey-text">Allow refunds</small>
      </div>
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @click='switchSettings({allowRefundsForAccountants: !settings.allowRefundsForAccountants})' v-model='settings.allowRefundsForAccountants' :disabled="$apollo.loading">
          <span class="lever"></span>
          On
        </label>
      </div>

      <div class= "very-close-lines mt-5">
        <small class="grey-text">Allow voids</small>
      </div>
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @click='switchSettings({allowVoidsForAccountants: !settings.allowVoidsForAccountants})' v-model='settings.allowVoidsForAccountants' :disabled="$apollo.loading">
          <span class="lever"></span>
          On
        </label>
      </div>
    </div>
    <div class="col s12 mt-10">
      <small class="grey-text">* Test voids under $3.00 are allowed</small>
    </div>
  </div>
</template>
