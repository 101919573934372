<template>
  <div>
    <center>
      <section v-show='!showAfterPayment'>
        <b>for {{type.replace(/[0-9]/g,'').toUpperCase()}}</b><br>
        Card amount: ${{cardAmount}}<br>
        <b>
          Congrats! <emojify text='🎊'></emojify>
          you got {{percentDiscount}}% off <emojify text='😎'></emojify>
        </b>
        <h3>Amount to pay: ${{amount}}</h3>
        <br><br>
        <div class='red-text'>
          {{errorMessage}}
        </div>
        <button class='btn-large' :disabled='blockPaymentButton' @click='pay'>
          <i v-show='blockPaymentButton' class="fas fa-spinner fa-spin grey-text"></i> 
          <span v-show='blockPaymentButton'>Processing...</span>
          <span v-show='!blockPaymentButton'>Pay ${{amount}}</span>
        </button>
        <div class='grey-text'>
          <br><br>
          * limit ${{buyingLimit}} per person per week
        </div>
      </section>

      <section v-show='showAfterPayment'>
        <after-payment 
          :cardAmount='paidCardAmount'
          :amount='paidAmount'
          :type='cardType'
          :cardNumber='cardNumber'
          :percentDiscount='percentDiscount'
          :usageNotice='usageNotice'/>

      </section>
    </center>
  </div>
</template>
<script>
  const giftCardPaymentApi = '/api/v1/app/client/gift_card/payments'
  import afterPayment from './after_payment.vue'

  export default {
    components: { afterPayment },
    created() {
      this.getInitial()
    },
    props: ['type'],
    data() {
      return {
        amount: null,
        cardAmount: null,
        cardType: null,
        showAfterPayment: false,
        paidCardAmount: null,
        buyingLimit: null,
        paidAmount: null,
        cardNumber: null,
        usageNotice: null,
        percentDiscount: null,
        blockPaymentButton: false,
        errorMessage: null
      }
    },
    methods: {
      getInitial() {
        this.$http.get(giftCardPaymentApi + '/new?type=' + this.type).then(response => {
          this.amount = response.body.data.attributes.amount
          this.cardAmount = response.body.data.attributes.card_amount
          this.cardType = response.body.data.attributes.type
          this.percentDiscount = response.body.data.attributes.percent_discount
          this.buyingLimit = response.body.data.attributes.buying_limit
        })
      },
      pay() {
        this.blockPaymentButton = true
        this.$http.post(giftCardPaymentApi, { amount: this.amount, card_type: this.cardType } ).then(response => {
          this.notifySuccess(response.body.message)
          this.showAfterPayment = true
          this.paidAmount = response.body.payment.data.attributes.amount
          this.paidCardAmount = response.body.payment.data.attributes.card_amount
          this.cardNumber = response.body.payment.data.attributes.card_number
          this.usageNotice = response.body.payment.data.attributes.usage_notice
          
        }, error => {
          this.notifyError('Unable to buy a Gift Card')
          this.blockPaymentButton = false
          this.errorMessage = error.body.error_message
        })
      }
    }
  }
</script>
