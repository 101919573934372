<template>
  <div>
    <h3>Let's Split the bill!</h3>

    <div>
      <label>Quantity of participants (including you)</label>
      <input name='qty' v-model='qty' step='1' type="number" v-validate="'min_value:2|numeric|required'" class='inputText'>
      <div class='red-text'>{{ errors.first('qty') }}</div>
    </div>

    <div>
      <label>Bill amount</label>
      <input name='amount' v-model='amount' step="0.01" type="number" v-validate="'min_value:0.01|required'" class='inputText'>
      <div class='red-text'>{{ errors.first('amount') }}</div>
    </div>

    <button @click='submit' class='btn mt-25' :disabled='btnDisable'>
      Submit
    </button>

    <div class='mt-25'>
      You are going to split the bill with {{qty}} persons. With payment of 
      ${{perParticipant}} per each person.
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        qty: 2,
        amount: null,
        btnDisable: false
      }
    },
    methods: {
      submit() {
        this.btnDisable = true
        this.$http
          .post('/api/v1/app/client/bill_split', this.payLoad)
          .then(response => {
            this.$emit('created', response.body)
          }, error => {
            this.btnDisable = false
            console.log(error)
            this.notifyError(
              Object.keys(error.body.error)[0] + ' ' +
              Object.values(error.body.error)[0]
            )
          })
      }
    },
    computed: {
      perParticipant() {
        if (this.amount) {
          return Math.round((this.amount/this.qty)*100)/100
        }
      },
      payLoad() {
        return {
          bill_split: {
            qty: this.qty,
            amount: this.amount
          }
        }
      }
    }
  }
</script>
<style>
</style>
