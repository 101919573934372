<template>
  <div class='mt-25'>
    <section v-show='!showLoyaltyAfterpayment'>
      <div v-show='!showButton' class='bold-text emerald'>
        Collect {{totalForReward}} stamps and get free sandwich
      </div>
      <center v-if='haveStamps || totalForReward'>
        <span v-for='stamp in parseInt(calcHaveStamps)' :key="stamp"><img src='/reward.png' class="responsive-img"></span>
        <span v-for='s in leftToWin'><img src='/reward-grey.png' ></span><br>
      </center>
      <div v-show='showButton'>
        Congrats! 
        <emojify :text="'\ud83d\ude0e'" /> 
        You've got all stamps!
        <button class='btn mt-5' @click='buy' :disabled='disableButton'>
          Get FREE sandwich
        </button>
        <br>
        <div class='mt-10'>
          <b>Or follow magic link for signup!</b>
        </div>
      </div>
    </section>
    <section v-show='showLoyaltyAfterpayment' class='emerald'>
      <emojify :text="'\ud83c\udf54'" className='emo-100'/><br>
      Free Sandwich paid successfully!
    </section>
  </div>
</template>
<script>
  export default {
    props: ['phoneId', 'qid'],
    created() {
      if(this.phoneId) {
        this.getData()
      }
    },
    data() {
      return {
        haveStamps: null,
        totalForReward: null,
        disableButton: false,
        showLoyaltyAfterpayment: false
      }
    },
    methods: {
      getData() {
        this.$http.get(this.apiPath).then(response => {
          this.haveStamps     = response.body.have_stamps
          this.totalForReward = response.body.total_for_reward
        })
      },
      buy() {
        this.disableButton = true
        this.$http.post('/api/v1/vue/free/loyalty_payments', this.loyaltyParams).then(
          response => {
            this.showLoyaltyAfterpayment = true
            
          }, error => {
            this.notifyError(error.body.error.base[0])
            this.disableButton = false
          })
      }
    },
    computed: {
      calcHaveStamps() {
        var that = this
        if(parseInt(that.totalForReward) < parseInt(that.haveStamps)) {
          return that.totalForReward
        } else {
          return that.haveStamps
        }
      },
      leftToWin() {
        var that = this
        return parseInt(that.totalForReward) - parseInt(that.calcHaveStamps)
      },
      apiPath() {
        return '/api/v1/vue/free/phones/' + this.phoneId
      },
      showButton() {
        if(this.leftToWin == 0) {
          return true
        } else {
          return false
        }
      },
      loyaltyParams() {
        return {
          phone_id: this.phoneId,
          qid: this.qid
        }
      }
    }
  }
</script>
<style>
</style>
