<template>
  <div>
    <a class="waves-effect waves-light btn modal-trigger" href="#modal_add_items">Add Item</a>

    <div id="modal_add_items" class="modal mt-25" style="max-height: 90vh;">
      <div class="modal-content">
        <h3 class="grey-text text-darken-2">Transaction amount: <span class="bold-font emerald">{{transactionAmount | currency}}</span></h3>
        <div class="progress">
          <div class="determinate" :style="'width: ' + progress + '%'"></div>
        </div>
        <div class="grey-text text-darken-2" v-show="disableSave">
          <i class="fas fa-chart-pie"></i>
          Unallocated Amount: <span class="bold-font red-text">{{unallocatedAmount | currency}}</span>
          <a href @click.prevent="allocate" v-show="items.length > 0">
            <i class="fas fa-arrows-alt-v"></i>
            Allocate
          </a>
        </div>
        <items
          :items-updated="itemsUpdated"
          :items="items"
          :set-amount="amount"
          :invoice-label="invoiceLabel"
          :require-invoice-confirmation="requireInvoiceConfirmation"
          :show-checkout="false">
        </items>
        <a class="btn btn-large" @click="submit" :disabled="disableSave" v-show="!processing">Save items</a><br>

        <div v-show="processing" class='grey-text'>
          <i class="fas fa-spinner fa-spin grey-text"></i> 
          Processing ...
        </div>
        <div class="small-font grey-text" v-show="disableSave">
          * Please allocate the remaining amount among items to enable the 'Save items' button
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import items from './generic_cards/items'
  import gql from 'graphql-tag'

  export default {
    props: ["transactionAmount", "transactionId", "requireInvoiceField", "requireInvoiceConfirmation"],
    components: {items},
    data() {
      return {
        amount: null,
        items: [],
        processing: false
      }
    },
    methods: {
      itemsUpdated(amount) {
        this.amount = null
      },
      allocate() {
        this.amount = this.unallocatedAmount.toFixed(2);
      },
      submit() {
        this.processing = true
        this.$apollo.mutate({
          mutation: gql`mutation (
            $items: [ItemAttributes!],
            $transactionId: ID!,
          ) {
            createItems(input: 
              {
                items: $items,
                transactionId: $transactionId
              }) {
              message errors
            }
          }`,
          // Parameters
          variables: {items: this.items, transactionId: this.transactionId}
        }).then(result => {
          if(result.data.createItems.errors.length > 0) {
            this.processing = false
            this.notifyError(result.data.createItems.errors[0])
          } else {
            this.notifySuccess(result.data.createItems.message)
            location.reload();
          }
        }, error => {
          this.processing = false
          this.gqlErrorHandler(error)
        })
      }
    },
    computed: {
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      },
      unallocatedAmount() {
        return this.transactionAmount - this.allocatedAmount;
      },
      progress() {
        const progress = (this.allocatedAmount / this.transactionAmount) * 100;
        
        return progress.toFixed(2)
      },
      allocatedAmount() {
        return this.items.reduce((total, item) => total + item.amount, 0).toFixed(2)
      },
      disableSave() {
        return !(this.unallocatedAmount === 0)
      }
    }
  }
</script>
<style scoped>
  .modal-content {
    overflow-y: auto;
  }
</style>
