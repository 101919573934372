<template>
  <div>
    <section>
      <div class='row'>
        <div class='col s6'>
          <label>Amount, USD</label>
          <input 
            :name='amountName'
            v-model='amount'
            step="0.01"
            type="number"
            pattern="\d*"
            inputmode="decimal"
            v-validate="'min_value:0.01|required'"
            class='inputText'
            lang="en-001">

          <span class='red-text small-font' v-show='errors.first(amountName)'>The Amount field must be 0.01 or more.</span>
        </div>
        <div class='col s6'>
          <label>Note</label>
          <input :name='noteName' v-model='note' placeholder='Transaction details ...'>
        </div>

        <input v-model='selectedFundingSource' type='hidden' :name='selectedFundingSourceName'>
        
        <div class='col s12 mt-25'>
          <center v-show="fundingSource.type == 'notAvailable'" class='card-panel'>
            <h3 class="red-text">
            <i class="fas fa-exclamation-triangle"></i><br>
            You are unable to pay this transaction!</h3>
            No compatible funding source
          </center>
          <span class="btn-large pay-btn" @click='submitForm' :disabled='submitDisabled' v-show='["balance", "ach"].includes(fundingSource.type)'>Pay</span>
          <ebt-transaction-poster 
            v-if='fundingSource.type == "ebt"'
            class='right'
            :pinPadPath='pinPadPath'
            :returnUrl='returnUrl'
            :merchantQid='merchantQid'
            :clientQid='clientQid'
            :amount='amount'
            :note='note'
            :redirect='redirect'
            :subtype='fundingSource.subtype'
            :ebtCardTokenId='fundingSource.id'>
          </ebt-transaction-poster>
          
          <pay-by-card v-show='fundingSource.type =="cc"'
            :merchantQid='merchantQid'
            :amount='amount'
            :note='note'
            :subtype='fundingSource.subtype'
            :tipPercent='percent'
            :tipAmount='tipAmountForPercent(percent)'
            :cardId='fundingSource.id'>
          </pay-by-card>

        </div>
      </div>
    </section>

    <section>
      <funding-source-picker
        v-model='fundingSource'
        :client-qid='clientQid'
        :merchant-qid='merchantQid'>
      </funding-source-picker>
    </section>

    <section v-show='showTip && localSubmit'>
      <center class='large-font bold-text grey-text'>
        Add a tip (optional)
        <span v-if='tipAmountForPercent(percent) > 0'>
          {{percent}}% (${{tipAmountForPercent(percent)}}) | <a @click='resetTip'>Remove</a>
        </span>
      </center>
      <label v-show='!isHidden'>Tip, USD</label>
      <input :name='tipAmountName' :hidden='isHidden' :value='tipAmountForPercent(percent)' step="0.01" type="number" @input='manualChanged'>
      <input :name='tipPercentName' hidden :value='percent'>
      <center v-show='!isHidden'><span href class='mt-10 btn grey lighten-4 grey-text text-darken-3' @click='isHidden = !isHidden'>Use preset tip</span></center>
      <div v-show='isHidden'>
        <div class='row'>
          <div class='col s4'>
            <div class='card-panel pointer' @click='percent=15' :class='{"emerald-back": percent == 15}'>
              <div class='big-font bold-text center'>15%</div>
              ${{tipAmountForPercent(15)}}
            </div>
          </div>
          <div class='col s4'>
            <div class='card-panel pointer' @click='percent=20' :class='{"emerald-back": percent == 20}'>
              <div class='big-font bold-text center'>20%</div>
              ${{tipAmountForPercent(20)}}
            </div>
          </div>
          <div class='col s4'>
            <div class='card-panel pointer' @click='percent=25' :class='{"emerald-back": percent == 25}'>
              <div class='big-font bold-text center'>25%</div>
              ${{tipAmountForPercent(25)}}
            </div>
          </div>
        </div>
        <center><span class='btn grey lighten-4 grey-text text-darken-3' @click='isHidden = !isHidden'>CUSTOM TIP</span></center><br><br>
      </div>
    </section>
  </div>
</template>
<script>
  import ebtTransactionPoster from 'components/client/ebt_transaction_poster'
  export default {
    components: { 'ebt-transaction-poster': ebtTransactionPoster },
    data() {
      return {
        percent: 0,
        isHidden: true,
        amount: this.preAmount,
        note: this.preNote,
        submitDisabled: false,
        fundingSource: {}
      }
    },
    props: ['name','showTip', 'pinPadPath', 'returnUrl', 'merchantQid', 'clientQid', 'preAmount', 'preNote', 'redirect'],
    methods: {
      tipAmountForPercent(percent) {
        return (this.amount * percent / 100).toFixed(2)
      },
      submitForm() {
        this.submitDisabled = true
        this.$validator.validateAll().then((result) => {
          if (result) {
            document.getElementById("payForm").submit()
          } else {
            this.submitDisabled = false
          }
        })
      },
      manualChanged(event) {
        if(this.amount == 0) {
          this.percent = 0
        } else {
          this.percent = (event.currentTarget.value * 100 / this.amount).toFixed(2)
        }
      },
      resetTip() {
        this.percent = 0
        this.isHidden = true,
        this.submitDisabled = false
      }
    },
    computed: {
      tipAmountName() {
        return this.name + '[tip_amount]'
      },
      tipPercentName() {
        return this.name + '[tip_percent]'
      },
      amountName() {
        return this.name + '[amount]'
      },
      noteName() {
        return this.name + '[note]'
      },
      selectedFundingSourceName() {
        return this.name + '[funding_source]'
      },
      localSubmit() {
        if(this.fundingSource.type == "ebt") {
          return false
        }
        return true
      },
      selectedFundingSource() {
        return this.fundingSource.subtype
      }
    }
  }
</script>
<style scoped>
</style>
