<template>
  <span>
    <button :class="btnClass" @click="copy">{{label}}</button>
  </span>
</template>
<script>
  export default {
    props: {data: {type: String}, label: {type: String}, btnClass: {type: String, default: "btn"}},
    methods: {
      copy() {
        this.$copyText(this.data).then( result => {
          this.notifySuccess("Copied to clipboard!")
        }, error => {
          this.notifyError("Can not copy")
        })
      }
    }
  }
</script>
<style>
</style>
