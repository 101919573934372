<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      allowManageSubaccounts: {
        fetchPolicy: "no-cache",
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {allowManageSubaccounts}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.allowManageSubaccounts
      }
    },
    data() {
      return {
        allowManageSubaccounts: null
      }
    },
    methods: {
      switchTip() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($allowManageSubaccounts: Boolean!, $gid: ID) {
            updateAllowManageSubaccounts(input: {allowManageSubaccounts: $allowManageSubaccounts, userId: $gid}) {
              allowManageSubaccounts
            }
          }`,
          // Parameters
          variables: {
            allowManageSubaccounts: this.allowManageSubaccounts,
            gid: this.gid
          },
        }).then(result => {
          this.notifySuccess("Pay settings successfully updated")
        }, error => {
          this.$apollo.queries.allowManageSubaccounts.refetch()
          this.gqlErrorHandler(error)
        })
      }
    }
  }
</script>

<template>
  <div>
    <section>
      <div class= "very-close-lines">
        Allow manage sub-accounts:<br>
        
      </div>
      <!-- Switch -->
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @change='switchTip' v-model='allowManageSubaccounts' :disabled="$apollo.loading">
          <span class="lever"></span>
          On
        </label>
      </div>
    </section>
  </div>
</template>
