<template>
  <div>
    <div id="test"></div>
  </div>
</template>
<script>
  import AdyenDocumentViewer from '@adyen/adyen-document-viewer';
  import '@adyen/adyen-document-viewer/dist/adyen-document-viewer.min.css';

  export default {
    name: 'YourComponent',
    props: ['tos'],
    mounted() {
      this.initDocumentViewer();
    },
    methods: {
      initDocumentViewer() {
        const documentViewer = new AdyenDocumentViewer('#test');
        const document = JSON.parse(decodeURIComponent(escape(window.atob(this.tos))));
        documentViewer.render(document);
      }
    }
  }
</script>

