<template>
  <div v-if='step'>
    <div class="progress-bar">
      
      <div class="progress-track"></div>
        
      <div class="progress-step" ref="step1">
        <b>Step 1</b><br> Registration
      </div>
        
      <div class="progress-step" ref='step2'>
        <b>Step 2</b><br> Verify identity
      </div>
        
      <div class="progress-step" ref='step3'>
        <b>Step 3</b><br> Add bank
      </div>
      <div class="progress-step" ref='step4'>
        <b>Step 4</b><br> Add Money
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    if(this.step == 1) {
      this.$refs.step1.classList.add("is-active");
    }
    if(this.step == 2) {
      this.$refs.step1.classList.add("is-complete");
      this.$refs.step2.classList.add("is-active");
    }
    if(this.step == 3) {
      this.$refs.step1.classList.add("is-complete");
      this.$refs.step2.classList.add("is-complete");
      this.$refs.step3.classList.add("is-active");
    }
    if(this.step == 4) {
      this.$refs.step1.classList.add("is-complete");
      this.$refs.step2.classList.add("is-complete");
      this.$refs.step3.classList.add("is-complete");
      this.$refs.step4.classList.add("is-active");
    }
  },
  props:['step'],
}
</script>
<style scoped lang='scss'>
  $grey:  #777;
  $grey2: #dfe3e4;
  $blue:  #00ab8e;
  $green: #00ab8e;
  $white: #fff;

  .progress-bar {
    position: relative;
    display: flex;
  }
  .progress-track {
    position: absolute;
    top: 8px;
    width: 100%;
    height: 5px;
    background-color: $grey2;
    z-index: -1;
  }
  // Each Step on the Progress Bar
  .progress-step {
    position: relative;
    width: 100%;
    font-size: 12px;
    text-align: center;

    &:last-child:after {
      display: none;
    }
  
    // Step's circle in default state
    &:before {
      content: "";
      display: flex;
      margin: 0 auto;
      margin-bottom: 10px;
      width: 22px;
      height: 22px;
      background: $white;
      border: 2px solid $grey2;
      border-radius: 100%;
      color: $white;
    }

    // Step's progress bar in default state
    &:after {
      content: "";
      position: absolute;
      top: 8px;
      left: 50%;
      width: 0%;
      transition: width 1s ease-in;
      height: 5px;
      background: $grey2;
      z-index: -1;
    }
    // Step's active state
    &.is-active {
      color: black;

      &:before {
        border: 4px solid $grey;
        animation: pulse 2s infinite;
      }
    }

    // Step's complete state
    &.is-complete {
      color: $green;

      &:before {
        font-family: FontAwesome;
        font-size: 10px;
        color: $white;
        background: $green;
        border: 4px solid transparent;
      }

    // Step's progress bar in complete state
    &:after {
      background: #424242;
      animation: nextStep 1s;
      animation-fill-mode: forwards;
    }
  }
}
  // Pulse animation for Step's circle in active state
  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgba(33,131,221, 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(33,131,221, 0); }
    100% { box-shadow: 0 0 0 0 rgba(33,131,221, 0); }
  }
  // Progressing to next step animation for Step's progress bar
  @keyframes nextStep {
    0% { width: 0%; }
    100% { width: 100%; }
  }
</style>
