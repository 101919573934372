<template>
  <section>
    <div class='row'>
      <div class='col s12'>
        <b>Loyalty</b>

        <!-- Switch -->
        <div class="switch right">
          <label>
            Off
            <input type="checkbox" @change='switched' v-model='actualActive'>
            <span class="lever"></span>
            On
          </label>
        </div>

        <div class='grey-text mt-25' v-show='actualActive'>
          Total stamps: {{actualTotalStamps}}<br> reward discount percent: {{actualRewardPercent}}%<br> min amount: ${{minAmount}}<br> 
          <a href @click.prevent='showForm = true;' class='right'>Edit</a>
        </div>

        <!-- Form -->
        <div v-show='actualActive && showForm' class='mt-25'>
          <div class='row'>
            <div class='col s12 m4'>
              <div class="input-field">
                <select v-model='actualTotalStamps' id='stamps-select'>
                  <option value=3>3</option>
                  <option value=5>5</option>
                  <option value=7>7</option>
                  <option value=10>10</option>
                  <option value=15>15</option>
                  <option value=20>20</option>
                </select>
                <label for='stamps-select'>Total Stamps</label>
              </div>
            </div>
            <div class='col s12 m4'>
              <div class="input-field">
                <input 
                  type="number" 
                  id='min-amount' 
                  v-model='minAmount' 
                  v-validate="'required|decimal|min_value:1'" 
                  name='min-amount'>

                <label for='min-amount'>Min order amount</label>
              </div>
            </div>
            <div class='col s12 m4'>
              <div class="input-field">
                <input 
                  type="number" 
                  id='percent-input' 
                  v-model='actualRewardPercent' 
                  v-validate="'required|numeric|min_value:1|max_value:99'" 
                  name='percent'>

                <label for='percent-input'>Reward percent, %</label>
              </div>
            </div>
          </div>
          <div class='red-text'>{{ errors.first('percent') }}</div>
          <div class='red-text'>{{ errors.first('min-amount') }}</div>

          <button class='btn' :disabled="errors.any()" @click='saveSettings'>Save</button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  const apiLoyaltyPath = '/api/v1/app/merchant/settings/loyalties'

  export default {

    props: ['total_stamps', 'reward_percent', 'active', 'min_amount'],
    data() {
      return {
        actualTotalStamps: this.total_stamps,
        actualRewardPercent: this.reward_percent,
        actualActive: this.active,
        minAmount: this.min_amount,
        isSaving: false,
        showForm: false,
      }
    },
    methods: {
      saveSettings() {
        this.isSaving = true
        var payload = { loyalty: { total_stamps: this.actualTotalStamps, reward_percent: this.actualRewardPercent, min_amount: this.minAmount }, active: this.actualActive }
        this.$http.post(apiLoyaltyPath, payload).then( response => {
          this.showForm = false
          this.notifySuccess('Loyalty settings successfully updated')
        }, response => {
          this.notifyError('Loyalty settings NOT updated. Something went wrong')
        })

      },
      switched() {
        // Reset values before change active in case if it was validation error
        this.actualTotalStamps = this.total_stamps
        this.actualRewardPercent = this.reward_percent

        this.saveSettings()
      }
    }
  }
</script>
