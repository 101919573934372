<template>
  <div>
    <span class='red badge new' v-show="badges.disputes > 0">{{badges.disputes}}</span>
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    apollo: {
      badges: {
        query: gql`query {merchant { merchantUser {badges {disputes}}}}`,
        update: data => data.merchant.merchantUser.badges
      }
    },
    data() {
      return {
        badges: {disputes: 0}
      }
    }
  }
</script>
<style>
</style>
