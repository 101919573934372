<script>
  export default {
    props: ['userId', 'active'],
    created() {
      this.state = this.active

    },
    data() {
      return {
        state: null
      }
    },
    methods: {
      switched() {
        this.$http.post('/api/v1/app/admin/settings/pays', { id: this.userId, pay: {allow_create_reusable_card_by_terminal: this.state}}).then(response => {
          this.notifySuccess('Setting successfully updated')
        }, response => {
          this.notifyError('Something went wrong. Settins not updated')
          this.state = !this.state
        })
      }
    }
  }
</script>

<template>
  <div>
    <!-- Switch -->
    <div class="switch">
      <label>
        PI (Tips before)
        <input type="checkbox" @change='switched' v-model='state'>
        <span class="lever"></span>
        SI (Tips after)
      </label>
    </div>
  </div>
</template>

