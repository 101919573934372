<template>
  <div v-if='show'>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    created() {
      this.$bus.$on('hideSkipThisStep', this.hide);
      this.$bus.$on('showSkipThisStep', this.showStep);
    },
    data() {
      return {
        show: false
      }
    },
    methods: {
      hide() {
        this.show = false
      },
      showStep() {
        this.show = true
      }
    }
  }
</script>
