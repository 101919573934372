<template>
  <div>
    <button class='btn red' v-show='show' @click='cancel' :disabled='disabled'>Cancel</button>
  </div>
</template>
<script>
  export default {
    created() {
      this.checkButtonState()
    },
    data() {
      return {
        show: false,
        disabled: false,
        endPoint: '/api/v1/app/client/pending_transactions/'
      }
    },
    props: ['id'],
    methods: {
      checkButtonState() {
        this.$http.get(this.endPoint + this.id).then(response => {
          this.show = response.body.allow_cancel
        })
      },
      cancel() {
        this.disabled = true
        this.$http.post(this.endPoint + this.id + '/cancel').then(response => {
          Turbolinks.visit(window.location)
        }, response => {
          this.disabled = false
          this.notifyError(response.body.error)
        })
      }
    }
  }
</script>
