<template>
  <div>
    <p class='mt-25'>
      <label>
        <input @change="selected='business'" class="with-gap" name="group1" type="radio" checked />
        <span>
          <span class='bold-font grey-text text-darken-2'>Business account</span>
          <p class='small-font close-lines grey-text text-darken-1' style="margin-top: 0px;">
            A contactless way to accept payments. Zero fees,<br>
            hardware or set up costs for your business.
          </p>
        </span>

      </label>
    </p>
    <p class='mt-25'>
      <label>
        <input @change="selected='client'" class="with-gap" name="group1" type="radio" />
        <span>
          <span class='bold-font grey-text text-darken-2'>Personal account</span>
          <p class='small-font close-lines grey-text text-darken-1' style="margin-top: 0px;">
            A safe, contactless way to make payments.<br>
            Signing up is fast and free.
          </p>
        </span>
      </label>
    </p>
    <div>
      <div class="mt-50">
        <a :href='path' class="btn wide-btn rounded-btn">Next</a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        sel: null,
        selected: 'business'
      }
    },
    computed: {
      path() {
        if(this.selected == 'business') {
          return '/users/sign_up'
        } else {
          return '/personal_account'
        }
      }
    }
  }
</script>
