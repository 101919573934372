<template>
  <div>
    <section v-if="!showItemForm">
      <form enctype="multipart/form-data" novalidate ref="form">
        <div class="row">
          <div v-if="items.length == 0">
            <div class='col s6'>
              <label>Amount, USD</label>
              <input 
                name='amount'
                autofocus
                v-model='amount'
                step="0.01"
                type="number"
                inputmode="decimal"
                pattern="\d*"
                v-validate="'min_value:0.51|required'"
                class='inputText'
                lang="en-001">

              <span class='red-text small-font' v-show='errors.first("amount")'>The Amount field must be 0.51 or more.</span>
            </div>
            <div class='col s12 m6'>
              <label>Note (optional)</label>
              <input v-model="note" name="note">
            </div>
            <div class='col s12 m6'>
              <label>{{invoiceLabel}}</label>
              <input v-model="invoice" name="invoice" type="text" id="invoice" ref="invoice">
            </div>
            <div class="input-field col s12 m6" v-if="requireInvoiceConfirmation">
              <i class="fas fa-file-invoice prefix grey-text"></i>
              <input name="invoice-cfm" id="invoice-cfm" type="text" v-validate="{required: invoice.length > 0, confirmed: 'invoice'}">

              <label for="invoice-cfm">Invoice (confirmation)</label>
              <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
            </div>

          </div>

          <div v-if="items.length > 0">
            <div class="col s12 m6">
              <span class="items-amount-check bold-font emerald">{{amount | currency}}</span>
            </div>
            <div class="col s12 m6">
              <show-items :items="items" :items-updated="itemsUpdated"></show-items>
            </div>
          </div>

          <div class='col s12 m6'>
            <label>Phone (optional)</label>
            <input v-model="phone" name="phone" type="tel">
          </div>

          <div class='col s12 m6 right'>
            <a class="btn btn-small outlined-btn right mt-10" @click="showItemForm=true">
              <i class="fas fa-plus"></i>

              Add Item
            </a>
          </div>
        </div>

        <image-uploader
            :debug="1"
            :maxWidth="900"
            :quality="0.8"
            :autoRotate=true
            outputFormat="blob"
            :preview=false
            :className="['file-upload', { 'fileinput--loaded' : hasImage }]"
            :capture="false"
            accept="image/*"
            doNotResize="['gif', 'svg']"
            @input="setImage"
            @onUpload="startImageResize"
            onComplete="endImageResize"
          >

          <label for="fileInput" slot="upload-label">
            <div class="btn-large wide-btn" :disabled="disabled">
              <i class="fas fa-camera"></i> 
              <span class="upload-caption">{{ hasImage ? 'Replace' : 'Check' }}</span>
            </div>
          </label>

        </image-uploader>

        <section class="grey-text small-font center" v-show="showSubmitButton">
          <img :src="src" alt="Check image" height="90px" class="mt-10" />
          <div class="btn-large wide-btn mt-10" @click="submit" :disabled="disabled">
            <span v-if="disabled">
              <i class="fas fa-spinner fa-spin grey-text"></i> 
              <span>Processing...</span>
            </span>
            <span v-else>
              Submit
            </span>
          </div>
        </section>
      </form>
    </section>

    <items
      v-if="showItemForm"
      :items="items"
      :items-updated="itemsUpdated"
      :set-invoice="invoice"
      :set-note="note"
      :set-amount="items.length > 0 ? null : amount"
      :invoice-label="invoiceLabel"
      :require-invoice-confirmation="requireInvoiceConfirmation"
      @close="showItemForm=false">

    </items>
  </div>
</template>
<script>
  import items from './generic_cards/items'
  import showItems from './generic_cards/show_items'

  export default {
    props: ['requireInvoiceField', 'requireInvoiceConfirmation', 'setAmount', 'setPhone', 'setNote', 'setInvoice'],
    components: {items, showItems},
    data() {
      return {
        amount: this.setAmount,
        phone: this.setPhone,
        note: this.setNote,
        invoice: this.setInvoice,
        disabled: false,
        src: null,
        hasImage: false,
        image: null,
        showSubmitButton: false,
        items: [],
        showItemForm: false
      }
    },
    methods: {
      submit() {
        this.disabled = true
        this.$validator.validateAll().then((result) => {
          if (result) {
            let formData = new FormData(this.$refs.form)
            formData.append("check", this.image)
            formData.append("amount", this.amount)

            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                formData.append(`items[${i}][amount]`, item.amount);
                formData.append(`items[${i}][note]`, item.note);
                formData.append(`items[${i}][invoice]`, item.invoice);
            }

            this.$http.post("/api/v1/app/merchant/check_by_photo", formData).then(response => {
              window.location.href = "/merchant/checks/" + response.body.transaction_id + "/success"
              this.disabled = false
            }, error => {
              this.disabled = false
              this.errorHandler(error)
            })
          } else {
            this.disabled = false
            this.notifyError("Please complete all required fields!")
          }
        })
      },
      startImageResize() {
        // Some image processing
      },
      setImage(image) {
        this.hasImage = true
        this.image = image

        // Prepare image preview
        var reader = new FileReader();
        reader.readAsDataURL(image); 
        reader.onloadend = res => {
          this.src = reader.result;                
        }
        this.showSubmitButton = true
      },
      itemsUpdated(amount) {
        this.amount = amount
        this.invoice = ""
        this.note = ""
      }
    },
    computed: {
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      }
    }
  }
</script>
<style>
  input#fileInput {
    display: none;
  }

  .items-amount-check {
    font-size: 3em !important;
  }
</style>
