<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {pay {gateway}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.pay
      }
    },
    data() {
      return {
        settings: {}
      }
    },
    methods: {
      switchIt() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($gateway: String!, $gid: ID) {
            updateGateway(input: {gateway: $gateway, userId: $gid}) {
              paySettings { gateway }
            }
          }`,
          // Parameters
          variables: {
            gateway: this.settings.gateway,
            gid: this.gid
          },
        }).then(result => {
          this.settings.gateway = result.data.updateGateway.paySettings.gateway
          this.notifySuccess("Pay settings successfully updated")
        }, error => {
          this.gqlErrorHandler(error)
        })
      }
    }
  }
</script>

<template>
  <div>
    <section>
      <label>Gateway:</label>
        
      <select class="browser-default" v-model="settings.gateway" @change="switchIt">
        <option></option>
        <option value="stripe">Stripe</option>
        <option value="payrix">Payrix</option>
      </select>
    </section>
  </div>
</template>
