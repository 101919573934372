<template>
  <div>
    <a class="btn outlined-btn modal-trigger" href="#modal1">Change amount</a>
    <!-- Modal Structure -->

    <div id="modal1" class="modal" ref="modal">
      <div class="modal-content">
        <h4 class="bold-font">Request an amount change</h4>
        <b class="bold-font red-text">Warning: it takes up to 3 - 5 weeks and no other changes will be allowed during that period</b>
        <p>The current check amount is {{oldAmount | currency}} - what should the new amount be?</p>
        <div class="row">
          <div class="input-field col s12 m6">
            <i class="fas fa-dollar-sign prefix grey-text"></i>
            <input 
                class="inputText"
                type="number"
                pattern="\d*"
                v-validate="'min_value:0.01|required'"
              v-model="amount">
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Cancel</a>
        <button :disabled="!amount" class="waves-effect waves-green btn" @click="submit">Submit</button>
      </div>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    props: ["oldAmount", "gid"],
    data() {
      return {
        amount: null
      }
    },
    methods: {
      submit() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($id: ID!, $amount: Float!) {
            createChangeAmountRequest(input: {amount: $amount, transactionId: $id}) {
              message
              errors
            }
          }`,
          // Parameters
          variables: {
            amount: Number(this.amount),
            id: this.gid
          }
        }).then((response) => {
          if(response.data.createChangeAmountRequest.errors.length > 0) {
            this.notifyError(response.data.createChangeAmountRequest.errors[0])
          } else {
            M.Modal.getInstance(this.$refs.modal).close()
            this.notifySuccess(response.data.createChangeAmountRequest.message)
            this.amount = null
          }
        }).catch((error) => {
          this.notifyError(error)
        })
      }
    }
  }
</script>
