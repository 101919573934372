<template>
  <div>
    Business name: <b>{{businessName}}</b>
    <span v-show='dba'><br>Doing business as: <b>{{dba}}</b></span> | 
    <a href  @click.prevent = 'showForm = true' v-show='!showForm'>Edit</a>
    <div v-show='showForm'>
      <section v-show='!isSaving'>
        <input v-model='dba'>

        <a class='btn' @click='save' v-show='!isSaving'>Save</a>
        
        <a href 
          @click.prevent='showForm = false' 
          class='right'>Close</a>
      </section>
      
      <center v-show='isSaving' class='grey-text'>
        <i class="fas fa-spinner fa-spin grey-text"></i> 
        Saving ...
      </center>


    </div>
  </div>
</template>
<script>
  export default {
    props: ['doingBusinessAs', 'businessName'],
    data() {
      return {
        showForm: false,
        isSaving: false,
        dba: this.doingBusinessAs,
        lastSavedDba: this.doingBusinessAs
      }
    },
    methods: {
      save() {
        this.isSaving = true
        var params = { profile: { doing_business_as: this.dba } }

        this.$http.patch('/api/v1/app/merchant/profile', params).then(response => {
          this.isSaving = false
          this.showForm = false
          this.lastSavedDba = this.dba
          this.notifySuccess(response.body.message)

        }, error => {
          Rollbar.error(error.body)
          this.isSaving = false
          this.showForm = false
          this.dba = this.lastSavedDba
          this.notifyError(error.body.error)
        })
      }
    }
  }
</script>
