<template>
  <div>
    <button class="btn" :disabled="processing" @click="create">
      <i v-show="!processing" class="fas fa-plus"></i> 
      <i v-show="processing" class="fas fa-spinner fa-spin grey-text"></i>
      Add Link
    </button>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        processing: false
      }
    },
    methods: {
      create() {
        this.processing = true
        this.$http.post("/api/v1/app/merchant/link_checks").then(response =>{
          this.processing = false
          Turbolinks.visit(window.location)
        }, error => {
          this.processing = false
          this.errorHandler(error)
        })
      }
    }

  }
</script>
<style>
</style>
