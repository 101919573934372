<template>
  <div class='mt-50'>
    <section v-show='emailId'>
      <center>
        <img src='/assets/logo_horiz_110px.png'>
      </center>
      <h3>Welcome to iWallet!</h3>

      <div class='btn-large' :disabled='!agreed || processing' @click='signup'>
        <span v-if='!processing'>
          Sign up
        </span>
        <span v-else>
          <i class="fas fa-spinner fa-spin"></i> 
          processing ...
        </span>
      </div><br>
      <div class='mt-10'>
        By signing up you agree to iWallet's 
        <a href='https://iwallet.com/terms-of-service' target='_blank'>Terms of service</a> 
        and  
        <a href='https://iwallet.com/privacy-policy' target='_blank'>Privacy policy</a>, 
        as well as our partner Dwolla's 
        <a href='https://www.dwolla.com/legal/tos/' target='_blank'>Terms of service</a> 
        and 
        <a href='https://www.dwolla.com/legal/privacy/' target='_blank'>Privacy Policy</a>
      </div>
      <p>
        <label>
          <input type="checkbox" v-model='agreed'/>
          <span>Agree</span>
        </label>
      </p>
      <small class='red-text' v-show='!agreed'>
        * Please agree with terms of service
      </small>
    </section>
  </div>
</template>
<script>
  export default {
    props: ['emailId'],
    created() {
      if(!this.emailId) {
        Turbolinks.visit('/client/sign_up')
      }
    },
    data() {
      return {
        agreed: false,
        processing: false,
        api_path: '/api/v1/app/verification/unverified_client/from_free_pays'
      }
    },
    methods: {
      signup() {
        this.processing = true
        this.$http.post(this.api_path, { email_id: this.emailId }).then(response => {
          Turbolinks.visit('/')
        }, error => {
          this.processing = false
          this.notifyError(error.body.message)
        })
      }
    }
  }
</script>
<style>
</style>
