<template>
  <div>
    <div class= "very-close-lines">
      Require secured checks:<br>
      
      <small class="grey-text">Will require a credit card on file for checks over a set limit</small>
    </div>
    <!-- Switch -->
    <div class="switch">
      <label>
        Off
        <input type="checkbox" @change='update' v-model='settings.active' :disabled="$apollo.loading">
        <span class="lever"></span>
        On
      </label>
    </div>
    <div class="grey-text mt-10">
      Threshold: {{settings.threshold | currency}} | 
      <a class="pointer" @click="showForm=!showForm">
        <i class="far fa-edit"></i> Edit
      </a>
    </div>
    <div v-show="showForm">
      <input
        v-model="settings.threshold" 
        step="0.01"
        type="number"
        pattern="\d*"
      />
      <button class="btn" @click="update">Update</button>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'
  export default {
    apollo: {
      settings: {
        query: gql`query {
          merchant { merchantUser {settings {forceSecuredCheck {active threshold}}}}
        }`,
        update: data => data.merchant.merchantUser.settings.forceSecuredCheck
      }
    },
    data() {
      return {
        settings: {},
        showForm: false
      }
    },
    methods: {
      update() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($active: Boolean!, $threshold: Float!) {
            updateForceSecuredCheck(input: {active: $active, threshold: $threshold}) {
              forceSecuredCheck {
                active
                threshold
              }
              errors {message}
            }
          }`,
          // Parameters
          variables: {
            active: this.settings.active,
            threshold: Number(this.settings.threshold)
          },
        }).then((response) => {
          if(response.data.updateForceSecuredCheck.forceSecuredCheck.errors) {
            this.notifyError(response.data.updateForceSecuredCheck.forceSecuredCheck.errors.message)
          } else {
            this.notifySuccess("Successfully updated!")
            this.showForm = false
          }
        }).catch((error) => {
          if(error.graphQLErrors.length > 0) {
            this.notifyError(error.graphQLErrors[0].message)
          } else {
            this.notifyError(error.message)
          }
        })
      }
    }
  }
</script>
