<template>
  <span>
    {{localDate}}
  </span>
</template>
<script>
  export default {
    // onlyDate is a boolean prop default false, date is a string prop
    props: {
      onlyDate: {
        type: Boolean,
        default: false
      },
      date: {
        type: String,
        required: true
      }
    },
    computed: {
      localDate() {
        var parsedDate = new Date(this.date)
        return parsedDate.toLocaleString("en-US", this.dateFormat)
      },
      dateFormat() {
        if (this.onlyDate) {
          return {
            month: "short", day: "2-digit"
          }
        }
        return {
          hour: "2-digit", minute: "2-digit", month: "short", day: "2-digit", hour: "numeric"
        }
      }
    }
  }
</script>
