<template>
  <div>
    <section class='large-font mt-25' v-if='showPhoneForm'>
      <center>
        <div class='big-font'>Please enter your phone to get {{subjectText}}</div>
      </center>
      <div class='mt-10'>
        <label>Phone</label>
        <input
          type='tel'
          placeholder='(373) 112-1122'
          v-model='phone'
          autofocus=true
          autocomplete="phone"
          v-validate="'required|numeric'"
          name='phone'>
          <span class='red-text small-font' v-show='errors.first("phone")'>{{errors.first("phone")}}</span>

        <button class='btn-large mt-25' @click='sendPhone'>
          Get {{subjectText}}
        </button>
      </div>
    </section>

    <section v-if='showVerificationForm' class='mt-25'>
      <center>
        <div class='large-font'>
          A text message with a 3-digit verification code was just sent to
          <b>{{formattedPhone}}</b>
        </div>
      </center>
      <div class='mt-10'>
        <label>Enter the code</label>
        <input
          type='number'
          placeholder='123'
          v-model='codeFromUser'
          autofocus=true
          v-validate="'required|numeric'"
          name='code'>
          <span class='red-text small-font' v-show='errors.first("code")'>{{errors.first("code")}}</span>

            <button class='btn-large mt-25' @click='verifyCode' style='width: 100%' >
              <i class="far fa-paper-plane"></i> 
              Send code
            </button>
            <br>

            <button class='btn mt-25 red' @click='doItLater' v-show='!already_skipped' style='margin-lef: 10px;'>
              do it later
           </button>
      </div>
    </section>

    <center v-show='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>

    <after-free-payment
      v-if='showAfterPayment'
      :merchant='merchant'
      :qid='qid'
      :amount='amount'
      :phoneId='phoneId'
      :authCode='authCode'/>

  </div>
</template>
<script>
  import afterFreePayment from './phone_after_free_payment'
  export default {
    components: { afterFreePayment },
    props: [ 'amount', 'merchant', 'qid', 'stuff_qid', 'subject', 'already_skipped', 'phoneIdFromCookie', 'phoneNumberFromCookie'],
    created() {
      if(this.phoneIdFromCookie) {
        this.showPhoneForm = false
        this.showPending = true
        this.sendPhone()
      }
    },
    data() {
      return {
        phone: this.phoneNumberFromCookie,
        formattedPhone: null,
        code: 111,
        codeFromUser: null,
        phoneId: this.phoneIdFromCookie,
        phoneState: null,
        showPhoneForm: true,
        showVerificationForm: false,
        showPending: false,
        showAfterPayment: false,
        authCode: null,
        attempts: 0
      }
    },
    methods: {
      sendPhone() {
        this.$http.post('/api/v1/vue/free/phones', this.phoneParams).then(response => {
          this.showVerificationForm   = true
          this.showPhoneForm          = false
          this.formattedPhone         = response.body.formatted_number
          this.code                   = response.body.code
          this.phoneId                = response.body.id
          this.phoneState             = response.body.state

          if(this.phoneState == 'verified') {
            this.codeFromUser = this.code
            this.verifyCode()
          }
          console.log(this.code)
        }, error => {
          this.showPhoneForm  = true
          this.showPending    = false
          this.notifyError(error.body.message)
          this.phone = this.phoneNumberFromCookie
        })
      },
      verifyCode() {
        if (this.code == this.codeFromUser) {
          this.notifySuccess('Code successfully verified!')
          this.showVerificationForm = false
          this.showPending = true
          this.$http.patch('/api/v1/vue/free/phones/' + this.phoneId, {state: 'verified'})
          this.$http.post('/api/v1/vue/free/phone_payments', this.params).then(response => {
            this.showPending = false
            this.showAfterPayment = true
            this.authCode = response.body.payment.data.attributes.auth_code
          }, error => {
            this.notifyError(error.body.error.base[0])
            this.submitDisabled = false
          })

        } else {
          this.codeFromUser = null
          this.attempts += 1

          if (this.attempts == 5) {
            this.phone = null
            this.formattedPhone = null
            this.code = null
            this.codeFromUser = null
            this.phoneId = null
            this.showPhoneForm = true
            this.showVerificationForm = false
            this.showPending = false
            this.showAfterPayment = false
            this.authCode = null
            this.attempts = 0
          }
          this.notifyError('Incorrect code!')
        }
      },
      doItLater() {
        this.showVerificationForm = false
        this.showPending = true
        this.$http.post('/api/v1/vue/free/phone_payments/skip_verification', this.params).then(response => {
          this.showPending = false
          this.showAfterPayment = true
          this.authCode = response.body.payment.data.attributes.auth_code
        }, error => {
          this.notifyError(error.body.error.base[0])
          this.submitDisabled = false
        })
      }
    },
    computed: {
      subjectText() {
        return this.subject.replace(/_/g, ' ')
      },
      phoneParams() {
        return {
          number: this.phone,
          stuff_qid: this.stuff_qid
        }
      },
      params() {
        return {
          amount: this.amount,
          qid:    this.qid,
          stuff_qid: this.stuff_qid,
          phone_id: this.phoneId
        }
      }
    }
  }
</script>
