<template>
  <div>
    <transition name="bounce">
      <div v-show='showForm'>
        <h3><b>Welcome to iWallet!</b></h3>

        <div class='large-font'>
          <label>First name</label>
          <input 
            v-model='firstName'
            autocomplete="first_name"
            v-validate="'required'"
            name='firstName'>
            <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("firstName")}}</span>

          <label>Last name</label>
          <input 
            v-model='lastName'
            autocomplete="last_name"
            v-validate="'required'"
            name='lastName'>
            <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("lastName")}}</span>

          <label>Email</label>
          <input 
            placeholder='email@example.com'
            v-model='email'
            autofocus=true
            autocomplete="email"
            v-validate="'required|email'"
            name='email'>
            <span class='red-text small-font' v-show='errors.first("email")'>{{errors.first("email")}}</span>

          <button class='btn-large mt-25' @click='send'>
            <i class="far fa-paper-plane"></i> 
            Get link to free $25
          </button>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-show='success'>
        <h3><b>Email Sent!</b></h3>
        <a class='btn-large' href='/r/277954' >Sign up and get $25 bonus</a>
      </div>
    </transition>
  </div>
</template>
<script>
  export default {
    params: ['authCode'],
    data() {
      return {
        showForm: true,
        success: false,
        email: null,
        firstName: null,
        lastName: null
      }
    },
    methods: {
      send() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.showForm = false
            this.$http.post('/api/v1/vue/free/emails', { email: this.email, first_name: this.firstName, last_name: this.lastName, auth_code: this.authCode }).then(response => {
              this.success = true
            })
          }
        })
          
      }
    }
  }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
