<template>
  <div>
    <div class='row'>
      <div class='col s5'>
        <select class='browser-default' v-model='monthSelected' name='month' v-validate="'required'">
          <option v-for='month in monthes' :value='month.value'>{{month.name}}</option>
        </select>
      </div>
      <div class='col s3'>
        <input v-model='day' placeholder='Day' v-validate="'required|numeric|min_value:1|max_value:31'" name='day' type='number'>
      </div>
      <div class='col s4'>
        <input v-model='year' placeholder='Year' v-validate="'required|digits:4|min_value:1920|max_value:2005'" name='year' type='number'>
      </div>
    </div>
    <div class='row'>
      <div class='red-text'>{{ errors.first('day') }}</div>
      <div class='red-text'>{{ errors.first('year') }}</div>
      <div class='red-text'>{{ errors.first('month') }}</div>
      <input :name='name' hidden :value='dobVal'>
    </div>
  </div>
</template>
<script>
  export default {
    created() {
      if(this.dob) {
        var splitted = this.dob.split('-')
        this.year = splitted[0]
        this.monthSelected = splitted[1]
        this.day = splitted[2]
      }
    },
    data() {
      return {
        day: '',
        year: '',
        monthSelected: '',
        monthes: [
          {name: 'January',     value: '01'},
          {name: 'February',    value: '02'},
          {name: 'March',       value: '03'},
          {name: 'April',       value: '04'},
          {name: 'May',         value: '05'},
          {name: 'June',        value: '06'},
          {name: 'July',        value: '07'},
          {name: 'August',      value: '08'},
          {name: 'September',   value: '09'},
          {name: 'October',     value: '10'},
          {name: 'November',    value: '11'},
          {name: 'December',    value: '12'}
        ]
      }
    },
    props: ['name', 'dob'],
    methods: {
    },
    computed: {
      dobVal() {
        return this.year + '-' + this.monthSelected + '-' + this.day
      }
    }
  }
</script>
<style>
</style>
