<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid', 'isMasterAccount', "showTipsSwitch"],
    apollo: {
      settings: {
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {pay {allowSmsSignaturePad showTips}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.pay
      }
    },
    data() {
      return {
        settings: {}
      }
    },
    methods: {
      switchTip() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($tipsState: Boolean!, $gid: ID) {
            updateTips(input: {showTips: $tipsState, userId: $gid}) {
              paySettings { allowSmsSignaturePad showTips}
              errors
            }
          }`,
          // Parameters
          variables: {
            tipsState: !this.settings.showTips,
            gid: this.gid
          },
        }).then(result => {
          if(result.data.updateTips.errors.length > 0) {
            this.notifyError(result.data.updateTips.errors[0])
            this.settings.showTips = !this.settings.showTips
          } else {
            this.settings.allowSmsSignaturePad = result.data.updateTips.paySettings.allowSmsSignaturePad
            this.settings.showTips = result.data.updateTips.paySettings.showTips
            this.notifySuccess("Pay settings successfully updated")
          }
        }, error => {
          this.gqlErrorHandler(error)
          this.settings.showTips = !this.settings.showTips
        })
      },
      switchSms() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($state: Boolean!, $gid: ID) {
            updateAllowSmsSignaturePad(input: {allowSmsSignaturePad: $state, userId: $gid}) {
              paySettings { allowSmsSignaturePad }
            }
          }`,
          // Parameters
          variables: {
            state: !this.settings.allowSmsSignaturePad,
            gid: this.gid
          },
        }).then(result => {
          this.settings.allowSmsSignaturePad = result.data.updateAllowSmsSignaturePad.paySettings.allowSmsSignaturePad
          this.notifySuccess("Pay settings successfully updated")
        }, error => {
          this.gqlErrorHandler(error)
          this.settings.allowSmsSignaturePad = !this.settings.allowSmsSignaturePad
        })
      }
    }
  }
</script>

<template>
  <div>
    <section v-if="showTipsSwitch">
      <div class= "very-close-lines">
        Activate tips:<br>
        
        <small class="grey-text">Allow payers to add tips</small>
      </div>
      <!-- Switch -->
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @click='switchTip' v-model='settings.showTips' :disabled="$apollo.loading">
          <span class="lever"></span>
          On
        </label>
      </div>
    </section>

    <section v-show="!$apollo.loading && settings.showTips && isMasterAccount" class="mt-25">
      <tip-values :gid="gid"></tip-values>
    </section>

    <section v-show="!$apollo.loading" class="mt-25">
      <div class= "very-close-lines">
        Activate remote signature capture:<br>
        
        <div>
          <small class="grey-text">Allows cardholders to sign on their own phone via SMS</small>
        </div>
      </div>
      <!-- Switch -->
      <div class="switch mt-5">
        <label>
          Off
          <input type="checkbox" @click='switchSms' v-model='settings.allowSmsSignaturePad'>
          <span class="lever"></span>
          On
        </label>
      </div>
    </section>
    <slot v-if="settings.allowSmsSignaturePad">
    </slot>
  </div>
</template>
