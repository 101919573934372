<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        fetchPolicy: "no-cache",
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {policyUrl policyText isPolicyUrlLocal}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings
      }
    },
    data() {
      return {
        settings: {},
        showForm: false
      }
    },
    methods: {
      update() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($url: String, $gid: ID) {
            updatePolicyUrl(input: {url: $url, userId: $gid}) {
              url
            }
          }`,
          // Parameters
          variables: {
            url: this.settings.policyUrl,
            gid: this.gid
          },
        }).then(result => {
          this.settings.policyUrl = result.data.updatePolicyUrl.url
          this.showForm = false
          this.notifySuccess("Policy url successfully updated")
        }, error => {
          this.gqlErrorHandler(error)
        })
      },
      updateByText() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($gid: ID, $text: String) {
            updatePolicyUrl(input: {userId: $gid, text: $text}) {
              url
              text
            }
          }`,
          // Parameters
          variables: {
            text: this.settings.policyText,
            gid: this.gid
          },
        }).then(result => {
          this.settings.policyUrl = result.data.updatePolicyUrl.url
          this.text = result.data.updatePolicyUrl.text
          this.showForm = false
          this.notifySuccess("Policy successfully updated")
        }, error => {
          this.gqlErrorHandler(error)
        })
      },
      deleteUrl() {
        this.settings.policyUrl = null
        this.update()
      },
      close() {
        this.$apollo.queries.settings.refetch()
        this.showForm = false
      }
    }
  }
</script>

<template>
  <div>
    <section class="row">
      <div class="mt-25 grey-text">
        <div v-if="$apollo.loading">
          <i class="fas fa-spinner fa-spin grey-text"></i> 
          Loading...
        </div>

        <div v-else>
          <a :href="settings.policyUrl" target="_blank" >Policy link</a>

          <span v-show="!settings.policyUrl">(not set)</span>

          <span v-if="!showForm">
            <a class="pointer" @click="showForm = true">
              <i class="far fa-edit"></i> Edit
            </a>
          </span>
        </div>

        <div class="col s12" v-show="showForm">
          <div class="switch">
            <label>
              By url
              <input type="checkbox" v-model="settings.isPolicyUrlLocal">
              <span class="lever"></span>
              By text
            </label>
          </div>
          <section v-if="settings.isPolicyUrlLocal" class="mt-10">
            <textarea v-model="settings.policyText" placeholder="Policy text..."></textarea>
            <button class="btn" @click="updateByText">Save</button>
            <button @click="deleteUrl" class="right btn-flat">Delete</button>
          </section>

          <section v-else class="mt-10">
            <input v-model="settings.policyUrl" placeholder="Policy url http://..." />
            <button class="btn" @click="update">Save</button>
            <button @click="deleteUrl" class="right btn-flat">Delete</button>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
  textarea {
    height: 400px;
  }
</style>
