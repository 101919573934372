<template>
  <div>
    <div>
      <img :src="src" class="fit-to-screen" :style="imageStyle" ref="image" @mousedown="startDrag" />
    </div>

    <div @click="rotateClockwise" class="btn">Rotate Clockwise</div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      rotationDegrees: 0,
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      imagePositionX: 0,
      imagePositionY: 0
    };
  },
  computed: {
    imageStyle() {
      return {
        transform: `rotate(${this.rotationDegrees}deg) translate(${this.imagePositionX}px, ${this.imagePositionY}px)`
      };
    }
  },
  methods: {
    rotateClockwise() {
      this.rotationDegrees += 90;
    },
    startDrag(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      document.addEventListener('mousemove', this.dragImage);
      document.addEventListener('mouseup', this.stopDrag);
    },
    dragImage(event) {
      if (this.isDragging) {
        const deltaX = event.clientX - this.dragStartX;
        const deltaY = event.clientY - this.dragStartY;

        const angleInRadians = (this.rotationDegrees * Math.PI) / 180;
        const rotatedDeltaX = deltaX * Math.cos(angleInRadians) + deltaY * Math.sin(angleInRadians);
        const rotatedDeltaY = -deltaX * Math.sin(angleInRadians) + deltaY * Math.cos(angleInRadians);

        this.imagePositionX += rotatedDeltaX;
        this.imagePositionY += rotatedDeltaY;
        this.dragStartX = event.clientX;
        this.dragStartY = event.clientY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.dragImage);
      document.removeEventListener('mouseup', this.stopDrag);
    }
  }
};
</script>
<style>
  .fit-to-screen {
    max-width: 97%;
    height: auto;
  }
</style>
