<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {pay {allowSubaccountAllCofAccess}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.pay
      }
    },
    data() {
      return {
        settings: {}
      }
    },
    methods: {
      change() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($state: Boolean!, $gid: ID) {
            updateSubaccountAllCofAccess(input: {allowSubaccountAllCofAccess: $state, userId: $gid}) {
              paySettings { allowSubaccountAllCofAccess }
              errors
            }
          }`,
          // Parameters
          variables: {
            state: !this.settings.allowSubaccountAllCofAccess,
            gid: this.gid
          },
        }).then(result => {
          if(result.data.updateSubaccountAllCofAccess.errors.length > 0) {
            this.notifyError(result.data.updateSubaccountAllCofAccess.errors[0])
            this.settings.allowSubaccountAllCofAccess = !this.settings.allowSubaccountAllCofAccess
          } else {
            this.settings.allowSubaccountAllCofAccess = result.data.updateSubaccountAllCofAccess.paySettings.allowSubaccountAllCofAccess
            this.notifySuccess("Sub-accounts setting successfully updated")
          }
        }, error => {
          this.gqlErrorHandler(error)
          this.settings.allowSubaccountAllCofAccess = !this.settings.allowSubaccountAllCofAccess
        })
      }
    }
  }
</script>

<template>
  <div>
    <section>
      <div class= "very-close-lines">
        Sub-accs access to cards on file:<br>
        
        <small class="grey-text">Allows app-wide access</small>
      </div>
      <!-- Switch -->
      <div class="switch">
        <label>
          Disabled
          <input type="checkbox" @click='change' v-model='settings.allowSubaccountAllCofAccess' :disabled="$apollo.loading">
          <span class="lever"></span>
          Enabled
        </label>
      </div>
    </section>
  </div>
</template>
