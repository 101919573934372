<template>
  <div>
    <section class="main-form" v-show="!showItemForm">
      <div class="row" v-if="items.length == 0">
        <div class="input-field col s12 m6">
          <i class="fas fa-dollar-sign prefix grey-text"></i>
          <input 
            name="amount"
            id="amount"
            @input="updateAmounts"
            autofocus
            v-model='amount'
            step="0.01"
            type="text" 
            inputmode="decimal"
            v-validate="'min_value:0|required'"
            class='inputText'
            lang="en-001">
            <span class='red-text small-font' v-show='errors.first("amount")'>{{errors.first("amount")}}</span>

          <label for="amount">Amount</label>
        </div>
        <div class="input-field col s12 m6">
          <i class="fas fa-sticky-note prefix grey-text"></i>
          <input name="note" id="note" type="text" v-model="note" ref="note">

          <label for="note">Note (Optional)</label>
        </div>

        <div class="input-field col s12 m6">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input name="note" id="invoice" type="text" v-model="invoice" ref="invoice">

          <label for="invoice">{{invoiceLabel}}</label>
        </div>

        <div class="input-field col s12 m6" v-if="requireInvoiceConfirmation">
          <i class="fas fa-file-invoice prefix grey-text"></i>
          <input name="invoice-cfm" id="invoice-cfm" type="text" v-validate="{required: invoice.length > 0, confirmed: 'invoice'}">

          <label for="invoice-cfm">Invoice (confirmation)</label>
          <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
        </div>
      </div>

      <div v-show="items.length > 0">
        <div class="row">
          <div class="col s12 m6">
            <span class="items-amount bold-font emerald">{{amount | currency}}</span>
          </div>
          <div class="col s12 m6">
            <show-items :items="items" :items-updated="itemsUpdated"></show-items>
          </div>
        </div>
      </div>

      <card-element v-model="cardToken"></card-element>

      <button class="btn btn-small outlined-btn" @click="showItemForm=true" v-show='!showPending'>
        <i class="fas fa-plus"></i>

        Add Item
      </button>

      <div class="mt-10">
        <span class='red-text'>{{errorMessage}}</span><br>
        <span class='red-text'>{{processingErrorMessage}}</span><br>
      </div>

      <center>
        <section class='mt-10' v-if='showTips'>
          <tips :qid="qid" :tips-updated="tipsUpdated"></tips>
        </section>
      </center>

      <button @click="validateAndProcess(checkDuplicate)" v-show="!showPending" class="btn-large wide-btn mt-25" :disabled="disableSignupBtn || !phoneIsValid || !submittable">
        charge<span v-show='amount'> for {{withTipAmount}}</span>
      </button>

      <div class="center grey-text mt-5" v-if="feeAmount > 0">
        Gross amount: <b>{{grossAmount | currency}}</b> |  
        Non-cash adj: <b>{{feeAmount | currency}}</b>
      </div>
    </section>
    <center v-show='showPending' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>

    <items
      v-if="showItemForm"
      :items="items"
      :items-updated="itemsUpdated"
      :set-invoice="invoice"
      :set-note="note"
      :set-amount="items.length > 0 ? null : amount"
      :invoice-label="invoiceLabel"
      :require-invoice-confirmation="requireInvoiceConfirmation"
      @close="showItemForm=false">

    </items>
  </div>
</template>
<script>
  import gql from 'graphql-tag'
  import cardElement from './card_element'
  import items from './items'
  import showItems from './show_items'
  import tips from "../../tips"

  export default {
    props: ['showTips', 'qid', 'requireInvoiceField', 'requireInvoiceConfirmation', 'setAmount', 'setPhone', 'setNote', 'setInvoice', 'surchargeEligible'],
    components: {cardElement, tips, items, showItems},
    data() {
      return {
        amount: this.setAmount,
        grossAmount: 0,
        feeAmount: 0,
        withTipAmount: 0,
        phone: this.setPhone,
        errorMessage: null,
        processingErrorMessage: null,
        disableSignupBtn: false,
        showPending: false,
        phoneIsValid: true,
        transactionAmount: null,
        selectedTip: 0,
        tipCustomAmount: 0,
        note: this.setNote,
        invoice: this.setInvoice,
        hideZip: false,
        cardToken: null,
        items: [],
        showItemForm: false,
        lastGrossCalcRequestId: 0,
        performCheckDuplicate: true
      }
    },
    methods: {
    validateAndProcess(func) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          func()
        } else {
          this.showPending = false
          this.notifyError("Please complete all required fields!")
        }
      })
    },
    checkDuplicate() {
      this.showPending = true

      if (!this.performCheckDuplicate) {
        this.validateAndProcess(this.charge)
        return
      }

      this.$apollo.query({
        // Query
        query: gql`query($amount: Float!, $invoice: String, $flow: PaymentFlows!) {
          merchant {
            checkDuplicate(amount: $amount, invoice: $invoice, flow: $flow) {
              duplicate
              messages
            }
          }
        }`,
        // Parameters
        variables: {
          amount: parseFloat(this.amount),
          invoice: this.invoice,
          flow: "card"
        },
      }).then(result => {
        this.showPending = false
        if (result.data.merchant.checkDuplicate.duplicate) {
          this.errorMessage = result.data.merchant.checkDuplicate.messages[0]
          this.performCheckDuplicate = false
        } else {
          this.validateAndProcess(this.charge)
        }
      }, error => {
        this.showPending = false
        this.gqlErrorHandler(error)
      })
    },
    charge() {
        this.processingErrorMessage = null
        this.errorMessage = null
        this.showPending = true

        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($amount: Float!, $tipPercent: Int, $tipAmount: Float, $phone: String, $note: String, $invoice: String, $token: String!, $items: [ItemAttributes!]) {
            chargeCardDirect(input: {amount: $amount, tipPercent: $tipPercent, tipAmount: $tipAmount, phone: $phone, note: $note, invoice: $invoice, token: $token, items: $items}) {
              transaction { deprecatedId authCode }
              errors
            }
          }`,
          // Parameters
          variables: {
            amount: parseFloat(this.amount),
            tipPercent: parseInt(this.selectedTip),
            tipAmount: parseFloat(this.tipCustomAmount),
            note: this.note,
            invoice: this.invoice,
            phone: this.phone,
            token: this.cardToken,
            items: this.items
          },
        }).then(result => {
          if (result.data.chargeCardDirect.errors.length > 0) {
            this.showPending = false
            this.errorMessage = result.data.chargeCardDirect.errors[0]
            this.notifyError(result.data.chargeCardDirect.errors[0])
          } else {
            window.location.href = '/client/ach_transactions/' + result.data.chargeCardDirect.transaction.deprecatedId
          }
        }, error => {
          this.showPending = false
          this.gqlErrorHandler(error)
        })
      },
      phoneValidate(result) {
        this.phoneIsValid = result.isValid

        if(result.isValid) {
          this.card.focus()
        }
      },
      tipsUpdated(selectedTip, tipCustomAmount) {
        this.selectedTip = selectedTip
        this.tipCustomAmount = tipCustomAmount
        this.updateAmounts()
      },
      updateAmounts() {
        this.performCheckDuplicate = true
        // For the frequent updates, we want to make sure we only use the latest request
        // because the requests are async and can come back in any order
        this.lastGrossCalcRequestId++;
        const currentRequestId = this.lastGrossCalcRequestId;

        this.$http.get("/api/v1/vue/gross_calculations", {params: this.paramsGrossCalc}).then(response => {
          if (currentRequestId === this.lastGrossCalcRequestId) {
            this.grossAmount = response.body.gross_amount
            this.feeAmount = response.body.fee
            this.withTipAmount = response.body.amount_with_tip
            this.errorMessage = null
          }
        })
      },
      itemsUpdated(amount) {
        this.amount = amount
        this.invoice = ""
        this.note = ""
        this.updateAmounts()
      }
    },
    computed: {
      paramsGrossCalc() {
        return {
          amount: this.amount,
          tip_percent: this.selectedTip,
          tip_custom_amount: this.tipCustomAmount,
          qid: this.qid
        }
      },
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      },
      submittable() {
        return (this.amount >= 0 && this.cardToken)
      }
    }
  }
</script>
<style scoped>
  .customPhoneInput {
    border: none !important;
  }
  .activeTip {
    background-color: #fff;
    border: 2px solid  #00ab8e;
    color: #00ab8e;
  }
  .items-amount {
    font-size: 3em !important;
  }
</style>
