import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "amount" ]

  connected() {
  }
  updated(event) {
    this.amountTarget.innerHTML = 'Add $' + event.target.value + '.00'
  }
}
