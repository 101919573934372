<template>
  <div>
    <section class='mt-25'>
      <center>
        <div class='large-font'>
          A text message with a 4-digit verification code was just sent to<br>
          <b>{{email}}</b>
        </div>
      </center>
      <div class='mt-10'>
        <label>Enter the code</label>
        <input
          type='number'
          placeholder='1234'
          v-model='codeFromUser'
          autofocus=true
          v-validate="'required|numeric'"
          name='code'>
          <span class='red-text small-font' v-show='errors.first("code")'>{{errors.first("code")}}</span>

          <button class='btn-large mt-25' @click='verifyCode' style='width: 100%' >
            <i class="far fa-paper-plane"></i> 
            Verify code
          </button>
      </div>
    </section>
  </div>
</template>
<script>
  export default {
    props: ['email', 'profileId', 'code'],
    data() {
      return {
        phone: this.phoneNumber,
        codeFromUser: null
      }
    },
    methods: {
      sendPhone() {
        this.$http.post('/api/v1/app/verification_phones', this.phoneParams).then(response => {
          this.showPhoneForm          = false
          this.formattedPhone         = response.body.formatted_number
          this.profileId              = response.body.profile_id

          console.log(this.code)
        }, error => {
          this.showPhoneForm  = true
          this.notifyError(error.body.error)
        })
      },
      verifyCode() {
        this.$http.post('/api/v1/app/verification_emails', { code: this.codeFromUser }).then(response => {
          this.notifySuccess(response.body.message)
          Turbolinks.visit('/')
          
        }, error => {
          this.codeFromUser = null
          this.notifyError(error.body.error)
        })
      },
      requestNewCode() {
        this.codeFromUser = null
        this.showPhoneForm = true
      }
    },
    computed: {
      phoneParams() {
        return {
          number: this.phone
        }
      },
    }
  }
</script>

