<template>
  <div>
    <div class='row'>
      <div class='col s12 m3'>
        <div class='input-filed'>
          <label>Amount, USD</label>
          <select v-model='selectedValue'>
            <option v-for='amount in amounts' :value='amount[1]'>{{amount[0]}}</option>
          </select>
        </div>
      </div>
    </div>
    <button class='btn' @click='submitForm' :disabled='buttonDisabled'>
      <span v-show='!buttonDisabled'>
        Add ${{selectedValue}}.00
      </span>

      <span v-show='buttonDisabled'>
        <i class="fas fa-spinner fa-spin grey-text"></i> Processing ... 
      </span>
    </button>
  </div>
</template>
<script>
  export default {
    props: ['userId', 'stepper'],
    data() {
      return {
        amounts: [['$1.00', 1],['$2.00', 2],['$5.00', 5], ['$10.00', 10], ['$50.00', 50], ['$100.00', 100], ['$250.00', 250], ['$500.00', 500]],
        selectedValue: 10,
        buttonDisabled: false,
        tokensPayload: null
      }
    },
    methods: {
      submitForm() {
        this.buttonDisabled = true

        this.$http.post('/api/v1/app/add_money', this.payload).then(response => {
          if (this.stepper && this.$webkit) {
            this.getRefreshTokeniOS()
          } else if (this.stepper && this.$android) {
            this.getRefreshTokenAndroid()
          } else {
            Turbolinks.visit('/')
          }
        }, error => {
          this.buttonDisabled = false
          if (error.status == "422") {
            this.notifyError(error.body.error_messages[0])
          } else {
            this.notifyError('Unable to load balance')
            Rollbar.error(error.body)
          }
        })
      },
      getRefreshTokeniOS() {
        this.$http.get('/api/v1/vue/refresh_token').then(response => {
          this.tokensPayload = response.body
          this.tokensPayload.type = 'Users::Client'
          this.$webkit.messageHandlers.finishClientSignup.postMessage(this.tokensPayload)
        }, error => {
          Rollbar.error(error.body)
        })
      },
      getRefreshTokenAndroid() {
        this.$http.get('/api/v1/vue/refresh_token').then(response => {
          this.tokensPayload = response.body
          this.tokensPayload.type = 'Users::Client'
          this.$android.finishClientSignup(JSON.stringify(this.tokensPayload))
        }, error => {
          Rollbar.error(error.body)
        })
      }
    },
    computed: {
      payload() {
        return { ach_load_balance: { amount: this.selectedValue } }
      }
    }
  }
</script>
