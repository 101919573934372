<template>
  <div>
    <div class="row">
      <span :class='cssClass("receiveMoneyQr")' @click='currentTab="receiveMoneyQr"'>Receive Money</span>
      <span :class='cssClass("paymentQr")' @click='currentTab="paymentQr"'>Pay to merchant</span>

      <component v-bind:is="currentTab" :qid="qid" :user-id="userId"></component>
    </div>
  </div>
</template>
<script>
  import myQrs from 'components/client/my_qrs'
  import paymentQr from 'components/client/payment_qr'
  import receiveMoneyQr from 'components/client/receive_money_qr'

  export default {
    components: {myQrs, receiveMoneyQr, paymentQr},
    props: ['userId', 'qid'],
    data() {
      return {
        currentTab: 'receiveMoneyQr'
      }
    },
    methods: {
      cssClass(val) {
        if (this.currentTab == val) {
          return 'btn'
        } else {
          return 'btn-flat'
        }
      }
    }
  }
</script>
