<template>
  <div class='mt-25'>
    <center v-show="showSuccess">
      <h3>
        <i class="fas fa-check-circle fa-2x emerald"></i><br>
        <div class='mt-25'>QR Successfully assigned</div>
        <a href="/" class='btn-large mt-25'>Ok</a>
      </h3>
    </center>
    <section v-show="!showScanner && !showSuccess">
      Assigning QR: <b>{{QR}}</b><br>
      Verification code sent to administrator.<br>
      <div class='row mt-25'>
        <div class='col s6 m3'>
          <label>Verification code</label>
          <input v-model="code" type="number" autofocus>
        </div>
      </div>
      <button class='btn-large pay-btn' :disabled='!code && !disableSubmit' @click='submitCode'>Submit</button>
    </section>


    <re-assign-qr-scanner v-show='showScanner' v-model='QR' @change='gotQR'></re-assign-qr-scanner>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        QR: null,
        showScanner: true,
        code: null,
        disableSubmit: false,
        showSuccess: false
      }
    },
    methods: {
      gotQR() {
        this.$http.post("/api/v1/app/merchant/assign_qr/otps").then(response => {
          this.showScanner = false
        })
      },
      submitCode() {
        this.disableSubmit = true
        this.$http.post("/api/v1/app/merchant/assign_qr/qrs", {code: this.code, qid: this.QR} ).then(response => {
          this.showSuccess = true
        }, error => {
          this.errorHandler(error)
        })
      }
    },
    watch: {
      QR: {
        handler() {
          this.gotQR()
        }
      }
    }
  }
</script>
<style>
</style>
