<template>
  <div>
    <!-- Modal Trigger -->
    <a class="waves-effect waves-light btn modal-trigger" href="#modal_void">Void</a>

    <!-- Modal Structure -->
    <div id="modal_void" class="modal">
      <div class="modal-content">
        <div class="center">
          <i class="fas fa-exclamation-triangle fa-3x grey-text"></i>
          <h3 class="bold-font emerald">Voiding a transaction is irreversible</h3>
          <p class="grey-text">Please be aware that once a transaction is voided, it cannot be undone. Make sure you have reviewed the details carefully before proceeding with the void action.</p>
        </div>
        <label>Note:</label>
        <input v-model="note">
      </div>
      <div class="modal-footer">
        <button class="btn" @click="voidCheck" :disabled="disabled">
          <span v-if="disabled">
              <i class="fas fa-spinner fa-spin grey-text"></i> 
              Voiding...
          </span>
          <span v-else>
            Void
          </span>
        </button>
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Cancel</a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["id"],
    data() {
      return {
        disabled: false,
        note: null
      }
    },
    methods: {
      voidCheck() {
        this.disabled = true
        this.$http.post("/api/v1/app/merchant/paya/direct_check21/" + this.id + "/void", {note: this.note}).then(response =>{
          this.notifySuccess("Successfully voided")
          Turbolinks.visit(window.location)
        }, error => {
          this.disabled = false
          this.errorHandler(error)
        })
      }
    }
  }
</script>
<styles>
</styles>
