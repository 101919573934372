<template>
  <div class="card-panel">
    <center class="emerald">
      Receipt
    </center>

    <div class="row">
      <div class="col s9">
        <input v-model="contact" placeholder="Phone / email">
      </div>
      <div class="col s3">
        <button class='btn' @click='send' :disabled="disabled">
          <i class="far fa-paper-plane"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'
  export default {
   props: ["transactionId"],
   data() {
     return {
       contact: null,
       disabled: false
     }
   },
   methods: {
      send() {
        this.disabled = true
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($transactionId: ID!, $contact: String!) {
            sendReceipt(input: {transactionId: $transactionId, contact: $contact}) {
              message
              errors
            }
          }`,
          // Parameters
          variables: {
            contact: this.contact || "",
            transactionId: this.transactionId
          },
        }).then(result => {
          if(result.data.sendReceipt.message) {
            this.notifySuccess(result.data.sendReceipt.message)
          } else {
            this.notifyError(result.data.sendReceipt.errors[0])
          }
          this.disabled = false
        }, error => {
          this.gqlErrorHandler(error)
          this.disabled = false
        })
      },
   },
   computed: {
     params() {
       return {
         phone: this.phone,
         transaction_id: this.transactionId
       }
     }
   }
 }
</script>
<style>
</style>
