<template>
  <div>
    <span v-show='showButton' class='btn-large' @click='setupAccount'>
      </i> Connect bank account
    </span>
    <section v-show='showPendingSetup'>
      <i class="fas fa-spinner fa-spin grey-text"></i> 
      Setting up account. Please wait
    </section>

    <section v-show='errorMessage && !showPendingSetup' class='red-text'>
      Unable setup account: <br>
      <b>
        {{errorMessage}}
      </b>
    </section>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        showPendingSetup: false,
        apiPath: '/api/v1/app/verification/unverified_client/dwolla_customers',
        errorMessage: null,
        showButton: true
      }
    },
    methods: {
      setupAccount() {
        this.showPendingSetup = true
        this.showButton = false
        this.$http.post(this.apiPath).then(response => {
          window.location.href = '/bank_institutions'
        }, error => {
          this.notifyError(error.body.error)
          this.showPendingSetup = false
          this.showButton = true
          this.errorMessage = error.body.error
        })
      }
    }
  }
</script>

