<template>
  <div>
    <input v-model="image" :name="name" type="hidden">
    <image-uploader
        :debug="1"
        :maxWidth="800"
        :quality="0.7"
        :autoRotate=true
        outputFormat="string"
        :preview=false
        :className="['file-upload', { 'fileinput--loaded' : hasImage }]"
        :capture="false"
        accept="image/*"
        doNotResize="['gif', 'svg']"
        @input="setImage"
        onUpload="startImageResize"
        onComplete="endImageResize"
      >


      <label for="fileInput" slot="upload-label">
        <div class="btn-small" :disabled="disabled">
          <span v-if="disabled">
            <i class="fas fa-spinner fa-spin grey-text"></i> 
            <span>Processing...</span>
          </span>
          <span v-else>
            <i class="fas fa-camera"></i> 
            <span class="upload-caption">{{ hasImage ? 'Replace' : 'Check' }}</span>
          </span>
        </div>
      </label>
    </image-uploader>
    <img :src="image" width="100%" class="mt-25" v-if="image">

  </div>
</template>
<script>
  export default {
    props: ["name"],
    data() {
      return {
        hasImage: false,
        disabled: false,
        image: null
      }
    },
    methods: {
      setImage(image) {
        this.hasImage = true
        this.image = image
      }
    }
  }
</script>
<style>
  
</style>
