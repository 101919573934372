<template>
  <div>
    <br>
    <div class="input-field col s12 m4">
      <i class="prefix grey-text" :class="cardBrand"></i>
      <input id="number" type="text" inputmode="numeric" v-mask="cardNumberMask" name="number" v-model="number" @input="jumpOn(cardNumberLength, number, $refs.monthInput)" v-validate="'required|min:15'">
      <div class="red-text text-darken-3" v-show="invalidNumber">Card number is invalid</div>

      <label for="number">Card number</label>
    </div>
    <div class="input-field col s3 m2">
      <input id="month" ref="monthInput" name="month" type="number" v-model="expMonth" @input="jumpOn(2, expMonth, $refs.yearInput)" v-validate="'required|min:1|max:2|max_value:12'">

      <label for="month">Exp MM</label>
    </div>
    <div class="input-field col s3 m2">
      <input id="year" ref="yearInput" name="year" type="number" v-model="expYear" @input="jumpOn(2, expYear, $refs.cvvInput)" v-validate="'required|min:2|max:4'">

      <label for="year">Exp YY</label>
    </div>
    <div class="input-field col s3 m2">
      <input id="cvv" ref="cvvInput" name="cvv" type="number" v-model="verificationNumber" @input="jumpOn(cvvCalculatedLength, verificationNumber, $refs.zipInput)" v-validate="'required|min:3'">

      <label for="cvv">CVV</label>
    </div>

    <div class="input-field col s3 m2">
      <input id="addressZip" name="zip" ref="zipInput" type="text" v-model="addressZip" @keyup="apiSubmit" v-mask="zipMask" v-validate="'required|length:' + zipLength">

      <label for="addressZip">{{zipLabel}}</label>
    </div>
  </div>
</template>
<script>
  export  default {
    props: ["submit", "resetToken", "presetAddressZip"],
    data() {
      return {
        number: null,
        expMonth: null,
        expYear: null,
        verificationNumber: null,
        addressZip: this.presetAddressZip || ""
      }
    },
    methods: {
      jumpOn(length, value, element) {
        this.apiSubmit()
        if (value && value.length >= length) {
          element.focus()
        }
      },
      apiSubmit() {
        this.$nextTick(() => {
          this.$validator.validateAll().then((result) => {
            if (result) {
              this.submit(this.cardJSON)
            }
          })
        })
      }
    },
    watch: { 
      cardJSON: function() {
        this.resetToken()
      }
    },
    computed: {
      isPartialCanadianZip() {
        return /^[A-Za-z]/.test(this.addressZip);
      },
      isPartialUSZip() {
        return /^[0-9]{1,5}(-[0-9]{1,4})?$/.test(this.addressZip);
      },
      zipLength() {
        if(this.isPartialCanadianZip) {
          return 7
        } else if(this.isPartialUSZip) {
          return 5
        } else {
          return 1000
        }
      },
      zipLabel() {
        if (this.isPartialCanadianZip) {
          return "Zip (CAN)";
        } else if (this.isPartialUSZip) {
          return "Zip (US)";
        } else {
          return "Zip";
        }
      },
      zipMask() {
        if (this.isPartialCanadianZip) {
          return "A#A #A#";
        } else if (this.isPartialUSZip) {
          return "#####";
        } else {
          return "XXXXXXXXXXXXXXXXX";
        }
      },
      cardBrand() {
        if (this.number) {
          switch(this.number[0]) {
            case "2":
              return "fab fa-cc-mastercard"
            case "3":
              return "fab fa-cc-amex"
            case "4":
              return "fab fa-cc-visa"
            case "5":
              return "fab fa-cc-mastercard"
            case "6":
              return "fab fa-cc-discover"
            default:
              return "fas fa-credit-card"
          }
        } else {
          return "fas fa-credit-card"
        }
      },
      // Check if card number not null
      rawCardNumber() {
        if (this.number) {
          return this.number.replace(/\s/g, '')
        } else {
          return null
        }
      },
      cardNumberLength() {
        return (this.amexCard ? 17 : 19)
      },
      cvvCalculatedLength() {
        // For AMEX card we set CVV length 4
        return (this.amexCard ? 4 : 3)
      },
      cardNumberMask() {
        return (this.amexCard ? "#### ###### #####" : "#### #### #### ####")
      },
      amexCard() {
        if (this.number && this.number[0] == "3") { return true } else { return false }
      },
      cardJSON() {
        return JSON.stringify(
          {
            number: this.rawCardNumber,
            exp_year: this.expYear,
            exp_month: this.expMonth,
            verification_number: this.verificationNumber,
            address_zip: this.addressZip
          }
        )
      },
      invalidNumber() {
        if(this.number && this.rawCardNumber.length > 14) {
          let sum = 0;
          let numDigits = this.rawCardNumber.length;
          let parity = numDigits % 2;
          
          for (let i = 0; i < numDigits; i++) {
            let digit = parseInt(this.rawCardNumber.charAt(i));
            if (i % 2 == parity) {
              digit *= 2;
              if (digit > 9) {
                digit -= 9;
              }
            }
            sum += digit;
          }
    
          return (sum % 10) != 0;
        } else {
          return false
        }
      }
    }
  }
</script>
<style>
</style>
