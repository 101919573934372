<template>
  <div>
    <h3>Pay ${{attributes.amount_for_pay}} as a bill split</h3>
    You are going to split the bill of ${{attributes.amount}} with {{attributes.qty}} participants
    <div class='mt-50'>
      <button 
        class='btn-large'
        :disabled='btnDisable'
        @click='pay'>
        
        Pay ${{attributes.amount_for_pay}}
      </button>
    </div>
  </div>
</template>
<script>
  export default {
    created() {
      this.getData()
    },
    props: ['billId'],
    data() {
      return {
        attributes: {},
        btnDisable: false
      }
    },
    methods: {
      getData() {
        this.$http.get(this.apiPath + '/new').then(response => {
          this.attributes = response.body.data.attributes
        })
      },
      pay() {
        this.btnDisable = true
        this.$http.post(this.apiPath).then(response => {
          Turbolinks.visit('/')
        }, error => {
          this.btnDisable = false
          this.notifyError(Object.values(error.body.error)[0])
        })
      }
    },
    computed: {
      apiPath() {
        return '/api/v1/app/client/bill_split/' + this.billId + '/bill_split_pays'
      }
    }
  }
</script>
<style>
</style>
