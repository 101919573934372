<template>
  <div>
    <center class='grey-text text-darken-3' style="font-size: 1.2em">
      {{pleaseSignHere}}
    </center>

    <div class="card grey lighten-2">
      <vue-signature-pad width="100%" 
                         height="21vh" 
                         :options="{ onBegin, onEnd }"
                         ref="signaturePad" />
    </div>
    <span class='grey-text'>
      <span v-if="policyUrl.length > 3">
        {{$t("sendBill.agreePayAboveAmountFirst")}} <a :href="policyUrl" target="_blank">{{$t("sendBill.agreePayAboveAmountUrl")}}</a> {{$t("sendBill.agreePayAboveAmountLast")}}
      </span>
      <span v-else>
        {{$t("sendBill.agreePayAboveAmount")}}
      </span>
    </span>
  </div>
</template>
<script>
  import { useCardsBillStore } from "./store"
  import { toRefs } from '@vue/composition-api'

  export default {
    setup: () => ({ ...toRefs(useCardsBillStore()) }),
    name: 'SignaturePad',
    props: {
      policyUrl: {type: String, default: ''}
    },
    data() {
      return {
        timer: null
      }
    },
    methods: {
      onBegin() {
        this.$emit('onBegin')
        if (this.timer) {
          clearTimeout(this.timer); // clear the timeout if it exists
        }
      },
      onEnd() {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        this.signatureImage = data

        if(this.allowSignAutosubmit) {
          this.timer = setTimeout(() => {
            this.$bus.$emit('submitOnSignatureFinished');
          }, 2000);
        } else {
          console.log("Not allowed", this.allowSignAutosubmit)
        }
      }
    },
    computed: {
      pleaseSignHere() {
        if (this.allowSignAutosubmit) {
          return this.$t('sendBill.pleaseSignHereAutoSubmit')
        } else {
          return this.$t('sendBill.pleaseSignHere')
        }
      }
    }
  }
</script>
