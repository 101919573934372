<template>
  <div>
    <form name="myform" :action="pinPadPath" method="POST" ref='form'>
      <input type="hidden" name="AccuLanguage" value=“en-US”>
      <input type="hidden" name="AccuId" v-model="AccuId">
      <input type="hidden" name="tranId" v-model="tranId">
      <input type="hidden" name="tempCardId" v-model='tempCardId'>
      <input type="hidden" name="AccuReturnURL" v-model="returnUrl">
      <input type="hidden" name="redirectUrl" v-model="redirectUrl">
      <span class='btn-small mt-50' :disabled="submitDisabled" @click="checkBalance" v-show="!autoSubmit">
        <i v-show='submitDisabled' class="fas fa-spinner fa-spin grey-text"></i> 
        <span v-show='!submitDisabled'>Balance</span>
      </span>
    </form>
  </div>
</template>
<script>
  export default {
    props: ['pinPadPath', 'returnUrl', 'cardId', 'autoSubmit', 'redirectUrl'],
    data() {
      return {
        AccuId: null,
        tranId: null,
        submitDisabled: false,
        tempCardId: null
      }
    },
    created() {
      if(this.autoSubmit) {
        this.checkBalance()
      }
    },
    methods: {
      checkBalance() {
        this.submitDisabled = true
        this.getTempCardId()
      },
      getAccuId() {
        this.$http.get('/api/v1/app/ebt/accu_ids/' + this.cardId).then(response => {
          this.AccuId = response.body.accu_id
          this.tranId = response.body.tran_id
          setTimeout(() => this.$refs.form.submit(), 300);
        }, error => {
          this.submitDisabled = false
          if(error.status == 422) {
            this.notifyError(error.body.error_messages[0])
          } else {
            this.notifyError('Something went wrong')
          }
        })
      },
      getTempCardId() {
        this.$http.post('/api/v1/app/temp_ids', {id: this.cardId}).then(response => {
          this.tempCardId = response.body.temp_id
          this.getAccuId()
        }, error => {
          this.submitDisabled = false
          this.notifyError('Something went wrong. Unable to create temp ID')
        })
      }
    }
  }
</script>
<style>
</style>
