export default {
  howDidWeDoToday: "How did we do today?",
  whatTheBadReason: "What is the main reason you are not satisfied?",
  whatTheGoodReason: "Please share your happy experiences",
  shareFeedback: "Share your feedback",
  leaveReview: "Would you like to leave review?",
  yes: "Yes",
  no: "No",
  showSuccess: "Thanks for the feedback",
  redirecting: "Redirecting..."
}

