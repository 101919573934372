<template>
  <div class="row">
    <div class="col s12 m6">
      <div class="card-panel gift-card">
        <div class="card-input" ref="card"></div>
        <div class="red-text mt-10">{{errorMessage}}</div>
        <div class="btn mt-25 right" v-show="showAddBtn" @click="addCard" :disabled="addButtonDisable">ADD</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['stripePublishableKey', 'redirectUrl'],
    mounted() {
      this.loadStripeJs()
    },
    data() {
      return {
        stripe: null,
        elements: null,
        card: undefined,
        errorMessage: "",
        showAddBtn: false,
        addButtonDisable: false,
        scripe: null
      }
    },
    methods: {
      addCard() {
        this.addButtonDisable = true
        this.stripe.createToken(this.card).then(this.resultHandler);
      },
      resultHandler(result) {
        if (result.error) {
          this.notifyError(result.error.message)
          this.showPending = false
          this.disableSignupBtn = false
        } else {
          this.$http.post("/api/v1/app/cards", {token: result.token.id}).then(response =>{
            Turbolinks.visit(this.redirectTo)
          }, error => {
            this.addButtonDisable = false
            this.errorHandler(error)
          })
        }
      },
      onChange({error, complete}) {
        if (error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = '';
        }
        if (complete) {
          this.showAddBtn = true
        }
      },
      loadStripeJs() {
        this.script= document.createElement('script')
        this.script.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(this.script)
        this.script.onload = this.setupStripe

      },
      setupStripe() {
        this.stripe = Stripe(this.stripePublishableKey)
        this.elements = this.stripe.elements({locale: 'en'})
        this.card = this.elements.create('card', {hidePostalCode: true, iconStyle: 'solid'})
        this.card.mount(this.$refs.card)

        this.card.on('change', this.onChange);
      }
    },
    computed: {
      redirectTo() {
        if(this.redirectUrl) { return this.redirectUrl }

        return "/bank_institutions"
      }
    }
  }
</script>
<style>
  .card-input {
    border: 1px solid #ccc;
    padding: 10px;
  }
</style>
