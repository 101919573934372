<template>
   <span v-html='parsedText'></span>
</template>
<script>
  export default {
    props: ['text', 'className'],
    
    computed: {
      parsedText() {
        return this.$twemoji.parse(
          this.text, {
            className: this.emojiClassName,
            folder: 'svg',
            ext: '.svg'
          })
      },
      emojiClassName() {
        if (this.className) {
          return this.className
        }
        return 'def-emoji'
      }
    }
  }
</script>
<style>
  .def-emoji {
    width: 25px;
  }
  .emo-50 {
    width: 50px;
  }
  .emo-100 {
    width: 100px;
  }
</style>
