<template>
  <div>
    <h3>Order total: ${{totalAmount}}</h3>
    <div class='card-panel' :class='[processCardActive ? "z-depth-5" : "grey-text"]' v-if='processCard'>
      <i class="far fa-credit-card"></i> 
      By Credit Card: 
      <h3><b>${{cardPayment}}</b></h3>
      <process-by-card
        @paid='cardPaid'
        :cards="cards"
        :note="orderId"
        :local_order_id="localOrderId"
        :card_flow_status="card_flow_status"
        :qid="qid"
        :amount="cardPayment">

      </process-by-card>
    </div>
    <div class='card-panel mt-50' :class='[processEbtCardActive ? "z-depth-5" : "grey-text"]' v-show="processEbt">
      By EBT/SNAP Card:
      <h3><b>${{ebtPayment}}</b></h3><br>

      <select v-model='ebtCard' class='browser-default' v-show='processEbtCardActive || !processCard'>
        <option v-for="card in ebtCards" :value='card'> {{card.subtype}} {{card.last4}}</option>
      </select>

      <ebt-transaction-poster 
        v-if='processEbtCardActive && ebtCard || !processCard'
        class='mt-25'
        :pinPadPath='pinPadPath'
        :returnUrl='returnUrl'
        :merchantQid='qid'
        :clientQid='clientQid'
        :amount='ebtPayment'
        :note='orderId'
        :redirect='redirectUrlWithId'
        subtype='snap'
        :ebtCardTokenId='ebtCard.id'>
      </ebt-transaction-poster>

    </div>
  </div>
</template>
<script>
  import processByCard from './processors/cards'
  import ebtTransactionPoster from 'components/client/ebt_transaction_poster'

  export default {

    props: ['totalAmount', 'ebtPayment', 'cardPayment', 'cards', 'ebtCards', 'qid', 'orderId', 'redirectUrl', 'pinPadPath', 'returnUrl', 'clientQid', 'localOrderId', 'ebt_card_flow_status', 'card_flow_status'],
    components: {'process-by-card': processByCard, 'ebt-transaction-poster': ebtTransactionPoster},
    data() {
      return {
        processCardActive: true,
        processEbtCardActive: false,
        ebtCard: this.ebtCards[0],
        cardTransactionId: null,
      }
    },
    methods: {
      cardPaid(payload) {
        this.cardTransactionId = payload.payment.data.id
        if(this.processEbt) {
          this.processCardActive = false
          this.processEbtCardActive = true
        } else {
          window.location.href = this.redirectUrlWithId
        }
      }
    },
    computed: {
      processEbt() {
        if(this.ebtPayment == 0 ) { return false }
        return true
      },
      processCard() {
        if(this.cardPayment == 0 ) { return false }
        return true
      },
      redirectUrlWithId() {
        return this.localOrderId
      }
    }
  }
</script>
