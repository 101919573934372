<template>
  <div>
    <section v-show="!submitSucceed">
      <vue-recaptcha 
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        @verify="submit"
        @expired='onCaptchaExpired'
        ref="recaptcha"
        size="invisible">

      </vue-recaptcha>
      <form enctype="multipart/form-data" novalidate ref="form">
        <div class='row'>
          <div class='col s6'>
            <label>Amount, USD</label>
            <input 
              name='amount'
              v-model='amount'
              step="0.01"
              type="number"
              pattern="\d*"
              inputmode="decimal"
              v-validate="'min_value:0.51|required'"
              class='inputText'
              lang="en-001">

            <span class='red-text small-font' v-show='errors.first("amount")'>The Amount field must be 0.51 or more.</span>
          </div>
        </div>
        <div class='row'>
          <div class='col s12 m6'>
            <label>Your Phone (required)</label>
            <input v-model="phone" name="phone" type="tel">
          </div>
          <div class='col s12 m6'>
            <label>Note (optional)</label>
            <input v-model="note" name="note">
          </div>
        </div>

        <image-uploader
            :debug="1"
            :maxWidth="900"
            :quality="0.8"
            :autoRotate=true
            outputFormat="blob"
            :preview=false
            :className="['file-upload', { 'fileinput--loaded' : hasImage }]"
            :capture="false"
            accept="image/*"
            doNotResize="['gif', 'svg']"
            @input="setImage"
            @onUpload="startImageResize"
            onComplete="endImageResize"
          >

          <label for="fileInput" slot="upload-label">
            <div class="btn-large wide-btn" :disabled="disabled">
              <span v-if="disabled">
                <i class="fas fa-spinner fa-spin grey-text"></i> 
                <span>Processing...</span>
              </span>
              <span v-else>
                <i class="fas fa-camera"></i> 
                <span class="upload-caption">{{ hasImage ? 'Replace' : 'Check' }}</span>
              </span>
            </div>
          </label>

        </image-uploader>

        <section class="grey-text small-font center" v-show="!disabled && src">
          <img :src="src" alt="Check image" height="90px" class="mt-10" />
          <div class="btn-large wide-btn mt-10" @click="checkCaptcha">Re-submit</div>
        </section>
      </form>
    </section>
    <section class="center" v-show="submitSucceed">
      <i class="fas fa-check-circle fa-4x emerald"></i><br>
      <h3>Submitted successfully</h3>
    </section>
  </div>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha';

  export default {
    components: { VueRecaptcha },
    props: ["code", "sitekey"],
    data() {
      return {
        amount: null,
        phone: null,
        note: null,
        disabled: false,
        src: null,
        hasImage: false,
        image: null,
        submitSucceed: false
      }
    },
    methods: {
      checkCaptcha() {
        this.$refs.recaptcha.execute()
      },
      submit(recaptchaToken) {
        this.disabled = true
        let formData = new FormData(this.$refs.form)
        formData.append("code", this.code)
        formData.append("recaptcha_token", recaptchaToken)
        formData.append("check", this.image)

        this.$http.post("/api/v1/app/client/check_by_images", formData).then(response => {
          this.submitSucceed = true
          this.disabled = false
        }, error => {
          this.disabled = false
          this.onCaptchaExpired()
          this.errorHandler(error)
        })
      },
      onCaptchaExpired () {
        this.$refs.recaptcha.reset()
      },
      startImageResize() {
        this.disabled = true
      },
      setImage(image) {
        this.hasImage = true
        this.image = image

        // Prepare image preview
        var reader = new FileReader();
        reader.readAsDataURL(image); 
        reader.onloadend = res => {
          this.src = reader.result;                
        }
        this.checkCaptcha()
      }
    }
  }
</script>
<style scoped>
  input#fileInput {
    display: none;
  }
</style>
