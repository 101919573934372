<template>
  <div>
    <div class="btn-large wide-btn rounded-btn mt-50" @click="pay" v-if='showCCBtn'>
      <span style='margin-right: 10px'>{{$t("sendBill.payWithCard")}}</span>
    </div>
    <section v-else>
      <div class="col s12 mt-10" v-show="showManualForm">
        <section class="card-container">
          <label>{{$t("sendBill.fillInCardDetails")}}:</label>
          <div ref="card" v-show='amountNumber' class="card-input"></div>
          <span class='red-text'>{{errorMessage}}</span>
        </section>
        <section class='mt-10 grey-text center' v-if='showTips'>
          <tips :qid="merchantQid" :tips-updated="tipsUpdated"></tips>
        </section>

        <section>
          <div class='card-panel mt-10'>
            <section v-show="!errorMessage">
              <center class='grey-text' style="font-size: 1.8em">
                {{$t("sendBill.pleaseSignHere")}}
              </center>

              <vue-signature-pad width="100%" 
                                 height="27vh" 
                                 :options="{ onBegin }"
                                 ref="signaturePad" />
              <span class='grey-text'>
                X __________________________<br>
                <span v-if="policyUrl.length > 3">
                  {{$t("sendBill.agreePayAboveAmountFirst")}} <a :href="policyUrl" target="_blank">{{$t("sendBill.agreePayAboveAmountUrl")}}</a> {{$t("sendBill.agreePayAboveAmountLast")}}
                </span>
                <span v-else>
                  {{$t("sendBill.agreePayAboveAmount")}}
                </span>
              </span>
            </section>
          </div>

          <button @click="charge(resultHandler)" v-show="!showPending && showManualForm" class="btn-large wide-btn mt-5" :disabled="disableSignupBtn || disableSubmit">
            {{$t("sendBill.pay")}}<span v-show='amountNumber'> {{withTipAmount}}</span>
          </button>
          <div class='grey-text mt-5 center' v-show="feeAmount != 0">
            {{$t("sendBill.grossAmount")}}: <b>{{grossAmount}}</b> |  
            <i class="far fa-credit-card"></i> {{$t("sendBill.nonCashAdj")}}: <b>{{feeAmount}}</b>
          </div>
        </section>
      </div>
    </section>

    <center v-if='showPending' class='grey-text mt-5'>
      <i class="fas fa-spinner fa-spin"></i> {{$t("sendBill.processing")}}
    </center>
  </div>
</template>
<script>
  import { useCardsBillStore } from "./cc/store"
  import { toRefs } from '@vue/composition-api'
  import tips from "./cc/tips_block"

  export default {
    setup: () => ({ ...toRefs(useCardsBillStore()) }),
    components: {tips},
    props: ['stripePublishableKey', 'linkOrderId', 'showTips', 'policyUrl', 'hideVotes'],
    data() {
      return {
        pending: false,
        showCCBtn: true,
        feeAmount: 0,
        tipAmount: 0,
        tipAmountFormatted: null,
        withTipAmount: null,
        token: null,
        elements: null,
        card: null,
        errorMessage: null,
        disableSignupBtn: true,
        showPending: false,
        stripeForConnected: null,
        showManualForm: true,
        selectedTip: 0,
        tipCustomAmount: 0,
        disableSubmit: true
      }
    },
    methods: {
      onBegin() {
        this.disableSubmit = false
      },
      pay() {
        this.$emit('input', true)
        this.showCCBtn = false
        if(!this.showTips) {
          this.updateAmounts()
        }
        this.loadStripeJs()
      },
      charge(resultHandler) {
        if(this.tipAmount < 20 || confirm(`Confirm ${this.tipAmountFormatted} tip amount`)){
          this.disableSignupBtn = true
          this.showPending = true
          this.stripe.createToken(this.card).then(resultHandler)
        }
      },
      resultHandler(result) {
        if (result.error) {
          this.notifyError(result.error.message)
          this.showPending = false
          this.disableSignupBtn = false
        } else {
          this.token = result.token.id

          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          this.signatureImage = data

          this.$http.post("/api/v1/app/client/link_orders/card_transactions", this.params).then(response => {
            this.transactionId = response.body.payment.data.id
            this.votesUrl = response.body.payment.data.attributes.votes_url
            Turbolinks.visit("/a/thank_you?transaction_id=" + this.transactionId + "&url=" + this.votesUrl + "&locale=" + this.$i18n.locale + "&hide_votes=" + this.hideVotes)
          }, error => {
            this.errorHandler(error)
            this.showPending = false
            this.disableSignupBtn = false
          })
        }
      },
      loadStripeJs() {
        this.script= document.createElement('script')
        this.script.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(this.script)
        this.script.onload = this.setupStripe
      },
      setupStripe() {
        this.stripe = Stripe(this.stripePublishableKey)
        this.elements = this.stripe.elements({locale: this.$i18n.locale})
        this.card = this.elements.create('card', 
          {
            hidePostalCode: false,
            iconStyle: 'solid',
            style: {
              base: {
                iconColor: '#00ab8e',
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                '::placeholder': {
                  color: 'grey',
                },
              }
            }
          })
        this.card.mount(this.$refs.card)

        this.card.on('change', this.onChange);

        this.stripeForConnected = Stripe(this.stripePublishableKey, {
          stripeAccount: this.merchantStripeId,
          apiVersion: "2020-08-27"
        })
      },
      onChange({error, complete}) {
        if (error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = '';
        }
        if (complete) {
          this.disableSignupBtn = false
        }
      },
      tipsUpdated(selectedTip, tipCustomAmount) {
        this.selectedTip = selectedTip
        this.tipCustomAmount = tipCustomAmount
        this.updateAmounts()
      }
    },
    computed: {
      params() {
        return {
          token: this.token,
          link_order_id: this.linkOrderId,
          tip: this.selectedTip,
          tip_amount: this.tipCustomAmount,
          signature_image: this.signatureImage
        }
      }
    }
  }
</script>
<style scoped>
  .card-input {
    border: 2px solid #00ab8e;
  }
  .card-container {
    margin-left: 3px;
    margin-right: 3px;
  }
</style>
