<template>
  <div>
    <a class="waves-effect waves-light btn modal-trigger grey lighten-4 grey-text text-darken-3" href="#modal1">reverse refund</a>

    <!-- Modal Structure -->
    <div id="modal1" class="modal" ref='modal'>
      <div class="modal-content">
        <h3>Reverse Refund</h3>
        <p>
          Initial transaction of {{amount}} 
          was refunded and this action will reverse that {{amount}} refund.<br>
          <div class='mt-10 bold-text'>Would you like to proceed?</div>
        </p>
      </div>
      <div class="modal-footer">
        <button data-target="modal1" class="btn grey lighten-4 grey-text text-darken-3" @click="cancel">Cancel</button>
        <button data-target="modal1" class="btn" @click="reverse">yes</button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        disabled: false,
        endPoint: '/api/v1/app/merchant/ach_transactions/'
      }
    },
    props: ['id', 'amount'],
    methods: {
      cancel() {
        var instance = M.Modal.getInstance(this.$refs.modal);
        instance.close()
      },
      reverse() {
        this.disabled = true
        this.$http.post(this.endPoint + this.id + '/reverses').then(response => {
          Turbolinks.visit(window.location)
        }, response => {
          this.disabled = false
          this.notifyError(response.body.error)
        })
      }
    }
  }
</script>
