<template>
  <div class='mt-25 row'>
    <div class='col s6'>
      <label>Select the Range</label>
      <div class="input-field">
        <select class='browser-default' v-model='range'>
          <option value="this_week">This Week</option>
          <option value="last_week">Last Week</option>
          <option value="this_month">Month To Date</option>
          <option value="last_month">Last Month</option>
          <!-- <option value="last_month">Year To Date</option> -->
        </select>
      </div>
      <a :href='path' class='btn-large' target='_blank'>
        <i class="fas fa-download"></i> 
        Download CSV
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        range: 'this_week'
      }
    },
    computed: {
      path() {
        return '/admin/master_reports.csv?range=' + this.range
      }
    }
  }
</script>
