import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "password", "fields", "autoOption", "manualOption" ]

  connect() {
    if(this.data.get("showPasswordValue") == "true") {
      if(this.data.get("manualPasswordValue")) {
        this.manual()
      } else {
        this.auto()
      }
    }
  }
  auto() {
    this.autoOptionTarget.checked = true
    this.fieldsTarget.hidden = true
    this.passwordTarget.value = null
  }

  manual() {
    this.manualOptionTarget.checked = true
    this.fieldsTarget.hidden = false
    this.passwordTarget.value = this.data.get("manualPasswordValue")
  }

  setManualPassword() {
    this.data.set("manualPasswordValue", this.passwordTarget.value)
  }
  showPassword(event) {
    if(event.srcElement.checked) {
      this.passwordTarget.type = "text"
    } else {
      this.passwordTarget.type = "password"
    }
  }
}
