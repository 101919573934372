import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "type", 'beneficialForm' ]

  connect() {
    this.changed()
  }

  changed() {

    if (this.businessTypes()[this.typeTarget.value]) {
      this.beneficialFormTarget.classList.remove('hidden')
      this.setDisabledTo(false)
    } else {
      this.setDisabledTo(true)
      this.beneficialFormTarget.classList.add('hidden')
    }
  }

  // { business_type_selected: should_fillin_beneficial_owner? }
  businessTypes() {
    return {
      corporation: true,
      corporation_public: false,
      llc: true,
      partnership: true,
      corporation_nonprofit: false
    }
  }

  setDisabledTo(state) {
    let inputs = this.beneficialFormTarget.getElementsByTagName('INPUT')
    let select = this.beneficialFormTarget.getElementsByTagName('SELECT')
    for (var i = 0; i < inputs.length; ++i ) {
      if (state) {
        inputs[i].setAttribute('disabled', true)
      } else {
        inputs[i].removeAttribute("disabled");
      }
    }
    if (state) {
      select[0].setAttribute('disabled', true)
    } else {
      select[0].removeAttribute("disabled");
    }
  }
}
