<template>
  <div v-show='!readyForReward && loyaltyActive'>
    <div class='mt-10'>
      <center>
        <span v-for='stamp in parseInt(haveStamps)' :key="stamp"><img src='/reward.png' class="responsive-img"></span>
        <span v-for='s in leftToWin'><img src='/reward-grey.png' ></span><br>
        Automatic {{rewardPercent}}% off when you spend ${{minAmount}} or more {{leftToWin}} time(s)
      </center>
    </div>
  </div>
</template>
<script>
  export default {
    props: [
      'haveStamps', 
      'totalForReward', 
      'readyForReward', 
      'loyaltyActive', 
      'minAmount',
      'rewardPercent'
    ],
    computed: {
      leftToWin() {
        var that = this
        return parseInt(that.totalForReward) - parseInt(that.haveStamps)
      }
    }
  }

</script>
