<template>
  <div v-show='show'>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    computed: {
      show() {
        if (this.$webkit) {
          return false
        } else {
          return true
        }
      }
    }
  }
</script>
