<template>
  <div>
    <div v-for="(item, index) in items" :key="index" class="flex items-center justify-between py-2 border-b">
      <div class="flex items-center">
        <b class="bold-font emerald">{{ item.amount | currency }}</b> 
        <span class="ml-4">
          {{ item.invoice ? 'INV: ' + item.invoice : '' | truncate(20, '...') }} <br v-if="item.invoice">
          {{ item.note | truncate(15, '...') }}
        </span>
      </div>
      <i class="fas fa-times red-text cursor-pointer" @click="removeItem(index)"></i>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['items', 'itemsUpdated'],
    data() {
      return {
      }
    },
    methods: {
      removeItem(index) {
        this.items.splice(index, 1)
        this.itemsUpdated(this.calculatedTotalAmount)
      }
    },
    computed: {
      calculatedTotalAmount() {
        return this.items.reduce((total, item) => total + item.amount, 0)
      },
      limitedNote() {
        if (this.note.length > 7) {
          return this.note.substring(0, 7) + '...'
        } else {
          return this.note
        }
      }
    }
  }
</script>
<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .ml-4 {
    margin-left: 1rem;
  }

  .py-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .border-b {
    border-bottom-style: dotted;
    border-bottom-color: #CCCCCC;
  }
</style>
