<script>
  import gql from 'graphql-tag'

  export default {
    props: ['gid'],
    apollo: {
      settings: {
        query: gql`query($gid: ID) {
          merchant(gid: $gid) { merchantUser {settings {pay {genericFlow}}}}
        }`,
        variables() {
          return {
            gid: this.gid
          }
        },
        update: data => data.merchant.merchantUser.settings.pay
      }
    },
    data() {
      return {
        settings: {}
      }
    },
    methods: {
      switchIt() {
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($genericFlow: Boolean!, $gid: ID) {
            updateGenericFlow(input: {genericFlow: $genericFlow, userId: $gid}) {
              paySettings { genericFlow }
            }
          }`,
          // Parameters
          variables: {
            genericFlow: !this.settings.genericFlow,
            gid: this.gid
          },
        }).then(result => {
          this.settings.genericFlow = result.data.updateGenericFlow.paySettings.genericFlow
          this.notifySuccess("Pay settings successfully updated")
        }, error => {
          this.gqlErrorHandler(error)
          this.settings.genericFlow = !this.settings.genericFlow
        })
      }
    }
  }
</script>

<template>
  <div>
    <section>
      <div class= "very-close-lines">
        Generic flow:<br>
        
        <small class="grey-text">
          Enables custom tokenisation flow (required for Payrix)
        </small>
      </div>
      <!-- Switch -->
      <div class="switch">
        <label>
          Off
          <input type="checkbox" @click='switchIt' v-model='settings.genericFlow' :disabled="$apollo.loading">
          <span class="lever"></span>
          On
        </label>
      </div>
    </section>
  </div>
</template>
