<template>
  <div v-if="badCheckImagesPresent">
    <button v-show="!showImages" @click="loadImages" class="btn">
      <i class="far fa-eye"></i> Show banks bad check image
    </button>

    <div v-if="$apollo.loading">
      <i class="fas fa-spinner fa-spin grey-text"></i> 
      Loading...
    </div>

    <img class="mt-10" v-if="showImages" v-for="item in images" :src="'data:image/jpeg;base64,' + item.image" width="100%">
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    props: ["id"],
    apollo: {
      preImages: {
        query: gql`query($id: String!) {
          merchant { transaction(id: $id) { badCheckImages {nodes {fileName}}}}
        }`,
        variables() {
          return {
            id: this.id
          }
        },
        update: data => data.merchant.transaction.badCheckImages.nodes
      },
      images: {
        query: gql`query($id: String!) {
          merchant { transaction(id: $id) { badCheckImages {nodes {image}}}}
        }`,
        variables() {
          return {
            id: this.id
          }
        },
        skip() {
          return this.skipQuery
        },
        update: data => data.merchant.transaction.badCheckImages.nodes
      }
    },
    data() {
      return {
        images: [],
        preImages: [],
        skipQuery: true
      }
    },
    methods: {
      loadImages() {
        this.skipQuery = false
      }
    },
    computed: {
      showImages() {
        return this.images.length > 0
      },
      badCheckImagesPresent() {
        return this.preImages.length > 0
      }
    }
  }
</script>
<style scoped>
  img { display: block; }
</style>
